import React, { FC, useState } from 'react';

// Components
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { DayPicker } from 'react-day-picker';

// Icons
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import IconButton from '@mui/material/IconButton';

// Styles
import classes from './TrackerDatePicker.module.scss';

interface IProps {
  selected: Date | undefined;
  onSelect: (day: Date) => void;
}

const TrackerDatePicker: FC<IProps> = ({ selected, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
      setIsOpen(false);
    } else {
      setAnchorEl(e.currentTarget);
      setTimeout(() => {
        setIsOpen(true);
      }, 50);
    }
  };

  const handleDayClick = (day: Date) => {
    setAnchorEl(null);
    setIsOpen(false);
    onSelect(day);
  };

  return (
    <>
      <IconButton onClick={handleButtonClick}>
        <CalendarIcon />
      </IconButton>
      <ClickAwayListener
        onClickAway={() => {
          if (isOpen) {
            setAnchorEl(null);
            setIsOpen(false);
          }
        }}
      >
        <Popper
          className={classes.wrapper}
          open={isOpen}
          anchorEl={anchorEl}
          placement="bottom"
        >
          <DayPicker
            mode="single"
            selected={selected}
            onDayClick={handleDayClick}
          />
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default TrackerDatePicker;
