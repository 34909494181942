import React, { useState, useCallback, FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import useTheme from '@mui/material/styles/useTheme';
import MenuIcon from '@mui/icons-material/Menu';

// Icons
import { ReactComponent as Logo } from 'assets/icons/logo.svg';

// Stores
import profileStore from 'stores/ProfileStore';
import alertsStore from 'stores/alertsStore';
import searchStore from 'stores/SearchStore';

// Types
import { UserRole } from 'types/user';

// Styles
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import classes from './LoggedLayout.module.scss';

// Components
import Header from '../../components/Header/Header';

import { sidebarList, subListManagement, subListOwnerOnly } from './IconsList';

const widthOpenDrawer = 256;
const paddingIconButton = 10;
const marginIconButton = 14;
const iconFontSize = 20;
const widthCloseDrawer =
  (paddingIconButton + marginIconButton) * 2 + iconFontSize;

const LoggedLayout: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isReportsOpen, setReportsOpen] = useState<boolean>(false);
  const [hasManagement, setHasManagment] = useState(false);
  const [hasAdminFunctionality, setHasAdminFunctionality] = useState(false);

  const { alerts, isAlertsOpen, clearAlerts, showAlerts, closeAlert } =
    alertsStore;

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    showAlerts(false);
    clearAlerts();
  };

  useEffect(() => {
    setHasManagment(
      [
        UserRole.ADMIN.toString(),
        UserRole.MANAGER.toString(),
        UserRole.OWNER.toString()
      ].includes(profileStore.role)
    );
    setHasAdminFunctionality(
      [UserRole.ADMIN.toString(), UserRole.OWNER.toString()].includes(
        profileStore.role
      )
    );
  }, [profileStore.role]);

  const location = useLocation();

  const handleNavigate = useCallback((page: string) => {
    navigate(page);
    searchStore.reset();
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen((cIsOpen) => !cIsOpen);
  }, []);

  const drawerContent = (
    <>
      <Box className={classes.header}>
        <Box
          className={clsx(classes.logo, {
            [classes.deleteItem]: isOpen
          })}
          onClick={() => handleNavigate('/')}
        >
          <Logo fill="green" color="green" />
        </Box>
        <h1
          className={clsx(classes.title, {
            [classes.deleteItem]: isOpen
          })}
        >
          Olga Finance
        </h1>
        <Button onClick={handleOpen} className={classes.openButton}>
          <MenuIcon
            className={clsx({
              [classes.isOpenIcon]: isOpen,
              [classes.closeIcon]: !isOpen
            })}
          />
        </Button>
      </Box>
      <List dense className={classes.list}>
        <div
          className={clsx(classes.subHeader, {
            [classes.deleteItem]: isOpen
          })}
        >
          GENERAL
        </div>
        {sidebarList.map((item) => {
          if (
            (!item.forAdmins || hasAdminFunctionality) &&
            (!item.forManagers || hasManagement)
          ) {
            return (
              <div key={item.id}>
                <Tooltip
                  key={item.id}
                  title={isOpen ? item.desc : ''}
                  placement="right"
                >
                  <ListItemButton
                    onClick={
                      item.subpages
                        ? () => setReportsOpen(!isReportsOpen)
                        : () => handleNavigate(item.page)
                    }
                    className={clsx(classes.itemButton, {
                      [classes.activeButton]:
                        location.pathname === `/${item.page}`
                    })}
                  >
                    <ListItemIcon className={classes.icon}>
                      <Badge
                        color="secondary"
                        badgeContent={item.badge}
                        className={classes.badge}
                        variant="dot"
                      >
                        {location.pathname !== `/${item.page}` ? (
                          <item.icon className={classes.icon} />
                        ) : (
                          <item.activeIcon className={classes.icon} />
                        )}
                      </Badge>
                    </ListItemIcon>
                    <p
                      className={clsx(classes.itemText, {
                        [classes.openedPage]:
                          location.pathname === `/${item.page}`
                      })}
                    >
                      {item.desc}
                    </p>
                    {item.badge !== 0 ? (
                      <Chip
                        label={item.badge}
                        size="small"
                        className={classes.chip}
                      />
                    ) : (
                      <div />
                    )}
                  </ListItemButton>
                </Tooltip>
                {isReportsOpen &&
                  item.subpages &&
                  item.subpages?.map((subpage) => (
                    <Tooltip
                      key={subpage.id}
                      title={isOpen ? subpage.desc : ''}
                      placement="right"
                    >
                      <ListItemButton
                        onClick={() => handleNavigate(subpage.page)}
                        className={clsx({
                          [classes.itemButton]: isOpen,
                          [classes.itemSubpageButton]: !isOpen,
                          [classes.activeButton]:
                            location.pathname === `/${subpage.page}`
                        })}
                      >
                        <ListItemIcon className={classes.icon}>
                          <Badge
                            color="secondary"
                            badgeContent={subpage.badge}
                            className={classes.badge}
                            variant="dot"
                          >
                            {location.pathname !== `/${subpage.page}` ? (
                              <subpage.icon className={classes.icon} />
                            ) : (
                              <subpage.activeIcon className={classes.icon} />
                            )}
                          </Badge>
                        </ListItemIcon>
                        <p
                          className={clsx(classes.itemSubpageText, {
                            [classes.openedPage]:
                              location.pathname === `/${subpage.page}`
                          })}
                        >
                          {subpage.desc}
                        </p>
                        {subpage.badge !== 0 ? (
                          <Chip
                            label={subpage.badge}
                            size="small"
                            className={classes.chip}
                          />
                        ) : (
                          <div />
                        )}
                      </ListItemButton>
                    </Tooltip>
                  ))}
              </div>
            );
          }

          return null;
        })}
        <div
          className={clsx(classes.subHeader, {
            [classes.deleteItem]: isOpen || !hasManagement
          })}
        >
          MANAGEMENT
        </div>
        {subListManagement.map((item) => (
          <div key={item.id}>
            <Tooltip
              key={item.id}
              title={isOpen ? item.desc : ''}
              placement="right"
            >
              <ListItemButton
                onClick={() => handleNavigate(item.page)}
                className={clsx(classes.itemButton, {
                  [classes.activeButton]: location.pathname === `/${item.page}`,
                  [classes.deleteItem]: !hasManagement
                })}
              >
                <ListItemIcon className={classes.icon}>
                  <Badge
                    color="secondary"
                    badgeContent={item.badge}
                    className={classes.badge}
                    variant="dot"
                  >
                    {location.pathname !== `/${item.page}` ? (
                      <item.icon className={classes.icon} />
                    ) : (
                      <item.activeIcon className={classes.icon} />
                    )}
                  </Badge>
                </ListItemIcon>
                <p
                  className={clsx(classes.itemText, {
                    [classes.openedPage]: location.pathname === `/${item.page}`
                  })}
                >
                  {item.desc}
                </p>
                {item.badge !== 0 ? (
                  <Chip
                    label={item.badge}
                    size="small"
                    className={classes.chip}
                  />
                ) : (
                  <div />
                )}
              </ListItemButton>
            </Tooltip>
          </div>
        ))}

        {subListOwnerOnly.map((item) => (
          <div key={item.id}>
            <Tooltip
              key={item.id}
              title={isOpen ? item.desc : ''}
              placement="right"
            >
              <ListItemButton
                onClick={() => handleNavigate(item.page)}
                className={clsx(classes.itemButton, {
                  [classes.activeButton]: location.pathname === `/${item.page}`,
                  [classes.deleteItem]: !(profileStore.role === UserRole.OWNER)
                })}
              >
                <ListItemIcon className={classes.icon}>
                  <Badge
                    color="secondary"
                    badgeContent={item.badge}
                    className={classes.badge}
                    variant="dot"
                  >
                    {location.pathname !== `/${item.page}` ? (
                      <item.icon className={classes.icon} />
                    ) : (
                      <item.activeIcon className={classes.icon} />
                    )}
                  </Badge>
                </ListItemIcon>
                <p
                  className={clsx(classes.itemText, {
                    [classes.openedPage]: location.pathname === `/${item.page}`
                  })}
                >
                  {item.desc}
                </p>
                {item.badge !== 0 ? (
                  <Chip
                    label={item.badge}
                    size="small"
                    className={classes.chip}
                  />
                ) : (
                  <div />
                )}
              </ListItemButton>
            </Tooltip>
          </div>
        ))}
      </List>
    </>
  );

  return (
    <Box className={classes.menu}>
      <Snackbar
        open={isAlertsOpen}
        autoHideDuration={8000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Grid container gap={1} maxWidth={400}>
          {alerts.map((alert) => (
            <Alert
              classes={{ action: 'color: white !important' }}
              className={classes.alert}
              onClose={() => closeAlert(alert.message)}
              severity={alert.severity}
              sx={{ width: '100%' }}
              variant="filled"
              key={Math.random().toString()}
            >
              {alert.message}
            </Alert>
          ))}
        </Grid>
      </Snackbar>
      <Drawer
        variant="permanent"
        className={classes.logo}
        open={isOpen}
        sx={{
          width: isOpen
            ? { xs: '0px', sm: widthCloseDrawer }
            : { xs: widthCloseDrawer, sm: widthOpenDrawer },
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: isOpen
              ? theme.transitions.duration.leavingScreen
              : theme.transitions.duration.enteringScreen
          }),
          '& .MuiDrawer-paper': {
            justifyContent: 'start',
            overflowX: 'hidden',
            width: isOpen
              ? { xs: '0px', sm: widthCloseDrawer }
              : { xs: widthCloseDrawer, sm: widthOpenDrawer },
            borderRight: '1px solid #ebebeb',
            backgroundColor: isOpen ? '#fffff' : '#fffff',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: isOpen
                ? theme.transitions.duration.leavingScreen
                : theme.transitions.duration.enteringScreen
            })
          }
        }}
      >
        {drawerContent}
      </Drawer>
      <div
        className={classes.longHeader}
        style={{
          width: !isOpen ? `calc(100% - 256px)` : `calc(100% - 68px)`
        }}
      >
        <Header />
        <Outlet />
      </div>
    </Box>
  );
};

export default observer(LoggedLayout);
