import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import clsx from 'clsx';

import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

// Components
import Profile from 'layouts/ProfileLayout';
import DatePicker from 'components/DatePicker';

// Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// Types
import { User } from 'types/user';
import { IProject } from 'types/project';
import { AssignmentUser } from 'types/planner';
import { Placeholder } from 'types/placeholder';
import { ContractCurrencyVariant } from 'types/rate';

// Stores
import rateStore from 'stores/RateStore';
import projectsStore from 'stores/ProjectsStore';
import clientsStore from 'stores/ClientsStore';
import plannerStore from 'stores/PlannerStore';

// Styles
import classes from './PlannerAssignment.module.scss';

interface PlannerAssignmentProps {
  edit?: boolean;
  isPlaceholder?: boolean;
  active: boolean;
  setActive: (active: boolean) => void;
  member: User | Placeholder;
  project: IProject;
  assignment?: AssignmentUser;
  assignmentStart?: Date | number;
  assignmentEnd?: Date | number;
  readOnly?: boolean;
}

enum Names {
  userId = 'userId',
  projectId = 'projectId',
  rate = 'rate',
  expertise = 'expertise',
  dailyHours = 'dailyHours',
  totalHours = 'totalHours',
  startDate = 'startDate',
  endDate = 'endDate'
}

const PlannerAssignment: FC<PlannerAssignmentProps> = ({
  edit,
  isPlaceholder,
  active,
  setActive,
  member,
  project,
  assignment,
  assignmentStart,
  assignmentEnd,
  readOnly = false
}) => {
  const [selectedField, setSelectedField] = useState<Names | null>(null);
  const [newExpertise, setNewExpertise] = useState<boolean>(false);
  const [client, setClient] = useState('');

  const { clients } = clientsStore;
  const { projects, expertises } = projectsStore;
  const { allUsers } = plannerStore;

  const handleClose = useCallback((cIsOpen: boolean) => {
    setActive(!cIsOpen);
  }, []);

  useEffect(() => {
    if (clients.length === 0) {
      clientsStore.loadClients();
    }

    if (projects.length === 0 || allUsers.length === 0) {
      projectsStore.loadProjects();
      plannerStore.getPlannerAllUsers();
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: useMemo(() => {
      let expertise: string = '';
      setNewExpertise(false);

      if (assignment && assignment.expertiseId) {
        expertise = assignment.expertiseId;
      } else if (assignment && assignment.expertize) {
        expertise = assignment.expertize;
        setNewExpertise(true);
      }

      return {
        [Names.userId]:
          isPlaceholder && member.id === '' ? 'placeholder' : member.id,
        [Names.projectId]: project.id,
        [Names.rate]: assignment?.rate ?? 0,
        [Names.expertise]: expertise,
        [Names.dailyHours]: assignment?.dailyHours ?? 0,
        [Names.totalHours]: member.defaultWeeklyCapacity,
        [Names.startDate]: dayjs(assignmentStart),
        [Names.endDate]: dayjs(assignmentEnd)
      };
    }, [assignment]),
    onSubmit: (values) => {
      if (readOnly) {
        handleClose(true);

        return;
      }

      const assignmentUser: AssignmentUser = {
        id: assignment?.id ?? '',
        userId: values.userId,
        projectId: values.projectId,
        rate: values.rate,
        expertize: newExpertise ? values.expertise : undefined,
        expertiseId: !newExpertise ? values.expertise : undefined,
        dailyHours: values.dailyHours,
        startAt: dayjs(values.startDate).format(),
        endAt: dayjs(values.endDate).format()
      };

      if (isPlaceholder) {
        if (edit) {
          plannerStore.updatePlaceholderAssignment(assignmentUser);
        } else if (member.id !== '') {
          plannerStore.addPlaceholderAssignment(assignmentUser);
        } else {
          plannerStore
            .addPlannerPlaceholder({
              name: member.name,
              defaultExpertize: member.defaultExpertize
            })
            .then((data: Placeholder | null) => {
              if (!data) {
                return;
              }

              plannerStore.addPlaceholderAssignment({
                ...assignmentUser,
                userId: data.id
              });
            });
        }
      } else if (edit) {
        plannerStore.updateAssignmentPlannerUser(assignmentUser);
      } else {
        plannerStore.addAssignmentPlannerUser(assignmentUser);
      }

      handleClose(true);
    }
  });

  const hoursPerDay = useMemo<number>(
    () => (member.defaultWeeklyCapacity ?? 40) / 5,
    [member.defaultWeeklyCapacity]
  );

  const acrossDays = useMemo(
    () =>
      Math.ceil(
        formik.values.endDate.diff(formik.values.startDate, 'day', true)
      ),
    [formik.values.startDate, formik.values.endDate]
  );

  useEffect(() => {
    const { projectId } = formik.values;

    const currentProject =
      projects.find((item) => item.id === projectId) ?? project;

    projectsStore.getAvailableExpertises(currentProject.id);

    const currentClient = clients.find(
      (item) => item.id === currentProject.clientId
    );

    if (currentClient) {
      setClient(currentClient.legalName);
    }
  }, [formik.values.projectId, projects, clients]);

  useEffect(() => {
    if (newExpertise) {
      return;
    }

    formik.setFieldValue(
      Names.rate,
      expertises.find((item) => item.id === formik.values.expertise)?.rate ?? 0
    );
  }, [expertises, formik.values.expertise]);

  return (
    <Profile isOpen={active} handleClose={handleClose}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>Assignment</div>
        <IconButton onClick={() => handleClose(active)}>
          <CloseIcon className={classes.closeItem} />
        </IconButton>
      </div>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <div className={classes.inputWrapper}>
          <div
            className={clsx(classes.label, {
              [classes.selectedLabel]: selectedField === Names.userId
            })}
          >
            Member name
          </div>
          <TextField
            select
            fullWidth
            disabled={readOnly}
            name={Names.userId}
            variant="standard"
            className={classes.formItem}
            value={formik.values.userId}
            onChange={formik.handleChange}
            onFocus={() => setSelectedField(Names.userId)}
            onBlur={() => setSelectedField(null)}
          >
            {isPlaceholder && member.id === '' ? (
              <MenuItem className={classes.option} value="placeholder">
                Placeholder: {member.name}
              </MenuItem>
            ) : null}
            {allUsers.map((item) => (
              <MenuItem
                key={item.id}
                className={classes.option}
                value={item.id}
              >
                {item.type === 'placeholder'
                  ? `Placeholder: ${item.name}`
                  : item.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.inputWrapper}>
          <div
            className={clsx(classes.label, {
              [classes.selectedLabel]: selectedField === Names.projectId
            })}
          >
            Project name
          </div>
          <TextField
            select={!readOnly}
            fullWidth
            disabled={readOnly}
            name={Names.projectId}
            variant="standard"
            className={classes.formItem}
            value={readOnly ? project.name : formik.values.projectId}
            onChange={formik.handleChange}
            onFocus={() => setSelectedField(Names.projectId)}
            onBlur={() => setSelectedField(null)}
          >
            {projects
              ? projects.map((item) => (
                  <MenuItem
                    key={item.id}
                    className={classes.option}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))
              : ''}
          </TextField>
        </div>
        <div className={classes.inputWrapper}>
          <div className={classes.label}>Client</div>
          <TextField
            disabled
            variant="standard"
            className={classes.formItem}
            value={client}
          />
        </div>
        <div className={classes.inputWrapper}>
          <div
            className={clsx(classes.label, {
              [classes.selectedLabel]: selectedField === Names.rate
            })}
          >
            Billable rate (ext.)
          </div>
          {project.currency === ContractCurrencyVariant.usd ? (
            <div className={classes.valuteItem}>
              <TextField
                type="number"
                name="rateDollar"
                variant="standard"
                disabled={readOnly}
                className={classes.valute}
                value={formik.values.rate}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <AttachMoneyIcon
                      className={classes.valuteIcon}
                      fontSize="small"
                    />
                  )
                }}
              />
            </div>
          ) : null}
          <div className={classes.valuteItem}>
            <TextField
              type="number"
              name={Names.rate}
              variant="standard"
              disabled={!newExpertise || readOnly}
              className={classes.valute}
              value={
                project.currency === ContractCurrencyVariant.usd
                  ? formik.values.rate * rateStore.rate
                  : formik.values.rate
              }
              onChange={formik.handleChange}
              onFocus={() => setSelectedField(Names.rate)}
              onBlur={() => setSelectedField(null)}
              InputProps={{
                startAdornment: (
                  <CurrencyRubleIcon
                    className={classes.valuteIcon}
                    fontSize="small"
                  />
                )
              }}
            />
          </div>
          {project.currency === ContractCurrencyVariant.usd ? (
            <div className={classes.valuteItem}>
              <TextField
                type="number"
                disabled={readOnly}
                name="exchangeRate"
                variant="standard"
                className={classes.exchangeRate}
                value={rateStore.rate.toFixed(3)}
                InputProps={{
                  startAdornment: (
                    <CurrencyExchangeIcon
                      className={classes.valuteIcon}
                      fontSize="small"
                    />
                  )
                }}
              />
            </div>
          ) : null}
        </div>
        <div className={classes.inputWrapper}>
          <div
            className={clsx(classes.label, {
              [classes.selectedLabel]: selectedField === Names.expertise
            })}
          >
            Expertise
          </div>
          <TextField
            fullWidth
            disabled={readOnly}
            select={!newExpertise}
            name={Names.expertise}
            variant="standard"
            className={classes.formItem}
            value={formik.values.expertise}
            onChange={formik.handleChange}
            onFocus={() => setSelectedField(Names.expertise)}
            onBlur={() => setSelectedField(null)}
          >
            <MenuItem
              onClick={() => {
                setNewExpertise(true);
                formik.setFieldValue(Names.expertise, '');
              }}
            >
              <AddCircleOutlineIcon />
              Add placeholder
            </MenuItem>
            {expertises.map((item) => (
              <MenuItem
                key={item.id}
                className={classes.option}
                value={item.id}
              >
                {item.expertise}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.inputWrapper}>
          <div
            className={clsx(classes.label, {
              [classes.selectedLabel]: selectedField === Names.dailyHours
            })}
          >
            Hours/Day
          </div>
          <TextField
            type="number"
            disabled={readOnly}
            name={Names.dailyHours}
            variant="standard"
            className={classes.hoursItem}
            value={formik.values.dailyHours}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue(
                Names.totalHours,
                acrossDays * Number(e.target.value)
              );
              formik.handleChange(e);
            }}
            onFocus={() => setSelectedField(Names.dailyHours)}
            onBlur={() => setSelectedField(null)}
          />
          <div className={classes.smallLabel}>
            {Math.round((formik.values.dailyHours / hoursPerDay) * 100)}% of{' '}
            {hoursPerDay} h/d
          </div>
        </div>
        <div className={classes.inputWrapper}>
          <div
            className={clsx(classes.label, {
              [classes.selectedLabel]: selectedField === Names.totalHours
            })}
          >
            Total Hours
          </div>
          <TextField
            type="number"
            disabled={readOnly}
            name={Names.totalHours}
            variant="standard"
            className={classes.hoursItem}
            value={formik.values.totalHours}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue(
                Names.dailyHours,
                Math.round(Number(e.target.value) / acrossDays)
              );
              formik.handleChange(e);
            }}
            onFocus={() => setSelectedField(Names.totalHours)}
            onBlur={() => setSelectedField(null)}
          />
          <div className={classes.smallLabel}>across {acrossDays} days</div>
        </div>
        <div className={classes.inputWrapper}>
          <div
            className={clsx(classes.label, {
              [classes.selectedLabel]: selectedField === Names.startDate
            })}
          >
            Start date
          </div>
          <div className={classes.formItem}>
            <DatePicker
              disabled={readOnly}
              name={Names.startDate}
              date={formik.values.startDate}
              onChange={(value) => {
                formik.setFieldValue(Names.startDate, value, true);
              }}
              onFocus={() => setSelectedField(Names.startDate)}
              onBlur={() => setSelectedField(null)}
            />
          </div>
        </div>
        <div className={classes.inputWrapper}>
          <div
            className={clsx(classes.label, {
              [classes.selectedLabel]: selectedField === Names.endDate
            })}
          >
            End date
          </div>
          <div className={classes.formItem}>
            <DatePicker
              disabled={readOnly}
              name={Names.endDate}
              date={formik.values.endDate}
              onChange={(value) => {
                formik.setFieldValue(Names.endDate, value, true);
              }}
              onFocus={() => setSelectedField(Names.endDate)}
              onBlur={() => setSelectedField(null)}
            />
          </div>
        </div>
        <div className={classes.buttons}>
          {edit && !readOnly ? (
            <button
              className={classes.button}
              type="button"
              onClick={() => {
                if (readOnly) {
                  return;
                }

                if (isPlaceholder) {
                  plannerStore.deletePlaceholderAssignment(
                    assignment?.id ?? ''
                  );
                } else {
                  plannerStore.deleteAssignmentPlannerUser(
                    assignment?.id ?? ''
                  );
                }

                handleClose(true);
              }}
            >
              DELETE
            </button>
          ) : null}
          <button
            onClick={() => handleClose(true)}
            className={classes.button}
            type="button"
          >
            CANCEL
          </button>
          <button className={classes.fullButton} type="submit">
            SAVE
          </button>
        </div>
      </form>
    </Profile>
  );
};

export default observer(PlannerAssignment);
