import React, { FC, useCallback, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

// Components
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Close from '@mui/icons-material/Close';

// Layouts
import Profile from 'layouts/ProfileLayout';

// Stores
import timetrackerStore from 'stores/TimeTrackerStore';

// Types
import { IProject } from 'types/project';

// Styles
import classes from './AddTaskModal.module.scss';

interface AddTaskModalProps {
  isOpen: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  isEditMode: boolean;
  project: IProject;
}

const AddTaskModal: FC<AddTaskModalProps> = ({
  isOpen,
  setOpen,
  isEditMode,
  project
}) => {
  const [selectedField, setSelectedField] = useState<string>('');
  const { taskId, title } = timetrackerStore.taskInModal;

  const closeModal = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const handleTaskNameChange = (taskTitle: string) => {
    timetrackerStore.changeTaskInModal(taskTitle);
  };

  const addTask = () => {
    if (project?.id) {
      timetrackerStore.addTask(project?.id, title).then(() => {
        closeModal();
      });
    }
  };

  const updateTask = () => {
    timetrackerStore.patchTask(taskId, project.id, title).then(() => {
      closeModal();
    });
  };

  const deleteTask = () => {
    timetrackerStore.deleteTask(taskId).then(() => {
      closeModal();
    });
  };

  return (
    <Profile isOpen={isOpen} handleClose={closeModal}>
      <div className={classes.wrapper}>
        <div className={classes.headerContainer}>
          <div>
            {isEditMode ? (
              <span>Edit task</span>
            ) : (
              <>
                <span>New task for </span>
                <span style={{ color: project?.colour }}>{project?.name}</span>
              </>
            )}
          </div>
          <IconButton
            onClick={() => {
              closeModal();
            }}
          >
            <Close className={classes.close} />
          </IconButton>
        </div>
        <Grid container spacing={1} className={classes.gridBox}>
          <Grid item xs={3}>
            <div
              className={clsx(classes.label, {
                [classes.selectedName]: selectedField === 'taskName'
              })}
            >
              Task name
            </div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="standard-basic"
              value={title}
              variant="standard"
              fullWidth
              color="primary"
              InputProps={{
                classes: {
                  root: classes.font
                }
              }}
              onChange={(event) => handleTaskNameChange(event.target.value)}
              onFocus={() => setSelectedField('taskName')}
              onBlur={() => setSelectedField('')}
              className={classes.fullTextField}
            />
          </Grid>
        </Grid>
        <div className={classes.buttonBox}>
          {isEditMode && (
            <Button className={classes.delete} onClick={deleteTask}>
              DELETE
            </Button>
          )}
          <Button
            className={classes.cancel}
            onClick={(e) => {
              e.stopPropagation();
              closeModal();
            }}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            className={classes.add}
            onClick={(e) => {
              e.stopPropagation();

              if (isEditMode) {
                updateTask();
              } else {
                addTask();
              }
            }}
            disabled={!title}
          >
            {isEditMode ? 'UPDATE' : 'ADD'}
          </Button>
        </div>
      </div>
    </Profile>
  );
};

export default observer(AddTaskModal);
