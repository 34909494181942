import React, { FC } from 'react';

// Components
import Box from '@mui/material/Box';

// Styles
import classes from './TitleSettingsPages.module.scss';

interface PageProfileSettingsProps {
  title: string;
}

const TitleSettingsPages: FC<PageProfileSettingsProps> = ({ title }) => {
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.titleContainer}>
        <Box className={classes.title}>{title}</Box>
      </Box>
    </Box>
  );
};

export default TitleSettingsPages;
