import React, {
  FC,
  useCallback,
  useState,
  ChangeEvent,
  useEffect
} from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';

// Icons
import Close from '@mui/icons-material/Close';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// Layouts
import Profile from 'layouts/ProfileLayout';

// Components
import DatePicker from 'components/DatePicker';

// Stores
import rateStore from 'stores/RateStore';
import expertiseStore from 'stores/ExpertiseStore';

// Types
import { ExpertizeVariant } from 'types/member';
import { IRate } from 'types/rate';
import { IProject } from 'types/project';

// Styles
import classes from './SetNewBillableRateModal.module.scss';

interface IProps {
  item?: IRate;
  project: IProject;
  isOpen: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  checked: boolean;
  onExpertisesUpdate?: () => void;
}

// eslint-disable-next-line no-useless-escape
const numberRegex = /^\d*(\.\d*)?$/;

const SetNewBillableRate: FC<IProps> = ({
  item,
  project,
  isOpen,
  setOpen,
  checked,
  onExpertisesUpdate
}) => {
  const [expertise, setExpertise] = useState<string>('');
  const [rate, setRate] = useState<string>('0');
  const [dollarRate, setDollarRate] = useState<string>('0');
  const [exchangeRate, setExchangeRate] = useState<string>(`${rateStore.rate}`);
  const [newExpertise, setNewExpertise] = useState<boolean>(true);
  const [startAt, setStartAt] = useState<Dayjs | null>(null);

  const handleAddRate = useCallback(() => {
    if (item) {
      expertiseStore.updateExpertise(project.id, {
        id: item.id,
        projectId: project.id,
        expertise,
        rate: Number(rate),
        exchangeRate: Number(exchangeRate),
        startAt: startAt?.toISOString()
      });

      if (onExpertisesUpdate) {
        onExpertisesUpdate();
      }
    } else {
      expertiseStore.addExpertise(project.id, {
        projectId: project.id,
        expertise,
        rate: Number(rate),
        exchangeRate: Number(exchangeRate),
        startAt: startAt?.toISOString()
      });
    }
  }, [expertise, rate, exchangeRate, startAt]);

  const handleUpdate = useCallback(() => {
    if (item) {
      setExpertise(item.expertise);
      setRate(`${item.rate}`);

      if (item.exchangeRate) {
        setExchangeRate(`${item.exchangeRate}`);
      }

      setDollarRate(
        `${(
          item.rate / (item.exchangeRate ? item.exchangeRate : rateStore.rate)
        ).toFixed(2)}`
      );
      setStartAt(dayjs(item.startAt));
    }
  }, [item]);

  const startAtChange = useCallback(
    (changeEventDate: Dayjs | null) => {
      if (changeEventDate) {
        if (
          (changeEventDate.isAfter(dayjs(project?.startAt)) ||
            changeEventDate.isSame(dayjs(project?.startAt))) &&
          (changeEventDate.isBefore(dayjs(project?.endAt)) ||
            changeEventDate.isSame(dayjs(project?.endAt)))
        ) {
          setStartAt(changeEventDate);
        }
      }
    },
    [project]
  );

  const deleteProject = useCallback(() => {
    if (item) {
      expertiseStore.deleteExpertise(item);
    }
  }, [item]);

  useEffect(() => {
    if (isOpen && item) {
      handleUpdate();
    }
  }, [item]);

  const expertiseChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setExpertise(e.target.value);
  }, []);

  const rateChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (numberRegex.test(e.target.value)) {
        setRate(e.target.value);
        setDollarRate(
          (Number(e.target.value) / Number(exchangeRate)).toFixed(2)
        );
      }
    },
    [exchangeRate]
  );

  const dollarRateChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      console.log(e.target.value);

      if (numberRegex.test(e.target.value)) {
        setDollarRate(e.target.value);
        setRate(`${Math.round(Number(e.target.value) * Number(exchangeRate))}`);
      }
    },
    [exchangeRate]
  );

  const exchangeRateChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (
        numberRegex.test(e.target.value) /* && Number(e.target.value) !== 0 */
      ) {
        setExchangeRate(e.target.value);
        setRate(`${Math.round(Number(dollarRate) * Number(e.target.value))}`);
      }
    },
    [dollarRate]
  );

  const handleClose = useCallback(() => {
    setOpen((prev) => !prev);
  }, [isOpen]);

  return (
    <Profile isOpen={isOpen}>
      <Grid container spacing={1} className={classes.pageName}>
        <Grid item xs={11}>
          {item ? <h2>Edit rate</h2> : <h2>Set rate</h2>}
        </Grid>

        <Grid item xs={1}>
          <IconButton onClick={() => handleClose()}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>

      <Box className={classes.textFieldBox}>
        <Grid container spacing={1} className={classes.gridBox}>
          <Grid item xs={3}>
            <Typography className={classes.font}>Expertise</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="standard-basic"
              variant="standard"
              select={newExpertise}
              color="primary"
              fullWidth
              InputProps={{
                classes: {
                  root: classes.font
                }
              }}
              onChange={expertiseChange}
              value={expertise}
            >
              <MenuItem onClick={() => setNewExpertise(false)}>
                <AddCircleOutlineIcon />
                Add new expertise
              </MenuItem>
              {Object.values(ExpertizeVariant).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.gridBox}>
          <Grid item xs={12}>
            <Typography className={classes.font}>
              What is the new billable rate
            </Typography>
          </Grid>

          {checked ? (
            <Grid item xs={4}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.font
                  }
                }}
                variant="standard"
                value={dollarRate}
                onChange={dollarRateChange}
              />
            </Grid>
          ) : null}

          <Grid item xs={4}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRubleIcon />
                  </InputAdornment>
                ),
                classes: {
                  root: classes.font
                }
              }}
              variant="standard"
              value={rate}
              onChange={rateChange}
            />
          </Grid>

          {checked ? (
            <Grid item xs={4}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyExchangeIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.font
                  }
                }}
                variant="standard"
                value={exchangeRate}
                onChange={exchangeRateChange}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>

        <Grid container spacing={1} className={classes.gridBox}>
          <Grid item xs={12}>
            <Typography className={classes.font}>
              Apply this bilable rate to
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.font}>Time entries from</Typography>
          </Grid>
          <Grid item xs={5}>
            <DatePicker date={startAt} onChange={startAtChange} />
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.font}>onwards</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.buttonBox}>
        {item ? (
          <Button
            className={classes.cancel}
            onClick={() => {
              deleteProject();
              handleClose();
            }}
          >
            DELETE
          </Button>
        ) : (
          ''
        )}
        <Button
          className={classes.cancel}
          onClick={() => {
            handleClose();
          }}
        >
          CANCEL
        </Button>
        <Button
          className={classes.save}
          onClick={() => {
            handleAddRate();
            handleClose();
          }}
        >
          SAVE
        </Button>
      </Box>
    </Profile>
  );
};
export default observer(SetNewBillableRate);
