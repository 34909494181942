import { observer } from 'mobx-react-lite';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import moment from 'moment';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import Timeline, {
  SidebarHeader,
  TimelineHeaders,
  DateHeader,
  ReactCalendarItemRendererProps,
  IntervalRenderer,
  Interval,
  TimelineContext
} from 'react-calendar-timeline';

// Utils
import { boundedOffsetVisibleTimeEnd } from 'utils/plannerUtils';

// Stores
import plannerStore from 'stores/PlannerStore';
import projectsStore from 'stores/ProjectsStore';
import membersStore from 'stores/MembersStore';
import searchStore from 'stores/SearchStore';

// Styles
import 'react-calendar-timeline/lib/Timeline.css';
import './ProjectsTimeline.scss';

// Types
import { User } from 'types/user';
import { Placeholder } from 'types/placeholder';
import {
  AssignmentUser,
  TimelineProject,
  TimelineAssignmentType,
  TimelinePlaceholder
} from 'types/planner';

// Icons
import SplitIcon from 'assets/icons/split.svg';
import { ReactComponent as Satellite } from 'assets/icons/satellite.svg';

// Components
import TableFooter from 'components/TableFooter';
import Loading from 'components/Loading';
import NewPlannerAssignment from 'components/modals/NewPlannerAssignment';
import AddPlaceholderModal from 'components/modals/AddPlaceholderModal';
import PlannerPersonModal from 'components/modals/PlannerPersonModal';
import PlannerProject from '../PlannerProject';
import PlannerSelector from '../PlannerSelector';

interface TimelineUserSubGroup {
  id: string;
  type: 'user';
  title: React.ReactNode;
  height?: number;
  project: TimelineProject;
  user: User;
}

interface TimelinePlaceholderSubGroup {
  id: string;
  type: 'placeholder';
  title: React.ReactNode;
  height?: number;
  project: TimelineProject;
  placeholder: Placeholder;
}

interface TimelineSelectorSubGroup {
  id: string;
  type: 'selector';
  title: React.ReactNode;
  height?: number;
  project: TimelineProject;
}

type TimelineSubGroup =
  | TimelineUserSubGroup
  | TimelinePlaceholderSubGroup
  | TimelineSelectorSubGroup;

interface TimelineGroup {
  idx: number;
  project: TimelineProject;
  isOpen: boolean;
  subGroups: TimelineSubGroup[];
  allocation: number;
}

interface TimelineProjectAssignment {
  id: string;
  memberType: 'project';
  type: TimelineAssignmentType.Project;
  project: TimelineProject;
  group: string;
  start_time: number;
  end_time: number;
  weeksWorkTime: number[];
}

type TimelineUserAssignment = {
  id: string;
  memberType: 'user';
  project: TimelineProject;
  user: User;
  group: string;
  start_time: number;
  end_time: number;
} & (
  | {
      type: TimelineAssignmentType.User;
      assignment: AssignmentUser;
      weeksWorkTime: number[];
      dailyHours: number;
      weeklyCapacity: number;
    }
  | {
      type: TimelineAssignmentType.NewAssignment;
    }
);

type TimelinePlaceholderAssignment = {
  id: string;
  memberType: 'placeholder';
  project: TimelineProject;
  placeholder: Placeholder;
  group: string;
  start_time: number;
  end_time: number;
} & (
  | {
      type: TimelineAssignmentType.User;
      assignment: AssignmentUser;
      weeksWorkTime: number[];
      dailyHours: number;
      weeklyCapacity?: number;
    }
  | {
      type: TimelineAssignmentType.NewAssignment;
    }
);

type TimelineAssignment =
  | TimelineUserAssignment
  | TimelineProjectAssignment
  | TimelinePlaceholderAssignment;

type SelectorItem =
  | {
      type: 'user';
      member: User;
    }
  | {
      type: 'placeholder';
      member: Placeholder;
    }
  | {
      type: 'custom';
    };

const ProjectsTimeline: FC = () => {
  const isFirstRender = useRef(true);
  const isFirstLoading = useRef(true);
  const isTimeChangeFrozen = useRef(false);
  const headerRef = useRef<HTMLElement | null>(null);
  const scrollRef = useRef<HTMLElement | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isProfileSettingsOpen, setProfileSettingsOpen] =
    useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<TimelineAssignment | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [selectedPlaceholder, setSelectedPlaceholder] = useState<
    Placeholder | undefined
  >(undefined);
  const [groups, setGroups] = useState<TimelineGroup[]>([]);
  const [items, setItems] = useState<TimelineAssignment[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isPlaceholderOpen, setPlaceholderOpen] = useState(false);
  const [newPlaceholderGroup, setNewPlaceholderGroup] =
    useState<TimelineGroup | null>(null);
  const [newAssignment, setNewAssignment] = useState<TimelineAssignment | null>(
    null
  );

  const {
    projectsPagination,
    allUsers,
    assignments,
    loading,
    addedProject,
    addedUser,
    addedPlaceholder,
    addAssignment,
    visibleTimeStart,
    visibleTimeEnd,
    datePickerStart,
    datePickerEnd,
    minZoom,
    maxZoom
  } = plannerStore;
  const { updatedMember } = membersStore;
  const { updatedProject } = projectsStore;
  const { query } = searchStore;

  const sidebarRow = (left: React.ReactNode, right: React.ReactNode) => {
    return (
      <Grid container className="header">
        <Grid item xs={9} className="cell">
          {left}
        </Grid>
        <Grid item xs={3} className="cell">
          {right}
        </Grid>
      </Grid>
    );
  };

  const changeDatePicker = () => {
    if (!datePickerStart || !datePickerEnd) {
      return;
    }

    plannerStore.setVisibleTimeStart(datePickerStart);
    plannerStore.setVisibleTimeEnd(datePickerEnd);
  };

  const calculateWeekCount = (
    start: moment.Moment | Date | number,
    end: moment.Moment | Date | number
  ) => {
    const weekLength = 604800000; // 24 * 7 * 60 * 60 * 1000
    const weekCount = Math.ceil((end.valueOf() - start.valueOf()) / weekLength);

    return weekCount;
  };

  const handleMouseUp = () => {
    if (isTimeChangeFrozen.current) {
      isTimeChangeFrozen.current = false;
    }
  };

  const getAllocation = (project: TimelineProject): number => {
    if (project.intervals.length === 0) {
      return 0;
    }

    const start = moment(visibleTimeStart);

    if (start > moment(project.intervals[project.intervals.length - 1][1])) {
      return project.allocations[project.allocations.length - 1];
    }

    let i = 0;
    while (
      i < project.intervals.length &&
      start > moment(project.intervals[i][1])
    ) {
      i += 1;
    }

    return project.allocations[i];
  };

  const openUserSettings = useCallback((user: User) => {
    setSelectedUser(user);
    setSelectedPlaceholder(undefined);
    setProfileSettingsOpen(true);
  }, []);

  const openPlaceholderSettings = useCallback((placeholder: Placeholder) => {
    setSelectedUser(undefined);
    setSelectedPlaceholder(placeholder);
    setProfileSettingsOpen(true);
  }, []);

  const handlePlaceholderSubmit = useCallback(
    (name: string, expertise: string) => {
      const group = newPlaceholderGroup;

      if (!group) {
        return;
      }

      const placeholder: TimelinePlaceholder = {
        id: '',
        name,
        defaultExpertize: expertise,
        projects: [],
        workTime: [],
        intervals: []
      };

      group.subGroups.splice(group.subGroups.length - 1, 0, {
        id: `${group.project.id},`,
        project: group.project,
        type: 'placeholder',
        title: <div className="newSubGroup">Placeholder: {name} </div>,
        height: 49,
        placeholder
      });

      setGroups(groups.slice());
    },
    [newPlaceholderGroup]
  );

  const getAvailableUsers = useCallback(
    (project: TimelineProject) => {
      const available: SelectorItem[] = [{ type: 'custom' }];

      for (let i = 0; i < allUsers.length; i += 1) {
        let isAvailable = true;
        const first = allUsers[i];

        for (let j = 0; j < project.users.length; j += 1) {
          const second = project.users[j];

          if (first.id === second.id) {
            isAvailable = false;
            break;
          }
        }

        if (isAvailable) {
          if (first.type === 'user') {
            available.push({ type: 'user', member: first });
          } else {
            available.push({ type: 'placeholder', member: first });
          }
        }
      }

      return available;
    },
    [allUsers]
  );

  const getSelectorChangeHandler = useCallback(
    (group: TimelineGroup) => (option: SelectorItem) => {
      if (option.type === 'custom') {
        setPlaceholderOpen(true);
        setNewPlaceholderGroup(groups[group.idx]);

        return false;
      }

      const subGroup = {
        id: `${group.project.id},${option.member.id}`,
        height: 49,
        project: group.project
      };

      // generate new subgroup that was selected from selector
      if (option.type === 'user') {
        group.subGroups.splice(group.subGroups.length - 1, 0, {
          ...subGroup,
          type: 'user',
          title: (
            <ButtonBase
              className="newSubGroup"
              onClick={() => openUserSettings(option.member)}
            >
              {option.member.name}
            </ButtonBase>
          ),
          user: option.member
        });
      } else if (option.type === 'placeholder') {
        group.subGroups.splice(group.subGroups.length - 1, 0, {
          ...subGroup,
          type: 'placeholder',
          title: (
            <div className="newSubGroup">Placeholder: {option.member.name}</div>
          ),
          placeholder: option.member
        });
      }

      setGroups(groups.slice());

      return true;
    },
    [groups]
  );

  const getPlannerProjects = useCallback(
    (projects: TimelineProject[]) => {
      const convertedGroups: TimelineGroup[] = [];
      for (let i = 0; i < projects.length; i += 1) {
        const project = projects[i];
        const isCached =
          groups.length > i && groups[i].project.id === project.id;

        if (isCached) {
          const group = groups[i];
          const newGroup = {
            ...group,
            project
          };

          if (group.isOpen) {
            newGroup.subGroups = project.users.map((user) => ({
              id: `${project.id},${user.id}`,
              type: 'user',
              title: (
                <ButtonBase
                  className="subGroup"
                  onClick={() => openUserSettings(user)}
                >
                  {user.name}
                </ButtonBase>
              ),
              height: 49,
              project,
              user
            }));

            newGroup.subGroups = newGroup.subGroups.concat(
              project.placeholders.map((placeholder) => ({
                id: `${project.id},${placeholder.id}`,
                type: 'placeholder',
                title: (
                  <ButtonBase
                    className="subGroup"
                    onClick={() => openPlaceholderSettings(placeholder)}
                  >
                    Placeholder: {placeholder.name}
                  </ButtonBase>
                ),
                height: 49,
                project,
                placeholder
              }))
            );

            newGroup.subGroups.push({
              id: `selector${group.idx}`,
              type: 'selector',
              title: (
                <PlannerSelector
                  fullWidth
                  placeholder="Assign User..."
                  options={getAvailableUsers(project)}
                  getOptionLabel={(option: SelectorItem) => {
                    if (option.type === 'custom') {
                      return 'Placeholder';
                    }

                    if (option.type === 'user') {
                      return option.member.name;
                    }

                    return `Placeholder: ${option.member.name}`;
                  }}
                  onChange={getSelectorChangeHandler(group)}
                />
              ),
              height: 48,
              project
            });
          }

          convertedGroups.push(newGroup);
        } else {
          convertedGroups.push({
            idx: i,
            project,
            isOpen: false,
            subGroups: [],
            allocation: getAllocation(project)
          });
        }
      }

      setGroups(convertedGroups);
    },
    [groups]
  );

  const getPlannerAssignments = useCallback((projects: TimelineProject[]) => {
    const convertedItems: TimelineAssignment[] = [];
    for (let i = 0; i < projects.length; i += 1) {
      const project = projects[i];
      let workTimeOffset = 0;
      for (let j = 0; j < project.intervals.length; j += 1) {
        const interval = project.intervals[j];

        const startTime = moment(interval[0]).valueOf();
        const endTime = moment(interval[1]).valueOf();
        const weekCount = calculateWeekCount(startTime, endTime);

        const weeksWorkTime = project.workTime.slice(
          workTimeOffset,
          workTimeOffset + weekCount
        );

        convertedItems.push({
          id: `${i},${j}`,
          memberType: 'project',
          type: TimelineAssignmentType.Project,
          project,
          group: project.id,
          start_time: startTime,
          end_time: endTime,
          weeksWorkTime
        });
        workTimeOffset += weekCount;
      }

      for (let j = 0; j < project.users.length; j += 1) {
        const user = project.users[j];
        for (let k = 0; k < user.intervals.length; k += 1) {
          const assignment = user.intervals[k];
          convertedItems.push({
            id: `sub:${i},${j},${k}`,
            memberType: 'user',
            type: TimelineAssignmentType.User,
            project,
            user,
            assignment,
            group: `${project.id},${user.id}`,
            start_time: moment(assignment.startAt).startOf('day').valueOf(),
            end_time: moment(assignment.endAt).endOf('day').valueOf(),
            weeksWorkTime: assignment.workTime,
            dailyHours: assignment.dailyHours,
            weeklyCapacity: user.defaultWeeklyCapacity
          });
        }
      }

      for (let j = 0; j < project.placeholders.length; j += 1) {
        const placeholder = project.placeholders[j];
        for (let k = 0; k < placeholder.intervals.length; k += 1) {
          const assignment = placeholder.intervals[k];
          convertedItems.push({
            id: `sub:${i},${j + project.users.length},${k}`,
            memberType: 'placeholder',
            type: TimelineAssignmentType.User,
            project,
            placeholder,
            assignment,
            group: `${project.id},${placeholder.id}`,
            start_time: moment(assignment.startAt).startOf('day').valueOf(),
            end_time: moment(assignment.endAt).endOf('day').valueOf(),
            weeksWorkTime: assignment.workTime,
            dailyHours: assignment.dailyHours,
            weeklyCapacity: placeholder.defaultWeeklyCapacity
          });
        }
      }
    }
    setItems(convertedItems);
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  useEffect(() => {
    changeDatePicker();
  }, [datePickerStart, datePickerEnd]);

  useEffect(() => {
    setPage(0);
  }, [query]);

  const getPlannerData = useCallback(
    (projects: TimelineProject[]) => {
      getPlannerProjects(projects);
      getPlannerAssignments(projects);

      if (query) {
        searchStore.setOptions(projects);
      }

      searchStore.setLoadingState(false);
    },
    [query, groups]
  );

  useEffect(() => {
    if (isFirstLoading.current) {
      searchStore.setOptionLabelHandler(
        (option: unknown) => (option as TimelineProject).name
      );
    }

    if (!isFirstRender.current && !loading) {
      isFirstLoading.current = false;
    }
  }, [loading]);

  useEffect(() => {
    let isActive = true;

    plannerStore.getPlannerAllUsers();
    plannerStore
      .getPlannerProjects(page, rowsPerPage, query || undefined)
      .then((projects) => {
        if (!isActive) {
          return;
        }

        getPlannerData(projects);
      });

    searchStore.setLoadingState(!isFirstRender.current);
    isFirstRender.current = false;

    return () => {
      isActive = false;
    };
  }, [
    assignments,
    addedProject,
    updatedProject,
    addedUser,
    addedPlaceholder,
    updatedMember,
    addAssignment,
    query,
    page,
    rowsPerPage
  ]);

  const flattenedGroups = useMemo(() => {
    const flattened = [];
    for (let i = 0; i < groups.length; i += 1) {
      const group = groups[i];

      const foldToggle = (isFoldOpen: boolean) => {
        const newGroups = groups.slice();
        const tmpGroup = newGroups[group.idx];

        tmpGroup.isOpen = isFoldOpen;

        if (isFoldOpen) {
          // generate Users (subgroups) from planner data
          for (let j = 0; j < tmpGroup.project.users.length; j += 1) {
            const user = tmpGroup.project.users[j];
            tmpGroup.subGroups.push({
              id: `${tmpGroup.project.id},${user.id}`,
              type: 'user',
              title: (
                <ButtonBase
                  className="subGroup"
                  onClick={() => openUserSettings(user)}
                >
                  {user.name}
                </ButtonBase>
              ),
              height: 49,
              project: tmpGroup.project,
              user
            });
          }

          // generate Placeholders (subgroups) from planner data
          for (let j = 0; j < tmpGroup.project.placeholders.length; j += 1) {
            const placeholder = tmpGroup.project.placeholders[j];
            tmpGroup.subGroups.push({
              id: `${tmpGroup.project.id},${placeholder.id}`,
              type: 'placeholder',
              title: (
                <ButtonBase
                  className="subGroup"
                  onClick={() => openPlaceholderSettings(placeholder)}
                >
                  Placeholder: {placeholder.name}
                </ButtonBase>
              ),
              height: 49,
              project: tmpGroup.project,
              placeholder
            });
          }

          // generate PlannerSelector
          tmpGroup.subGroups.push({
            id: `selector${group.idx}`,
            type: 'selector',
            title: (
              <PlannerSelector
                fullWidth
                placeholder="Assign User..."
                options={getAvailableUsers(tmpGroup.project)}
                getOptionLabel={(option: SelectorItem) => {
                  if (option.type === 'custom') {
                    return 'Placeholder';
                  }

                  if (option.type === 'user') {
                    return option.member.name;
                  }

                  return `Placeholder: ${option.member.name}`;
                }}
                onChange={getSelectorChangeHandler(tmpGroup)}
              />
            ),
            height: 48,
            project: tmpGroup.project
          });
        } else {
          tmpGroup.subGroups = [];
        }

        setGroups(newGroups);
      };

      // generate PlannerMember
      flattened.push({
        id: group.project.id,
        title: sidebarRow(
          <PlannerProject
            project={group.project}
            openViewProjects={group.isOpen}
            setOpenViewProjects={foldToggle}
          />,
          <div className="futureAllocation">{group.allocation} hrs</div>
        )
      });

      for (let j = 0; j < group.subGroups.length; j += 1) {
        const subGroup = group.subGroups[j];
        flattened.push({
          id: subGroup.id,
          title: sidebarRow(subGroup.title, null),
          height: subGroup.height
        });
      }
    }

    return flattened;
  }, [groups]);

  const weekIntervalRenderer = useCallback(
    (data: IntervalRenderer<Interval> | undefined) => {
      const startMonth = moment(
        data?.intervalContext.interval.startTime
      ).format('MMM');
      const endMonth = moment(data?.intervalContext.interval.endTime).format(
        'MMM'
      );

      const format =
        (data?.intervalContext.interval.labelWidth ?? 130) >= 130
          ? 'MMMM'
          : 'MMM';

      const week = data?.intervalContext.intervalText;
      const month =
        startMonth === endMonth
          ? moment(data?.intervalContext.interval.startTime).format(format)
          : `${startMonth} - ${endMonth}`;

      return (
        <div
          {...data?.getIntervalProps({ style: { height: '100%' } })}
          className={clsx('rct-dateHeader', 'weekInterval', {
            current: week === moment().format('W')
          })}
        >
          <div className="week">{week ?? null} </div>
          <div className="month">{data ? month : null} </div>
        </div>
      );
    },
    []
  );

  const dayIntervalRenderer = useCallback(
    (data: IntervalRenderer<Interval> | undefined) => {
      const now = moment();
      const thisWeek = now.format('W');
      const thisDay = now.format('D');

      const startTime = moment(data?.intervalContext.interval.startTime);
      const week = startTime.format('W');
      const day = data?.intervalContext.intervalText;

      return (
        <div
          {...data?.getIntervalProps()}
          className={clsx('rct-dateHeader', 'dayInterval', {
            firstDay: day === startTime.startOf('isoWeek').format('D'),
            currentWeek: week === thisWeek,
            currentDay: week === thisWeek && day === thisDay
          })}
        >
          <span>{day}</span>
        </div>
      );
    },
    []
  );

  // TODO: somehow take out common implementations of this function?
  const itemRenderer = (
    data: ReactCalendarItemRendererProps<TimelineAssignment>
  ) => {
    const data0 = data as {
      timelineContext: TimelineContext;
    } & ReactCalendarItemRendererProps<TimelineAssignment>;

    const { start_time: startTime, end_time: endTime, type } = data0.item;
    const { canvasTimeStart, canvasTimeEnd } = data0.timelineContext;
    const { selected } = data0.itemContext;

    // see https://github.com/namespace-ee/react-calendar-timeline/blob/master/src/lib/utility/calendar.js#L216
    const calculateCanvasWidth = () => {
      const effectiveStartTime = Math.max(startTime, canvasTimeStart);
      const effectiveEndTime = Math.min(endTime, canvasTimeEnd);

      const { width } = data0.itemContext.dimensions;
      const start = canvasTimeStart;
      const end = canvasTimeEnd;
      const range = end - start;

      const canvasWidth =
        width / ((effectiveEndTime - effectiveStartTime) / range);

      return canvasWidth;
    };

    const canvasWidth = calculateCanvasWidth();
    // see https://github.com/namespace-ee/react-calendar-timeline/blob/master/src/lib/utility/calendar.js#L28
    const calculateXPositionForTime = (time: number) => {
      const widthToZoomRatio = canvasWidth / (canvasTimeEnd - canvasTimeStart);
      const timeOffset = time - canvasTimeStart;

      return timeOffset * widthToZoomRatio;
    };

    const calculateTimeForXPosition = (leftOffset: number) => {
      const timeToPxRatio = (canvasTimeEnd - canvasTimeStart) / canvasWidth;
      const timeFromCanvasTimeStart = timeToPxRatio * leftOffset;

      return timeFromCanvasTimeStart + canvasTimeStart;
    };

    const calculateWidth = (
      left: moment.Moment | Date | number,
      right: moment.Moment | Date | number
    ) => {
      return (
        calculateXPositionForTime(right.valueOf()) -
        calculateXPositionForTime(left.valueOf())
      );
    };

    const visibleStart = Math.max(canvasTimeStart, startTime);
    const end = endTime;

    const firstWeekStart = moment(visibleStart).startOf('isoWeek');
    const firstWeekEnd = moment(visibleStart).endOf('isoWeek');
    const lastWeekStart = moment(end).startOf('isoWeek');
    const isSameWeek =
      endTime - startTime <= firstWeekEnd.valueOf() - firstWeekStart.valueOf();

    let visibleEnd = moment(end).endOf('isoWeek');
    let isEndVisible = true;

    if (canvasTimeEnd < end) {
      isEndVisible = false;
      visibleEnd = moment(canvasTimeEnd).startOf('isoWeek');
    }

    const singleWeekWidth = calculateWidth(startTime, endTime);
    const firstWeekWidth = calculateWidth(visibleStart, firstWeekEnd);
    const weekWidth = calculateWidth(firstWeekStart, firstWeekEnd);
    const lastWeekWidth = calculateWidth(lastWeekStart, endTime);

    let offset = 0;

    if (canvasTimeStart > startTime) {
      const absoluteFirstWeekEnd = moment(startTime).endOf('isoWeek');
      offset = calculateWeekCount(absoluteFirstWeekEnd, visibleStart);
    }

    const visibleWeekCount = calculateWeekCount(visibleStart, visibleEnd);

    const minWidth = 40;

    const generateWeek = (
      id: string | number,
      workTimeHours: number,
      state: 'first' | 'mid' | 'last' | 'single',
      empty = false
    ) => {
      let width = weekWidth;

      if (state === 'first') {
        width = firstWeekWidth;
      } else if (state === 'last') {
        width = lastWeekWidth;
      } else if (state === 'single') {
        width = singleWeekWidth;
      }

      return (
        <div
          key={id}
          className={clsx('week', {
            firstWeek: state === 'first',
            lastWeek: state === 'last',
            singleWeek: state === 'single'
          })}
          style={{ width }}
        >
          {!empty ? (
            <div className="weekLoadWrapper">
              <div className="workTimeHours">
                {type === TimelineAssignmentType.User
                  ? `${data0.item.dailyHours ?? 0} h/d ${workTimeHours}h`
                  : `${workTimeHours}h`}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      );
    };

    const weeks = [];

    if (type !== TimelineAssignmentType.NewAssignment) {
      const { weeksWorkTime } = data0.item;

      if (isSameWeek && singleWeekWidth > minWidth) {
        weeks.push(generateWeek(0, weeksWorkTime[0], 'single'));
      } else {
        let idx = offset;
        weeks.push(
          generateWeek(
            0,
            weeksWorkTime[idx],
            'first',
            firstWeekWidth <= minWidth
          )
        );

        idx += 1;
        for (let i = 2; i < visibleWeekCount; i += 1) {
          weeks.push(generateWeek(i, weeksWorkTime[idx], 'mid'));
          idx += 1;
        }

        if (isEndVisible && lastWeekWidth > minWidth) {
          weeks.push(
            generateWeek(visibleWeekCount, weeksWorkTime[idx], 'last')
          );
        }
      }
    }

    let backgroundColor: string | undefined;

    if (type === TimelineAssignmentType.Project) {
      backgroundColor = data0.item.project.colour;
    } else {
      backgroundColor = selected
        ? '#f36d25' // $color-primary
        : '#4caf50'; // $color-green
    }

    const handleMouseMove = (e: React.MouseEvent) => {
      const { left } = data0.itemContext.dimensions;
      const rect = e.currentTarget.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const time = moment(calculateTimeForXPosition(left + offsetX));

      const start = moment(time).startOf('day').valueOf();
      const halfADay = 10.5 * 60 * 60 * 1000;
      const dayStartEnd = (time.valueOf() - start - halfADay) / halfADay;

      const edgeTime =
        dayStartEnd < 0 ? start : moment(time).endOf('day').valueOf();
      const splitTime =
        dayStartEnd < 0
          ? start
          : moment(time).add(1, 'day').startOf('day').valueOf();

      const closeToBounds =
        Math.abs(edgeTime - data0.item.end_time) < halfADay ||
        Math.abs(edgeTime - data0.item.start_time) < halfADay;

      let cursorWrapper =
        e.currentTarget.querySelector<HTMLDivElement>('.cursorWrapper');

      if (!closeToBounds && Math.abs(dayStartEnd) > 0.6) {
        if (!cursorWrapper) {
          cursorWrapper = document.createElement('div');
          cursorWrapper.classList.add('cursorWrapper');
          cursorWrapper.ariaHidden = 'true';

          cursorWrapper.onclick = () => {
            if (type === TimelineAssignmentType.Project) {
              const userAssignmentIds: string[] = [];
              const placeholderAssignmentIds: string[] = [];

              for (let i = 0; i < data0.item.project.users.length; i += 1) {
                const user = data0.item.project.users[i];
                const filteredAssignments = user.intervals.filter(
                  (assignment) => {
                    const startAt = moment(assignment.startAt).valueOf();
                    const endAt = moment(assignment.endAt).valueOf();

                    return startAt < splitTime && splitTime < endAt;
                  }
                );

                if (filteredAssignments.length !== 1) {
                  // eslint-disable-next-line no-continue
                  continue;
                }

                userAssignmentIds.push(filteredAssignments[0].id);
              }

              for (
                let i = 0;
                i < data0.item.project.placeholders.length;
                i += 1
              ) {
                const placeholder = data0.item.project.placeholders[i];
                const filteredAssignments = placeholder.intervals.filter(
                  (assignment) => {
                    const startAt = moment(assignment.startAt).valueOf();
                    const endAt = moment(assignment.endAt).valueOf();

                    return startAt < splitTime && splitTime < endAt;
                  }
                );

                if (filteredAssignments.length !== 1) {
                  // eslint-disable-next-line no-continue
                  continue;
                }

                placeholderAssignmentIds.push(filteredAssignments[0].id);
              }

              if (userAssignmentIds.length > 0) {
                plannerStore.splitPlannerAssignment(
                  splitTime,
                  userAssignmentIds
                );
              }

              if (placeholderAssignmentIds.length > 0) {
                plannerStore.splitPlaceholderAssignment(
                  splitTime,
                  placeholderAssignmentIds
                );
              }
            } else if (type === TimelineAssignmentType.User) {
              if (data0.item.memberType === 'user') {
                plannerStore.splitPlannerAssignment(splitTime, [
                  data0.item.assignment.id
                ]);
              } else if (data0.item.memberType === 'placeholder') {
                plannerStore.splitPlaceholderAssignment(splitTime, [
                  data0.item.assignment.id
                ]);
              }
            }
          };

          const cursor = document.createElement('img');
          cursor.id = 'cursor';
          cursor.src = SplitIcon;
          cursor.ondragstart = () => false;

          cursorWrapper.append(cursor);
          e.currentTarget.append(cursorWrapper);
        }

        const edgePosition = calculateXPositionForTime(edgeTime);
        cursorWrapper.style.left = `${edgePosition - left}px`;
      } else if (cursorWrapper) {
        e.currentTarget.removeChild(cursorWrapper);
      }
    };

    const handleMouseOut = (e: React.MouseEvent) => {
      if ((e.target as HTMLElement).id !== 'cursor') {
        return;
      }

      const cursor =
        e.currentTarget.querySelector<HTMLDivElement>('.cursorWrapper');

      if (cursor) {
        e.currentTarget.removeChild(cursor);
      }
    };

    const handleMouseDown = () => {
      if (selected) {
        isTimeChangeFrozen.current = true;
      }
    };

    const props = data.getItemProps({ onMouseDown: handleMouseDown });

    return (
      <div
        {...(type === TimelineAssignmentType.NewAssignment ||
        type === TimelineAssignmentType.User
          ? {
              ...props,
              onMouseMove: selected ? handleMouseMove : undefined,
              onMouseOut: handleMouseOut
            }
          : {
              onMouseMove: handleMouseMove,
              onMouseOut: handleMouseOut
            })}
        style={{
          // eslint-disable-next-line react/prop-types
          ...props.style,
          backgroundColor,
          ...(type === TimelineAssignmentType.Project &&
          data0.item.project.isGhost
            ? { opacity: 0.5 }
            : {})
        }}
        className={clsx({
          item: type === TimelineAssignmentType.Project,
          subItem: type === TimelineAssignmentType.User,
          newItem: type === TimelineAssignmentType.NewAssignment
        })}
      >
        {weeks}
      </div>
    );
  };

  const handleBoundsChange = (
    canvasTimeStart: number,
    canvasTimeEnd: number
  ) => {
    if (!scrollRef.current || !headerRef.current) {
      plannerStore.setVisibleTimeEndOffsetCallback(undefined);

      return;
    }

    plannerStore.setVisibleTimeEndOffsetCallback(
      boundedOffsetVisibleTimeEnd(
        canvasTimeStart,
        canvasTimeEnd,
        scrollRef.current.offsetWidth,
        headerRef.current.offsetWidth,
        172_800_000 // 1000 * 60 * 60 * 24 * 2
      )
    );
  };

  const handleTimeChange = (timeStart: number, timeEnd: number) => {
    if (isTimeChangeFrozen.current) {
      return;
    }

    plannerStore.setVisibleTimeStart(timeStart);
    plannerStore.setVisibleTimeEnd(timeEnd);

    let needsRefresh = false;
    for (let i = 0; i < groups.length; i += 1) {
      const group = groups[i];
      const newAllocation = getAllocation(group.project);

      if (group.allocation !== newAllocation) {
        if (!needsRefresh) {
          needsRefresh = true;
        }

        group.allocation = newAllocation;
      }
    }

    if (needsRefresh) {
      setGroups(groups.slice());
    }
  };

  const handleCanvasClick = (groupId: string, time: number) => {
    const newAssignmentDuration = 172_800_000; // 2 * 24 * 60 * 60 * 1000
    const startAt = moment(time).startOf('day').valueOf();
    const endAt = startAt + newAssignmentDuration;

    const foundOverlapping = items.find(
      (item) =>
        item.group === groupId &&
        item.start_time <= time &&
        item.end_time >= time
    );

    if (
      foundOverlapping ||
      (newAssignment &&
        newAssignment.group === groupId &&
        newAssignment.start_time <= time &&
        newAssignment.end_time >= time)
    ) {
      return;
    }

    const flatted = groups.reduce(
      (acc: TimelineSubGroup[], cur) => acc.concat(cur.subGroups),
      []
    );
    const projectFound = flatted.find((group) => group.id === groupId);

    if (!projectFound || projectFound.type === 'selector') {
      return;
    }

    const common = {
      id: `new:${groupId}`,
      project: projectFound.project,
      group: groupId,
      start_time: startAt,
      end_time: endAt
    };

    let assignment: TimelineAssignment | null;

    if (projectFound.type === 'user') {
      assignment = {
        ...common,
        type: TimelineAssignmentType.NewAssignment,
        memberType: 'user',
        user: projectFound.user
      };
    } else {
      assignment = {
        ...common,
        type: TimelineAssignmentType.NewAssignment,
        memberType: 'placeholder',
        placeholder: projectFound.placeholder
      };
    }

    items.push(assignment);
    setNewAssignment(assignment);
    setItems(items.filter((item) => item !== newAssignment));
  };

  const handleItemDoubleClick = (itemId: string) => {
    const foundItem = items?.find((item) => item.id === itemId);

    if (foundItem && foundItem.project) {
      setIsOpen(true);
      setActiveItem(foundItem);
    }
  };

  const updateAssignmentTime = (
    item: TimelineAssignment,
    startAt: number,
    endAt: number
  ) => {
    if (item.type !== TimelineAssignmentType.User) {
      return;
    }

    if (item.memberType === 'user') {
      plannerStore.updateAssignmentPlannerUser({
        id: item.assignment.id,
        startAt: moment(startAt).format(),
        endAt: moment(endAt).format()
      });
    } else if (item.memberType === 'placeholder') {
      plannerStore.updatePlaceholderAssignment({
        id: item.assignment.id,
        startAt: moment(startAt).format(),
        endAt: moment(endAt).format()
      });
    }
  };

  const handleItemResize = (
    itemId: string,
    time: number,
    edge: 'left' | 'right'
  ) => {
    const foundItemIndex = items.findIndex((item) => item.id === itemId);

    if (foundItemIndex === -1) {
      return;
    }

    const foundItem = items[foundItemIndex];

    const startTime = edge === 'left' ? time : foundItem.start_time;
    const endTime = edge === 'left' ? foundItem.end_time : time;

    if (foundItem.type === TimelineAssignmentType.User) {
      updateAssignmentTime(foundItem, startTime, endTime);
    }

    const newItems = items.slice();
    newItems[foundItemIndex].start_time = startTime;
    newItems[foundItemIndex].end_time = endTime;
    setItems(newItems);
  };

  const handleItemMove = (itemId: string, dragTime: number) => {
    const foundItemIndex = items.findIndex((item) => item.id === itemId);

    if (foundItemIndex === -1) {
      return;
    }

    const foundItem = items[foundItemIndex];

    const startTime = dragTime;
    const endTime = dragTime + (foundItem.end_time - foundItem.start_time);

    if (foundItem.type === TimelineAssignmentType.User) {
      updateAssignmentTime(foundItem, startTime, endTime);
    }

    const newItems = items.slice();
    newItems[foundItemIndex].start_time = startTime;
    newItems[foundItemIndex].end_time = endTime;
    setItems(newItems);
  };

  return (
    <Box className="projectBox">
      {isFirstLoading.current && loading ? (
        <Loading table />
      ) : (
        <Timeline
          headerRef={(e: HTMLElement) => {
            headerRef.current = e;
          }}
          scrollRef={(e: HTMLElement) => {
            scrollRef.current = e;
          }}
          groups={flattenedGroups}
          items={items}
          itemRenderer={itemRenderer}
          itemTouchSendsClick={false}
          canChangeGroup={false}
          canResize="both"
          itemHeightRatio={1}
          lineHeight={64}
          defaultTimeStart={moment().startOf('week').toDate()}
          defaultTimeEnd={moment().startOf('month').add(1, 'month').toDate()}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          minZoom={minZoom}
          maxZoom={maxZoom}
          onBoundsChange={handleBoundsChange}
          onTimeChange={handleTimeChange}
          onCanvasClick={handleCanvasClick}
          onItemDoubleClick={handleItemDoubleClick}
          onItemResize={handleItemResize}
          onItemMove={handleItemMove}
        >
          <TimelineHeaders className="sticky" style={{ display: 'flex' }}>
            <SidebarHeader>
              {({ getRootProps }) => (
                <div {...getRootProps()}>
                  {sidebarRow(
                    <div className="title">Project name</div>,
                    <div className="title">Future allocation</div>
                  )}
                </div>
              )}
            </SidebarHeader>
            <DateHeader
              unit="isoWeek"
              labelFormat="W"
              height={33}
              intervalRenderer={weekIntervalRenderer}
            />
            <DateHeader
              unit="day"
              labelFormat="D"
              intervalRenderer={dayIntervalRenderer}
            />
          </TimelineHeaders>
        </Timeline>
      )}
      {!isFirstLoading.current || !loading ? (
        <TableFooter
          rowsLength={projectsPagination?.totalItems ?? groups.length}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      ) : null}
      {flattenedGroups.length === 0 ? (
        <Box className="emptyWrapper">
          <Satellite />
          <Typography>There is no projects here. Add one!</Typography>
        </Box>
      ) : null}
      {activeItem && activeItem.type !== TimelineAssignmentType.Project ? (
        <NewPlannerAssignment
          active={isOpen}
          setActive={setIsOpen}
          member={
            activeItem.memberType === 'user'
              ? activeItem.user
              : activeItem.placeholder
          }
          project={activeItem.project}
          assignment={
            activeItem.type === TimelineAssignmentType.User
              ? activeItem.assignment
              : undefined
          }
          assignmentStart={activeItem.start_time}
          assignmentEnd={activeItem.end_time}
          edit={activeItem.type !== TimelineAssignmentType.NewAssignment}
          isPlaceholder={activeItem.memberType === 'placeholder'}
          readOnly={activeItem.project.isGhost}
        />
      ) : null}
      <AddPlaceholderModal
        isOpen={isPlaceholderOpen}
        setOpen={setPlaceholderOpen}
        onSubmit={handlePlaceholderSubmit}
      />
      {selectedUser || selectedPlaceholder ? (
        <PlannerPersonModal
          user={selectedUser}
          placeholder={selectedPlaceholder}
          isOpen={isProfileSettingsOpen}
          setOpen={setProfileSettingsOpen}
          title="Edit person"
        />
      ) : null}
    </Box>
  );
};

export default observer(ProjectsTimeline);
