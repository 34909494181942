import React, { FC, useState, useEffect, useCallback } from 'react';

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';

import { observer } from 'mobx-react-lite';

// Icons
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

// Components
import SetNewBillableRate from 'components/modals/SetNewBillableRateModal';

// Stores
import expertiseStore from 'stores/ExpertiseStore';

// Types
import { IRate } from 'types/rate';
import { IProject } from 'types/project';

// Styles
import classes from './ListBillableRateUSD.module.scss';

export interface IBillableList {
  project: IProject;
  checked: boolean;
}

const ListBillableRate: FC<IBillableList> = ({ project, checked }) => {
  const [onSetClick, setOnSetClick] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string | undefined>('');
  const { expertises } = expertiseStore;

  useEffect(() => {
    expertiseStore.loadExpertises(project.id);
  }, []);

  const dollarChange = useCallback((item: IRate) => {
    return (Number(item.rate) / item.exchangeRate).toFixed(2);
  }, []);

  const handleOnClick = (id: string | undefined) => {
    setCurrentId(id);
    setOnSetClick((prev) => !prev);
  };

  const onExpertisesUpdate = () => {
    expertiseStore.loadExpertises(project.id);
  };

  return (
    <List className={classes.list}>
      {expertises?.map((item) => (
        <ListItem key={item.id} component="div" disableGutters>
          <Grid container spacing={1} className={classes.gridBox}>
            <Grid item xs={3}>
              <Chip
                label={item.expertise}
                className={classes.chipColor}
                variant="filled"
              />
            </Grid>

            {checked ? (
              <Grid item xs={2}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                    classes: {
                      root: classes.font
                    },
                    disableUnderline: true
                  }}
                  variant="standard"
                  value={dollarChange(item)}
                  disabled
                />
              </Grid>
            ) : null}

            <Grid item xs={2}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyRubleIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.font
                  },
                  disableUnderline: true
                }}
                disabled
                value={item.rate}
                variant="standard"
              />
            </Grid>

            {checked ? (
              <Grid item xs={2}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyExchangeIcon />
                      </InputAdornment>
                    ),
                    classes: {
                      root: classes.font
                    },
                    disableUnderline: true
                  }}
                  variant="standard"
                  value={item.exchangeRate}
                  disabled
                />
              </Grid>
            ) : null}
            <Grid item xs={2}>
              <Button
                className={classes.editButton}
                onClick={() => handleOnClick(item.id)}
              >
                EDIT
              </Button>
            </Grid>
          </Grid>
        </ListItem>
      ))}
      <SetNewBillableRate
        item={expertises.find((exp) => exp.id === currentId)}
        project={project}
        isOpen={onSetClick}
        setOpen={setOnSetClick}
        checked={checked}
        onExpertisesUpdate={onExpertisesUpdate}
      />
    </List>
  );
};
export default observer(ListBillableRate);
