import React, { FC, useCallback, useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { endOfMonth, startOfMonth } from 'date-fns';

// Types
import { ButtonType } from 'components/ButtonView/ButtonView';

// Icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Components
import TabsView from 'components/Tabs/TabsView';
import ButtonView from 'components/ButtonView';
import DateRangePicker from 'components/DateRangePicker';
import projectsStore from 'stores/ProjectsStore';
import ClockifyTableTimesheets from './ClockifyTableTimesheets/ClockifyTableTimesheets';
import TableTimesheets from './TableTimesheets/TableTimesheets';

// Stores

// Styles
import classes from './Timesheets.module.scss';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const Timesheets: FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [firstDate, setFirstDate] = useState<Date>(startOfMonth(new Date()));
  const [secondDate, setSecondDate] = useState<Date>(endOfMonth(new Date()));
  const [rangeType, setRangeType] = useState<string>('ThisMonth');
  const [dateRange, setDateRange] = useState<DateRange>({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date())
  });

  useEffect(() => {
    projectsStore.loadProjects();
  }, []);

  const getDateRange = useCallback(() => {
    const firstYear =
      firstDate.getFullYear() === secondDate.getFullYear()
        ? ''
        : ' '.concat(firstDate.getFullYear().toString());

    return firstDate
      .getDate()
      .toString()
      .concat(
        ' ',
        months[firstDate.getMonth()],
        firstYear,
        ' - ',
        secondDate.getDate().toString(),
        ' ',
        months[secondDate.getMonth()],
        ' ',
        secondDate.getFullYear().toString()
      );
  }, [dateRange]);

  const calendarStateChange = useCallback(() => {
    setIsCalendarOpen((val) => !val);
  }, []);

  const changeMonth = useCallback(
    (diff: number) => {
      const newFirstDate = new Date(
        firstDate.getFullYear(),
        firstDate.getMonth() + diff,
        1,
        0,
        0,
        0,
        0
      );
      const newSecondDate = new Date(
        secondDate.getFullYear(),
        secondDate.getMonth() + diff + 1,
        0,
        23,
        59,
        59,
        999
      );
      setDateRange({
        from: startOfMonth(newFirstDate),
        to: endOfMonth(newSecondDate)
      });
      setFirstDate(newFirstDate);
      setSecondDate(newSecondDate);
    },
    [dateRange]
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.upperLevel}>
        <h1 className={classes.pageHeader}>Timesheets</h1>
        <ButtonView
          label="Generate report"
          isButtonClick={false}
          setIsButtonClick={() => {}}
          type={ButtonType.Reports}
        />
      </div>
      <div className={classes.navigate}>
        <TabsView
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={['Time Tracker', 'Clockify']}
        />
        <div className={classes.calendarButtons}>
          <button
            type="button"
            className={classes.calendarRange}
            onClick={calendarStateChange}
          >
            <CalendarMonthIcon className={classes.calendarIcons} />
            {getDateRange()}
            <ArrowDropDownIcon className={classes.calendarIcons} />
          </button>
          <DateRangePicker
            isOpen={isCalendarOpen}
            onClose={setIsCalendarOpen}
            firstDate={firstDate}
            onFirstDateChange={setFirstDate}
            secondDate={secondDate}
            onSecondDateChange={setSecondDate}
            rangeType={rangeType}
            onRangeTypeChange={setRangeType}
            datesRange={dateRange}
            onDatesRangeChange={setDateRange}
          />
        </div>
        <div className={classes.calendarButtons}>
          <button
            type="button"
            className={classes.buttonItem}
            onClick={() => changeMonth(-1)}
          >
            <KeyboardArrowLeftIcon />
          </button>
          <button
            type="button"
            className={classes.buttonItem}
            onClick={() => changeMonth(1)}
          >
            <KeyboardArrowRightIcon />
          </button>
        </div>
      </div>
      <div className={classes.tableView}>
        {activeTab === 0 ? (
          <TableTimesheets dateRange={dateRange} />
        ) : (
          <ClockifyTableTimesheets dateRange={dateRange} />
        )}
      </div>
    </div>
  );
};

export default Timesheets;
