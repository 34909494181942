import { action, autorun, makeObservable, observable, runInAction } from 'mobx';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

// API
import {
  addRecord,
  patchRecords,
  addTask,
  patchTask,
  deleteTask,
  getRecords,
  getTasks,
  deleteRecords,
  duplicateRecord
} from 'api/timetracker';

// Types
import {
  IProjectWithTasks,
  ITimeTrackerRecord,
  ITask
} from 'types/timetrackerRecord';

// Stores
import alertsStore from 'stores/alertsStore';

class TimeTrackerStore {
  @observable
  records: ITimeTrackerRecord[] = [];

  @observable
  projectTasks: IProjectWithTasks[] = [];

  @observable
  loading: boolean = false;

  @observable
  currentRecord: ITimeTrackerRecord = {
    id: uuid(),
    title: '',
    description: '',
    startedAt: moment().startOf('minute').toDate(),
    endedAt: moment().startOf('minute').toDate(),
    totalTime: 0,
    billable: false
  };

  @observable
  taskInModal: ITask = {
    taskId: '',
    title: ''
  };

  recordInterval: NodeJS.Timeout | null = null;

  @observable
  isRunning: boolean = false;

  constructor() {
    makeObservable(this);

    autorun(() => {
      this.currentRecord.totalTime = moment(this.currentRecord.endedAt).diff(
        this.currentRecord.startedAt,
        'seconds'
      );
    });
  }

  getExpertiseIdFromTasks = (
    expertiseName: string,
    projectId: string
  ): string | undefined => {
    const index = this.projectTasks.findIndex(
      (project) => project.project.id === projectId
    );

    const expertiseIndex = this.projectTasks[index].expertises.findIndex(
      (expertise) => expertise.expertiseName === expertiseName
    );

    return this.projectTasks[index].expertises[expertiseIndex].expertiseId;
  };

  @action
  resetCurrentRecord = () => {
    this.currentRecord = {
      id: uuid(),
      title: '',
      description: '',
      startedAt: moment().startOf('minute').toDate(),
      endedAt: moment().startOf('minute').toDate(),
      totalTime: 0,
      billable: false
    };
  };

  @action
  runRecord = (recordToRun: ITimeTrackerRecord = this.currentRecord) => {
    if (this.isRunning) {
      this.stopRecord();
    }

    this.isRunning = true;

    this.currentRecord = {
      ...recordToRun,
      startedAt: moment().toDate(),
      endedAt: moment().toDate()
    };

    if (!this.currentRecord.title) {
      this.currentRecord.title = this.currentRecord.expertise;
      this.currentRecord.expertise = this.getExpertiseIdFromTasks(
        this.currentRecord.expertise as string,
        this.currentRecord.project?.id as string
      );
    }

    this.recordInterval = setInterval(() => {
      this.currentRecord.endedAt = moment(this.currentRecord.endedAt)
        .add(1, 'second')
        .toDate();
    }, 1000);
  };

  @action
  stopRecord = () => {
    this.isRunning = false;

    if (this.recordInterval) {
      clearInterval(this.recordInterval);
    }

    this.currentRecord = {
      ...this.currentRecord,
      endedAt: moment().toDate()
    };

    this.addTimetrackerRecord(
      this.currentRecord.project?.id as string,
      this.currentRecord.title as string,
      this.currentRecord.expertise as string,
      this.currentRecord.description,
      this.currentRecord.billable,
      this.currentRecord.startedAt,
      this.currentRecord.endedAt
    );

    this.resetCurrentRecord();
  };

  @action
  cancelRecord = () => {
    this.isRunning = false;

    if (this.recordInterval) {
      clearInterval(this.recordInterval);
    }

    this.resetCurrentRecord();
  };

  @action
  toggleRecordRun = () => {
    if (this.isRunning) {
      this.stopRecord();
    } else {
      this.runRecord();
    }
  };

  @action
  addManualRecord = () => {
    this.addTimetrackerRecord(
      this.currentRecord.project?.id as string,
      this.currentRecord.title as string,
      this.currentRecord.expertise as string,
      this.currentRecord.description,
      this.currentRecord.billable,
      this.currentRecord.startedAt,
      this.currentRecord.endedAt
    );

    this.resetCurrentRecord();
  };

  @action
  deleteRecord = async (ids: string[]) => {
    await deleteRecords(ids).then(() => {
      ids.forEach((id) => {
        const index = this.records.findIndex((record) => record.id === id);

        if (index > -1) {
          runInAction(() => {
            this.records.splice(index, 1);
          });
        }
      });

      alertsStore.createAlert(
        'success',
        ids.length > 1 ? `Deleted ${ids.length} records` : 'Record deleted'
      );
    });
  };

  @action
  duplicateRecord = async (id: string) => {
    await duplicateRecord(id).then((response) => {
      const index = this.records.findIndex((record) => id === record.id);

      if (index > -1) {
        this.records.splice(index + 1, 0, {
          ...this.records[index],
          id: response.id
        });
      }

      alertsStore.createAlert('success', 'Record duplicated');
    });
  };

  @action
  patchRecords = async (ids: string[], record: ITimeTrackerRecord) => {
    let expertiseId;

    if (record.expertise) {
      expertiseId = this.getExpertiseIdFromTasks(
        record.expertise,
        record.project?.id as string
      );

      record.billable = true;
    } else {
      record.billable = false;
    }

    await patchRecords(ids, {
      projectId: record.project?.id,
      title: record.title || null,
      expertiseId: expertiseId || null,
      description: record.description,
      billable: !!expertiseId,
      startedAt: record.startedAt,
      endedAt: record.endedAt as Date
    }).then(() => {
      ids.forEach((id) => {
        const index = this.records.findIndex((rec) => rec.id === id);

        if (index > -1) {
          this.records[index] = {
            ...record,
            id,
            totalTime: moment(record.endedAt).diff(record.startedAt, 'second')
          };
        }
      });

      this.sortRecordsByDate();

      alertsStore.createAlert(
        'success',
        ids.length > 1 ? `Updated ${ids.length} records` : 'Record updated'
      );
    });
  };

  @action
  changeCurrentRecordDescription = (description: string) => {
    this.currentRecord.description = description;
  };

  @action
  changeCurrentRecordTitle = (title: string) => {
    this.currentRecord.title = title;
  };

  @action
  changeCurrentRecordBillable = (billable: boolean) => {
    this.currentRecord.billable = billable;
  };

  @action
  changeCurrentRecordProject = (project: IProjectWithTasks) => {
    this.currentRecord.project = {
      ...project.project
    };
  };

  @action
  changeCurrentRecordExpertise = (expertiseId: string) => {
    this.currentRecord.expertise = expertiseId;
  };

  @action
  changeCurrentRecordStartedAt = (date: Date) => {
    this.currentRecord.startedAt = date;
  };

  @action
  changeCurrentRecordEndedAt = (date: Date) => {
    this.currentRecord.endedAt = date;
  };

  @action
  addTimetrackerRecord = async (
    projectId: string,
    title: string | null,
    expertiseId: string | null,
    description: string,
    billable: boolean,
    startedAt: Date,
    endedAt?: Date
  ): Promise<void> => {
    try {
      this.loading = true;

      if (expertiseId) {
        title = null;
      } else {
        expertiseId = null;
      }

      const timeStarted = moment(startedAt).format('HH:mm');
      const timeEnded = moment(endedAt).format('HH:mm');

      if (timeStarted > timeEnded) {
        await addRecord(
          projectId,
          title,
          expertiseId,
          description,
          billable,
          startedAt,
          moment(endedAt).endOf('day').toDate()
        );

        const newEndedAt = moment(endedAt);

        if (moment(endedAt).isSame(startedAt, 'day')) {
          newEndedAt.add(1, 'day');
        }

        await addRecord(
          projectId,
          title,
          expertiseId,
          description,
          billable,
          moment(startedAt).startOf('day').add(1, 'day').toDate(),
          newEndedAt.toDate()
        );
      } else {
        await addRecord(
          projectId,
          title,
          expertiseId,
          description,
          billable,
          startedAt,
          endedAt
        );
      }

      await this.getRecords();
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  getRecords = async () => {
    try {
      this.loading = true;

      const data = await getRecords();

      runInAction(() => {
        this.records = data;
      });

      this.sortRecordsByDate();
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  getProjectTasks = async () => {
    try {
      this.loading = true;

      const data = await getTasks();

      runInAction(() => {
        this.projectTasks = data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  sortRecordsByDate = () => {
    this.records.sort(
      (a, b) =>
        new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime()
    );
  };

  @action
  addTask = async (projectId: string, taskTitle: string) => {
    await addTask(projectId, taskTitle).then((response) => {
      const index = this.projectTasks.findIndex(
        (projWithTask) => projWithTask.project.id === response.projectId
      );
      runInAction(() => {
        this.projectTasks[index].tasks.push({
          taskId: response.id,
          title: response.title
        });
        this.changeCurrentRecordExpertise('');
        this.changeCurrentRecordTitle(response.title);
      });

      alertsStore.createAlert('success', 'Task added');
    });
  };

  @action
  patchTask = async (id: string, projectId: string, title: string) => {
    await patchTask(id, title).then(() => {
      const projectIndex = this.projectTasks.findIndex(
        (projWithTask) => projWithTask.project.id === projectId
      );
      const taskIndex = this.projectTasks[projectIndex].tasks.findIndex(
        (task) => task.taskId === id
      );
      runInAction(() => {
        const prevTitle =
          this.projectTasks[projectIndex].tasks[taskIndex].title;

        this.projectTasks[projectIndex].tasks[taskIndex] = {
          taskId: id,
          title
        };
        this.changeCurrentRecordTitle(title);

        this.records.forEach((record, index) => {
          if (record.title === prevTitle) {
            this.records[index].title = title;
          }
        });
      });

      alertsStore.createAlert('success', 'Task edited');
    });
  };

  @action
  deleteTask = async (id: string) => {
    await deleteTask(id).then((res) => {
      if (res === 'fail, task has records ') {
        alertsStore.createAlert(
          'error',
          'Cannot delete task: this task has records'
        );

        return;
      }

      this.projectTasks.forEach((projectInTask, index) => {
        const taskIndex = projectInTask.tasks.findIndex(
          (task) => task.taskId === id
        );

        if (taskIndex > -1) {
          this.projectTasks[index].tasks.splice(taskIndex, 1);
        }
      });

      alertsStore.createAlert('success', 'Task deleted');
    });
  };

  @action
  changeCurrentDay = (day: Date) => {
    const startHour = moment(this.currentRecord.startedAt).get('hours');
    const startMinute = moment(this.currentRecord.startedAt).get('minutes');
    const endHour = moment(this.currentRecord.endedAt).get('hours');
    const endMinute = moment(this.currentRecord.endedAt).get('minutes');

    this.currentRecord.startedAt = moment(day)
      .set({ hour: startHour, minute: startMinute })
      .toDate();

    this.currentRecord.endedAt = moment(day)
      .set({ hour: endHour, minute: endMinute })
      .toDate();
  };

  @action
  changeTaskInModal = (task: string | ITask) => {
    if (typeof task === 'string') {
      this.taskInModal = {
        ...this.taskInModal,
        title: task
      };
    } else {
      this.taskInModal = task;
    }
  };
}

const timeTrackerStore = new TimeTrackerStore();
export default timeTrackerStore;
