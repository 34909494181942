import { ITimeTrackerRecord } from 'types/timetrackerRecord';
import moment from 'moment';

interface DividedRecords {
  [key: string]: ITimeTrackerRecord[];
}

enum DivideCategories {
  THIS_WEEK = 'This Week',
  LAST_WEEK = 'Last Week',
  THIS_MONTH = 'This Month',
  THIS_YEAR = 'This Year',
  OLDER = 'Older'
}

export const divideRecordsByWeeks = (
  records: ITimeTrackerRecord[]
): DividedRecords => {
  const dividedRecords: DividedRecords = {
    [DivideCategories.THIS_WEEK]: [],
    [DivideCategories.LAST_WEEK]: [],
    [DivideCategories.THIS_MONTH]: [],
    [DivideCategories.THIS_YEAR]: [],
    [DivideCategories.OLDER]: []
  };

  const today = moment().startOf('day');
  const startOfThisWeek = today.clone().startOf('isoWeek');
  const startOfLastWeek = startOfThisWeek.clone().subtract(1, 'week');
  const startOfLastMonth = today.clone().subtract(1, 'month').startOf('month');
  const startOfLastYear = today.clone().subtract(1, 'year').startOf('year');

  records.forEach((record) => {
    const momentDate = moment(record.startedAt);

    if (momentDate.isSameOrAfter(startOfThisWeek)) {
      dividedRecords[DivideCategories.THIS_WEEK].push(record);

      return;
    }

    if (momentDate.isAfter(startOfLastWeek)) {
      dividedRecords[DivideCategories.LAST_WEEK].push(record);

      return;
    }

    if (momentDate.isAfter(startOfLastMonth)) {
      dividedRecords[DivideCategories.THIS_MONTH].push(record);

      return;
    }

    if (momentDate.isAfter(startOfLastYear)) {
      dividedRecords[DivideCategories.THIS_YEAR].push(record);

      return;
    }

    dividedRecords[DivideCategories.OLDER].push(record);
  });

  return dividedRecords;
};

export const groupRecordsByDays = (
  records: ITimeTrackerRecord[]
): DividedRecords => {
  const groupedRecords: DividedRecords = {};

  records.forEach((record) => {
    const formattedDate = moment(record.startedAt).format('MMM DD');

    if (groupedRecords[formattedDate]) {
      groupedRecords[formattedDate].push(record);
    } else {
      groupedRecords[formattedDate] = [record];
    }
  });

  return groupedRecords;
};

export const formatTime = (
  time: number | Date,
  format: string = 'HH:mm:ss'
): string => {
  if (typeof time === 'number') {
    return moment.utc(time * 1000).format(format);
  }

  return moment(time).format(format);
};
