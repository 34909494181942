import { ReactComponent as Team } from 'assets/icons/team.svg';
import { ReactComponent as ActiveTeam } from 'assets/icons/activeTeam.svg';
import { ReactComponent as Planner } from 'assets/icons/planner.svg';
import { ReactComponent as ActivePlanner } from 'assets/icons/activePlanner.svg';
import { ReactComponent as Clients } from 'assets/icons/clients.svg';
import { ReactComponent as ActiveClients } from 'assets/icons/activeClients.svg';
import { ReactComponent as Reports } from 'assets/icons/reports.svg';
import { ReactComponent as profitForecasted } from 'assets/icons/profitForecasted.svg';
import { ReactComponent as profitForecastedActive } from 'assets/icons/profitForecastedActive.svg';
import { ReactComponent as profitGenerated } from 'assets/icons/profitGenerated.svg';
import { ReactComponent as profitGeneratedActive } from 'assets/icons/profitGeneratedActive.svg';
import { ReactComponent as plannedFactProfit } from 'assets/icons/plannedFactProfit.svg';
import { ReactComponent as plannedFactProfitActive } from 'assets/icons/plannedFactProfitActive.svg';
import { ReactComponent as plannedFactHours } from 'assets/icons/plannedFactHours.svg';
import { ReactComponent as plannedFactHoursActive } from 'assets/icons/plannedFactHoursActive.svg';
import { ReactComponent as ActiveReports } from 'assets/icons/activeReports.svg';
import { ReactComponent as Settings } from 'assets/icons/tools.svg';
import { ReactComponent as ActiveSettings } from 'assets/icons/ActiveSetting.svg';
import { ReactComponent as Projects } from 'assets/icons/projects.svg';
import { ReactComponent as ActiveProjects } from 'assets/icons/activeProjects.svg';
import { ReactComponent as TimeTracker } from 'assets/icons/timetracker.svg';
import { ReactComponent as ActiveTimeTracker } from 'assets/icons/activeTimetracker.svg';
import { ReactComponent as Timesheets } from 'assets/icons/timesheets.svg';
import { ReactComponent as ActiveTimesheets } from 'assets/icons/timesheets-active.svg';

export const sidebarList = [
  {
    icon: Planner,
    activeIcon: ActivePlanner,
    desc: 'Planner',
    page: '',
    id: 12,
    badge: 0,
    forAdmins: false,
    forManagers: false
  },
  {
    icon: TimeTracker,
    activeIcon: ActiveTimeTracker,
    desc: 'Time Tracker',
    page: 'timetracker',
    id: 14,
    badge: 0,
    forAdmins: false,
    forManagers: false
  },
  {
    icon: Timesheets,
    activeIcon: ActiveTimesheets,
    desc: 'Timesheets',
    page: 'sheets',
    id: 15,
    badge: 0,
    forAdmins: false,
    forManagers: true
  },
  {
    icon: Reports,
    activeIcon: ActiveReports,
    desc: 'Reports',
    page: 'reports',
    id: 13,
    badge: 0,
    forAdmins: false,
    forManagers: true,
    subpages: [
      {
        icon: profitForecasted,
        activeIcon: profitForecastedActive,
        desc: 'Profit forecasted',
        page: 'reports/profit_forecasted',
        id: 14,
        badge: 0
      },
      {
        icon: profitGenerated,
        activeIcon: profitGeneratedActive,
        desc: 'Profit generated',
        page: 'reports/profit_generated',
        id: 15,
        badge: 0
      },
      {
        icon: plannedFactProfit,
        activeIcon: plannedFactProfitActive,
        desc: 'Planned vs fact profit',
        page: 'reports/planned_fact_profit',
        id: 16,
        badge: 0
      },
      {
        icon: plannedFactHours,
        activeIcon: plannedFactHoursActive,
        desc: 'Planned vs fact hours',
        page: 'reports/planned_fact_hours',
        id: 17,
        badge: 0
      }
    ]
  }
];
export const subListManagement = [
  {
    icon: Projects,
    activeIcon: ActiveProjects,
    desc: 'Projects',
    page: 'projects',
    id: 21,
    badge: 0
  },
  {
    icon: Team,
    activeIcon: ActiveTeam,
    desc: 'Team',
    page: 'team',
    id: 22,
    badge: 0
  },
  {
    icon: Clients,
    activeIcon: ActiveClients,
    desc: 'Clients',
    page: 'clients',
    id: 23,
    badge: 0
  }
];

export const subListOwnerOnly = [
  {
    icon: Settings,
    activeIcon: ActiveSettings,
    desc: 'Settings',
    page: 'settings',
    id: 3,
    badge: 0
  }
];
