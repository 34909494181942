import React, { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import BlockWrapper from 'components/BlockWrapper';
import WeekDayCheckbox from 'components/WeekDayCheckbox';

// Types
import {
  WorkspaceBlockProps,
  WorkspaceSettingsNames as Names
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import { IWorkspaceSettingsPatch } from 'types/workspaceSettings';
import { WeekDay } from 'types/user';

// Styles
import classes from './WorkDays.module.scss';

const weekList: string[] = Object.values(WeekDay);

const WorkDays: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, setFieldValue } = useFormikContext<IWorkspaceSettingsPatch>();
  const [workDaysBoolState, setWorkDaysBoolState] = useState<boolean[] | null>(
    null
  );

  useEffect(() => {
    const workDaysBool: boolean[] = [];
    weekList.forEach((value, key) => {
      workDaysBool.push(values.workDays?.includes(key) as boolean);
    });
    setWorkDaysBoolState(workDaysBool);
  }, [values.workDays]);

  const weekDaysChangeHandler = (index: number) => () => {
    if (workDaysBoolState) {
      const newBoolState = [...workDaysBoolState];
      newBoolState[index] = !workDaysBoolState[index];

      const newWorkDays: number[] = [];
      weekList.forEach((day, i) => {
        if (newBoolState[i] === true) {
          newWorkDays.push(i);
        }
      });
      setFieldValue(Names.workDays, newWorkDays);
      onChange();
    }
  };

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Work time settings</span>
            <span className={classes.description}>
              Change time zone, when your week starts, and your preferred data
              and time format.
            </span>
          </Box>
          <Box className={classes.FieldsContainer}>
            <Box className={classes.column}>
              <Box className={classes.workDaysContainer}>
                <Box className={classes.workDays}>
                  {workDaysBoolState?.map((dayBool, index) => (
                    <WeekDayCheckbox
                      day={index}
                      checked={workDaysBoolState}
                      onChange={weekDaysChangeHandler(index)}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default WorkDays;
