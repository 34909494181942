import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

// Types
import { User, IUserPatch } from 'types/user';

// Api
import { getProfile } from 'api/auth';
import { updatePersonalInfo } from 'api/profileSettings';

class ProfileStore {
  @observable
  profile: User | null = null;

  @observable
  loading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  setProfile = (profile: User | null) => {
    this.profile = profile;
  };

  @action
  getProfile = async () => {
    const { data } = await getProfile();
    runInAction(() => {
      this.profile = data.data;
    });
  };

  @action
  logout = () => {
    runInAction(() => {
      this.profile = null;
    });
  };

  @computed
  get role(): string {
    return this.profile ? this.profile.role : 'Null';
  }

  @action
  updateProfile = async (userPatch: IUserPatch) => {
    try {
      this.loading = true;

      const data = await updatePersonalInfo(userPatch);

      runInAction(() => {
        this.profile = data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}

const profileStore = new ProfileStore();

export default profileStore;
