import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import Badge from '@mui/material/Badge';
import Toolbar from '@mui/material/Toolbar';
import PaidIcon from '@mui/icons-material/Paid';
import ButtonBase from '@mui/material/ButtonBase';
import useAutocomplete from '@mui/material/useAutocomplete';
import Box from '@mui/material/Box';

// Icons
import MailIcon from 'assets/icons/envelope-fill.svg';

// Components
import SearchBase from 'components/SearchBase';
import AvatarMenu from 'components/Header/AvatarMenu';
import MemberAvatar from 'components/MemberAvatar';

// Types
import { UserRole } from 'types/user';

// Stores
import rateStore from 'stores/RateStore';
import searchStore from 'stores/SearchStore';
import profileStore from 'stores/ProfileStore';

// Styles
import classes from './Header.module.scss';

const Header: FC = () => {
  const { query, selected, options, getOptionLabel } = searchStore;
  const { profile } = profileStore;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const profileRole = profileStore.role;

  const { getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete({
      open: isSearchOpen && query.length > 0,
      value: selected,
      onChange: (event, value) => searchStore.setSelectedOption(value),
      inputValue: query,
      onInputChange: (event, value) => searchStore.setQuery(value),
      options,
      getOptionLabel
    });

  const handleClick = useCallback(() => {
    setIsOpen((cIsOpen) => !cIsOpen);
  }, []);

  useEffect(() => {
    rateStore.getDollarRate();
  }, []);

  return (
    <div>
      <Toolbar className={classes.header}>
        <div className={classes.headerSection}>
          <SearchBase
            getInputProps={getInputProps}
            onSearchToggle={setSearchOpen}
          />
        </div>
        <div className={classes.headerSection}>
          {profileRole !== UserRole.USER ? (
            <div className={classes.headerItem}>
              <div className={classes.rate}>
                <PaidIcon className={classes.iconPaid} />
                <div>{Math.round(rateStore.rate * 100) / 100}</div>
              </div>
            </div>
          ) : null}
          <Badge className={classes.headerItem}>
            <img src={MailIcon} className={classes.icon} alt="mailIcon" />
          </Badge>
          <div
            onClick={handleClick}
            className={clsx(classes.headerItem, classes.avatar)}
          >
            <MemberAvatar
              fullName={profile?.name}
              userRole={profile?.role}
              color={profile?.colour}
            />
          </div>
        </div>
      </Toolbar>
      <AvatarMenu
        avatar={
          <MemberAvatar
            fullName={profile?.name}
            userRole={profile?.role}
            color={profile?.colour}
          />
        }
        fullName={profile?.name}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {isSearchOpen && groupedOptions.length > 0 ? (
        <Box onClick={() => setSearchOpen(!isSearchOpen)}>
          <ul {...getListboxProps()} className={classes.searchListbox}>
            {(groupedOptions as { title: string }[]).map((option, index) => (
              <ButtonBase
                component="li"
                {...getOptionProps({ option, index })}
                disableRipple
                className={classes.searchOption}
              >
                {option.title !== undefined
                  ? option.title
                  : getOptionLabel?.(option) ?? null}
              </ButtonBase>
            ))}
          </ul>
        </Box>
      ) : null}
    </div>
  );
};
export default observer(Header);
