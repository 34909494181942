import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

// Types
import { IProject, IProjectPatch, PlannerStatus } from 'types/project';
import { ITask, ITaskPatch } from 'types/task';
import { IRate } from 'types/rate';

// API
import {
  addProject,
  addProjectTask,
  getProjectExpertises,
  getProjects,
  refreshProject,
  updateTask
} from 'api/projects';

// Utils
import { sortingByField } from 'utils/sorting';

type AvailableSort =
  | 'name'
  | 'startAt'
  | 'endAt'
  | 'manager'
  | 'contractStatus';

class ProjectsStore {
  @observable
  projects: IProject[] = [];

  @observable
  expertises: IRate[] = [];

  @observable
  addedProject?: IProject;

  @observable
  addedTask?: ITask;

  @observable
  updatedProject?: IProject;

  @observable
  updatedTask?: ITask;

  @observable
  loading: boolean = false;

  @observable
  query: string = '';

  @observable
  sort: AvailableSort = 'name';

  @observable
  sortDir: 1 | -1 = 1;

  constructor() {
    makeObservable(this);
  }

  @action
  setQuery = (query: string): void => {
    this.query = query;
  };

  @action
  setSort = (newSort: AvailableSort): void => {
    this.sortDir = (this.sort === newSort ? -this.sortDir : 1) as 1 | -1;
    this.sort = newSort;
  };

  @computed
  get filteredProjects(): IProject[] | null {
    if (!this.projects) {
      return null;
    }

    return sortingByField(
      this.projects.filter(
        (project) =>
          project.name.toLowerCase().includes(this.query.toLowerCase()) &&
          project.plannerStatus !== PlannerStatus.paid &&
          project.plannerStatus !== PlannerStatus.unpaid &&
          project.plannerStatus !== PlannerStatus.sick
      ),
      this.sort as keyof IProject,
      this.sortDir
    );
  }

  @action
  addProject = async (
    name: string,
    clientId: string,
    colour: string
  ): Promise<void> => {
    try {
      this.loading = true;

      const { data } = await addProject(name, clientId, colour);

      runInAction(() => {
        this.addedProject = data.data;
        this.projects?.push(data.data);
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  loadProjects = async () => {
    try {
      this.loading = true;

      const data = await getProjects();

      runInAction(() => {
        this.projects = data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  updateProjects = async (id: string, request: IProjectPatch) => {
    try {
      this.loading = true;

      const data = await refreshProject(id, request);

      runInAction(() => {
        this.updatedProject = data;
        const index = this.projects.findIndex((x) => x.id === id);

        if (this.projects) {
          this.projects.splice(index, 1, data);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  getAvailableExpertises = async (id: string) => {
    try {
      this.loading = true;
      const { data } = await getProjectExpertises(id);

      runInAction(() => {
        this.expertises = data.data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  addProjectTask = async (
    name: string,
    projectId: string,
    assigned: number,
    startAt: Date,
    endAt: Date
  ): Promise<void> => {
    try {
      this.loading = true;

      const { data } = await addProjectTask(
        projectId,
        name,
        assigned,
        startAt,
        endAt
      );

      runInAction(() => {
        this.addedTask = data.data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  updateTask = async (taskId: string, request: ITaskPatch): Promise<void> => {
    try {
      this.loading = true;

      const data = await updateTask(taskId, request);

      runInAction(() => {
        this.updatedTask = data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}

const projectsStore = new ProjectsStore();

export default projectsStore;
