import React, { FC, useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useBlocker } from 'routes/WorkspaceSettings/Hooks/useBlocker';

// Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import workspaceSettingsStore from 'stores/WorkspaceSettingsStore';
import SuggestionModal from 'components/modals/SuggestionModal';
import { IWorkspaceSettingsPatch } from 'types/workspaceSettings';
import CompanyInfo from './ListSettings/CompanyInfo/CompanyInfo';
import NewProjects from './ListSettings/NewProjects';
import ProjectFavorite from './ListSettings/ProjectFavorite';
import BillableHours from './ListSettings/BillableHours';
import CreateProjects from './ListSettings/CreateProjects';
import CreateTasks from './ListSettings/CreateTasks';
import HidePages from './ListSettings/HidePages';
import LockTime from './ListSettings/LockTime';
import ForceTimer from './ListSettings/ForceTimer';
import ClockifySettings from './ListSettings/ClockifySettings';
import WorkDays from './ListSettings/WorkingDays';
import DurationFormat from './ListSettings/DurationFormat';
import Currency from './ListSettings/Currency';
import DataFormat from './ListSettings/DataFormat';
import RequiredForTask from './ListSettings/RequiredForTask';
import TimeRounding from './ListSettings/TimeRounding';
import RegularUserSee from './ListSettings/RegularUserSee';
import EditForOthers from './ListSettings/EditForOthers';

// Styles
import classes from './WorkspaceSettings.module.scss';

// Types
import { WorkspaceSettingsNames as Names } from './WorkspaceSettingsTypes';

const WorkspaceSettings: FC = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [isSuggestToSave, setIsSuggestToSave] = useState(false);
  const { workspaceSettings } = workspaceSettingsStore;

  useEffect(() => {
    workspaceSettingsStore.getWorkspaceSettings();
  }, []);

  const blocker = useCallback(() => {
    setIsSuggestToSave(true);
  }, []);
  useBlocker(blocker, isEdit);

  const changeHandler = () => {
    setIsEdit(true);
  };

  const cancelHandler = () => {
    setIsEdit(false);
  };

  useEffect(() => {}, [isEdit]);

  const initialValues: IWorkspaceSettingsPatch = {
    [Names.companyName]: workspaceSettings?.companyName,
    [Names.companyLogo]: workspaceSettings?.companyLogo,
    [Names.workDays]: workspaceSettings?.workDays,
    [Names.workspaceClockifyId]: workspaceSettings?.workspaceClockifyId,
    [Names.clockifyApiKey]: undefined,
    [Names.defaultPrivacy]: workspaceSettings?.defaultPrivacy,
    [Names.defaultBillability]: workspaceSettings?.defaultBillability,
    [Names.durationFormat]: workspaceSettings?.durationFormat,
    [Names.isActivateBillableHours]: workspaceSettings?.isActivateBillableHours,
    [Names.isActivateFavorites]: workspaceSettings?.isActivateFavorites,
    [Names.currency]: workspaceSettings?.currency,
    [Names.numberFormat]: workspaceSettings?.numberFormat,
    [Names.currencyFormat]: workspaceSettings?.currencyFormat,
    [Names.requiredForTask]: workspaceSettings?.requiredForTask,
    [Names.timeRounding]: workspaceSettings?.timeRounding,
    [Names.canSeeHourlyRates]: workspaceSettings?.canSeeHourlyRates,
    [Names.canChangeBillable]: workspaceSettings?.canChangeBillable,
    [Names.canCreateProjectsClients]:
      workspaceSettings?.canCreateProjectsClients,
    [Names.canCreateTasks]: workspaceSettings?.canCreateTasks,
    [Names.timeVisibility]: workspaceSettings?.timeVisibility,
    [Names.hidedPages]: workspaceSettings?.hidedPages,
    [Names.canEditElsesTime]: workspaceSettings?.canEditElsesTime,
    [Names.isLockTime]: workspaceSettings?.isLockTime,
    [Names.lockTimeBefore]: workspaceSettings?.lockTimeBefore,
    [Names.isLockTimeAutoUpdate]: workspaceSettings?.isLockTimeAutoUpdate,
    [Names.isForceTimer]: workspaceSettings?.isForceTimer
  };

  const submitHandler = (values: IWorkspaceSettingsPatch) => {
    workspaceSettingsStore.updateWorkspaceSettings({
      [Names.companyName]: values.companyName,
      [Names.companyLogo]: values.companyLogo,
      [Names.workDays]: values.workDays,
      [Names.workspaceClockifyId]: values.workspaceClockifyId,
      [Names.clockifyApiKey]: values.clockifyApiKey,
      [Names.defaultPrivacy]: values.defaultPrivacy,
      [Names.defaultBillability]: values.defaultBillability,
      [Names.durationFormat]: values.durationFormat,
      [Names.isActivateBillableHours]: values.isActivateBillableHours,
      [Names.isActivateFavorites]: values.isActivateFavorites,
      [Names.currency]: values.currency,
      [Names.numberFormat]: values.numberFormat,
      [Names.currencyFormat]: values.currencyFormat,
      [Names.requiredForTask]: values.requiredForTask,
      [Names.timeRounding]: values.timeRounding,
      [Names.canSeeHourlyRates]: values.canSeeHourlyRates,
      [Names.canChangeBillable]: values.canChangeBillable,
      [Names.canCreateProjectsClients]: values.canCreateProjectsClients,
      [Names.canCreateTasks]: values.canCreateTasks,
      [Names.timeVisibility]: values.timeVisibility,
      [Names.hidedPages]: values.hidedPages,
      [Names.canEditElsesTime]: values.canEditElsesTime,
      [Names.isLockTime]: values.isLockTime,
      [Names.lockTimeBefore]: values.lockTimeBefore,
      [Names.isLockTimeAutoUpdate]: values.isLockTimeAutoUpdate,
      [Names.isForceTimer]: values.isForceTimer
    });
    setIsEdit(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      {({ handleSubmit, resetForm, values }) => (
        <form onSubmit={handleSubmit}>
          <Box className={classes.wrapper}>
            <Box className={classes.titleContainer}>
              <Box className={classes.title}>Workspace Settings</Box>
            </Box>
            <Box>
              <CompanyInfo onChange={changeHandler} />
            </Box>
            <Box>
              <ClockifySettings onChange={changeHandler} />
            </Box>
            <Box>
              <NewProjects onChange={changeHandler} />
            </Box>
            <Box>
              <DurationFormat onChange={changeHandler} />
            </Box>
            <Box>
              <WorkDays onChange={changeHandler} />
            </Box>
            <Box>
              <ProjectFavorite onChange={changeHandler} />
            </Box>
            <Box>
              <Currency onChange={changeHandler} />
            </Box>
            <Box>
              <DataFormat onChange={changeHandler} />
            </Box>
            <Box>
              <RequiredForTask onChange={changeHandler} />
            </Box>
            <Box>
              <TimeRounding onChange={changeHandler} />
            </Box>
            <Box>
              <BillableHours onChange={changeHandler} />
            </Box>
            <Box>
              <CreateProjects onChange={changeHandler} />
            </Box>
            <Box>
              <CreateTasks onChange={changeHandler} />
            </Box>
            <Box>
              <RegularUserSee onChange={changeHandler} />
            </Box>
            <Box>
              <HidePages onChange={changeHandler} />
            </Box>
            <Box>
              <EditForOthers onChange={changeHandler} />
            </Box>
            <Box>
              <LockTime onChange={changeHandler} />
            </Box>
            <Box>
              <ForceTimer onChange={changeHandler} />
            </Box>
            <Box className={classes.buttonBox}>
              <Button
                onClick={() => {
                  cancelHandler();
                  resetForm();
                }}
                className={isEdit ? classes.editCancel : classes.cancel}
                disabled={!isEdit}
              >
                CANCEL
              </Button>
              <Button
                className={isEdit ? classes.editSave : classes.save}
                type="submit"
                disabled={!isEdit}
              >
                SAVE
              </Button>
            </Box>
          </Box>
          <SuggestionModal
            isOpen={isSuggestToSave}
            setIsOpen={setIsSuggestToSave}
            title="Exit settings"
            text="Save settings before exiting?"
            acceptText="Save"
            declineText="Do not save"
            acceptHandler={() => {
              submitHandler(values);
              // unblock()
            }}
            declineHandler={() => {
              cancelHandler();
              resetForm();
              // unblock()
            }}
          />
        </form>
      )}
    </Formik>
  );
};

export default WorkspaceSettings;
