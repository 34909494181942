import React, { FC } from 'react';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';

// Components
import Profile from 'layouts/ProfileLayout';

// Styles
import classes from './SuggestionModal.module.scss';

interface SuggestionProps {
  isOpen: boolean;
  title: string;
  text: string;
  acceptText: string;
  declineText: string;
  setIsOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  acceptHandler?: () => void;
  declineHandler?: () => void;
}

const SuggestionModal: FC<SuggestionProps> = ({
  isOpen,
  title,
  text,
  acceptText,
  declineText,
  setIsOpen,
  acceptHandler,
  declineHandler
}) => {
  const clickHandler = (action?: () => void) => {
    if (action) {
      action();
    }

    setIsOpen(false);
  };

  return (
    <Profile isOpen={isOpen}>
      <div
        className={clsx({
          [classes.wrapper]: isOpen
        })}
      >
        <div className={classes.titleContainer}>
          <div className={classes.title}>{title}</div>
          <IconButton className={classes.icon} onClick={() => setIsOpen(false)}>
            <Close />
          </IconButton>
        </div>
        <div className={classes.content}>{text}</div>
        <Box className={classes.buttonBox}>
          <Button
            className={classes.cancel}
            onClick={() => clickHandler(declineHandler)}
          >
            {declineText}
          </Button>
          <Button
            className={classes.yes}
            onClick={() => {
              clickHandler(acceptHandler);
            }}
          >
            {acceptText}
          </Button>
        </Box>
      </div>
    </Profile>
  );
};

export default SuggestionModal;
