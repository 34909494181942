import axios from 'api/helpers/axios';

// Types
import { ISalary } from 'types/salary';

interface ISalaryResponse {
  data: ISalary;
}

export const addSalary = async (
  userId: string,
  salary: number,
  startAt: Date,
  coefficients: string[]
) =>
  axios.post<ISalaryResponse>('/salary', {
    userId,
    salary,
    startAt,
    coefficients
  });

export const getSalaryForUser = async (userId: string) =>
  axios.get<ISalaryResponse>(`/salary/user/${userId}`);
