import React, { FC } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import BlockWrapper from 'components/BlockWrapper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Types
import {
  WorkspaceSettingsNames as Names,
  WorkspaceBlockProps
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import {
  CurrencyFormat,
  IWorkspaceSettingsPatch,
  NumberFormat
} from 'types/workspaceSettings';

// Styles
import classes from './DataFormat.module.scss';

const DataFormat: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, handleChange } = useFormikContext<IWorkspaceSettingsPatch>();

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Data format</span>
            <span className={classes.description}>
              Choose preferred number and currency format for the whole
              workspace.
            </span>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.column}>
            <Box className={classes.inputContainer}>
              <Box className={classes.wrapper}>
                <span className={classes.label}>Number format</span>
                <Select
                  name={Names.numberFormat}
                  value={values.numberFormat}
                  variant="standard"
                  className={classes.input}
                  fullWidth
                  onChange={(e) => {
                    handleChange(e);
                    onChange();
                  }}
                >
                  {Object.values(NumberFormat).map((value) => {
                    return <MenuItem value={value}>{value}</MenuItem>;
                  })}
                </Select>
              </Box>
            </Box>

            <Box className={classes.inputContainer}>
              <Box className={classes.wrapper}>
                <span className={classes.label}>Currency format</span>
                <Select
                  name={Names.currencyFormat}
                  value={values.currencyFormat}
                  variant="standard"
                  className={classes.input}
                  fullWidth
                  onChange={(e) => {
                    handleChange(e);
                    onChange();
                  }}
                >
                  {Object.values(CurrencyFormat).map((value) => {
                    return <MenuItem value={value}>{value}</MenuItem>;
                  })}
                </Select>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default DataFormat;
