import axios from 'api/helpers/axios';

// Types
import {
  User,
  IUserPatch,
  IPasswordPatch,
  IPasswordRecoveryPatch
} from '../types/user';
import { LoginResponse } from './auth';

interface IUserResponse {
  data: User;
}

export const updatePersonalInfo = (request: IUserPatch): Promise<User> => {
  return axios.patch<IUserResponse>(`/profile`, request).then((response) => {
    return response.data.data;
  });
};

export const updatePassword = (
  request: IPasswordPatch
): Promise<LoginResponse> => {
  return axios
    .patch<LoginResponse>(`/profile/password`, request)
    .then((response) => {
      return <LoginResponse>{
        data: {
          profile: response.data.data.profile,
          token: response.data.data.token
        }
      };
    });
};

export const recoveryPassword = (
  request: IPasswordRecoveryPatch
): Promise<LoginResponse> => {
  return axios
    .patch<LoginResponse>(`/profile/password`, request)
    .then((response) => {
      return <LoginResponse>{
        data: {
          profile: response.data.data.profile,
          token: response.data.data.token
        }
      };
    });
};
