import React, { FC, useCallback } from 'react';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { ReactComponent as GenerateReportIcon } from 'assets/icons/generateReport.svg';

// Components
import AddClientModal from 'components/modals/AddClientModal';
import AddMember from 'components/modals/AddMemberModal';
import AddProjectModal from 'components/modals/AddProjectModal';
import PlannerPersonModal from 'components/modals/PlannerPersonModal';
import PlannerProjectModal from 'components/modals/PlannerProjectModal';
import GenerateReport from 'components/modals/GenerateReport';

// Types
import { UserRole } from 'types/user';

// Stores
import profileStore from 'stores/ProfileStore';

// Styles
import classes from './ButtonView.module.scss';

export enum ButtonType {
  Projects,
  Team,
  Clients,
  PlannerTeam,
  PlannerProjects,
  Reports
}

interface ButtonProps {
  label: string;
  type?: ButtonType;
  isButtonClick: boolean;
  setIsButtonClick: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const ButtonView: FC<ButtonProps> = ({
  label,
  type,
  isButtonClick,
  setIsButtonClick
}) => {
  const profileRole = profileStore.role;

  const handleOnClick = useCallback((cIsButtonClick: boolean) => {
    setIsButtonClick(!cIsButtonClick);
  }, []);

  const handleClose = useCallback(() => {
    setIsButtonClick(false);
  }, []);

  const getModal = () => {
    switch (type) {
      case ButtonType.Team:
        return <AddMember isOpen={isButtonClick} setOpen={setIsButtonClick} />;
      case ButtonType.Clients:
        return (
          <AddClientModal open={isButtonClick} handleClose={handleClose} />
        );
      case ButtonType.Projects:
        return (
          <AddProjectModal isOpen={isButtonClick} setOpen={setIsButtonClick} />
        );
      case ButtonType.PlannerTeam:
        return (
          <PlannerPersonModal
            isOpen={isButtonClick}
            setOpen={setIsButtonClick}
            title="Add person"
          />
        );
      case ButtonType.PlannerProjects:
        return (
          <PlannerProjectModal
            isOpen={isButtonClick}
            setOpen={setIsButtonClick}
          />
        );
      case ButtonType.Reports:
        return (
          <GenerateReport isOpen={isButtonClick} setOpen={setIsButtonClick} />
        );
      default:
        return null;
    }
  };

  const isDisabled = () => {
    switch (type) {
      case ButtonType.Team:
        return ![
          UserRole.OWNER.toString(),
          UserRole.ADMIN.toString(),
          UserRole.MANAGER.toString()
        ].includes(profileRole);
      case ButtonType.Clients:
        return false;
      case ButtonType.Projects:
        return false;
      case ButtonType.PlannerTeam:
        return false;
      default:
        return false;
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={() => handleOnClick(isButtonClick)}
        className={classes.button}
        disabled={isDisabled()}
      >
        {type === ButtonType.Reports ? (
          <GenerateReportIcon className={classes.icon} />
        ) : (
          <Add className={classes.icon} />
        )}
        <span className={classes.text}>{label}</span>
      </Button>
      {getModal()}
    </div>
  );
};

export default ButtonView;
