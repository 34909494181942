import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { DateRange } from 'react-day-picker';

// Components
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Divider from '@mui/material/Divider';
import TableFooter from 'components/TableFooter';
import Loading from 'components/Loading';

// Icons
import CurrencyRuble from '@mui/icons-material/CurrencyRuble';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DropdownIndicator from 'components/DropdownIndicator';

// Utils
import { formatTime } from 'utils/timetrackerUtils';

// Stores
import timesheetsStore from 'stores/TimesheetsStore';
import projectsStore from 'stores/ProjectsStore';

// Styles
import classes from './TableTimesheets.module.scss';

interface TableTimesheetsProps {
  dateRange: DateRange;
}

const changeArrow = (sortDir: 1 | -1, active: boolean) => {
  return <DropdownIndicator sortDir={sortDir} active={active} />;
};

const TableTimesheets: FC<TableTimesheetsProps> = ({ dateRange }) => {
  useEffect(() => {
    timesheetsStore.loadRecords(dateRange.from as Date, dateRange.to as Date);
  }, [dateRange]);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const { loading, records, sort, sortDir } = timesheetsStore;
  const { projects } = projectsStore;

  return (
    <div className={classes.wrapper}>
      <TableContainer className={classes.container}>
        <Table aria-label="a dense table" className={classes.table}>
          <TableHead>
            <TableRow
              sx={{
                '.MuiTableCell-root': {
                  borderBottom: 'none'
                }
              }}
            >
              <TableCell
                align="left"
                className={clsx(classes.tableCellHead, classes.projectCell)}
              >
                <TableSortLabel
                  onClick={() => timesheetsStore.setSort('project')}
                  IconComponent={() => changeArrow(sortDir, sort === 'project')}
                >
                  Project
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                className={clsx(classes.tableCellHead, classes.projectCell)}
              >
                <TableSortLabel
                  onClick={() => timesheetsStore.setSort('task')}
                  IconComponent={() => changeArrow(sortDir, sort === 'task')}
                >
                  Task
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                className={clsx(classes.tableCellHead, classes.descriptionCell)}
              >
                <TableSortLabel
                  onClick={() => timesheetsStore.setSort('description')}
                  IconComponent={() =>
                    changeArrow(sortDir, sort === 'description')
                  }
                >
                  Description
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                className={clsx(classes.tableCellHead, classes.nameCell)}
              >
                <TableSortLabel
                  onClick={() => timesheetsStore.setSort('name')}
                  IconComponent={() => changeArrow(sortDir, sort === 'name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                className={clsx(classes.tableCellHead, classes.billableCell)}
              >
                <TableSortLabel
                  onClick={() => timesheetsStore.setSort('billable')}
                  IconComponent={() =>
                    changeArrow(sortDir, sort === 'billable')
                  }
                >
                  Billable
                </TableSortLabel>
              </TableCell>
              <TableCell align="left" className={clsx(classes.tableCellHead)}>
                <TableSortLabel
                  onClick={() => timesheetsStore.setSort('time')}
                  IconComponent={() => changeArrow(sortDir, sort === 'time')}
                >
                  Time
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                className={clsx(classes.tableCellHead, classes.durationCell)}
              >
                <TableSortLabel
                  onClick={() => timesheetsStore.setSort('time')}
                  IconComponent={() => changeArrow(sortDir, sort === 'time')}
                >
                  Duration
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableCellHead} />
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {loading ? (
              <Loading table />
            ) : (
              records
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((record) => {
                  const {
                    description,
                    name,
                    title,
                    expertise,
                    startedAt,
                    endedAt,
                    billable,
                    projectId,
                    totalTime
                  } = record;
                  const project = projects.find(
                    (item) => item.id === projectId
                  );

                  return (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      sx={{
                        '.MuiTableCell-root': {
                          borderBottom: 'none'
                        }
                      }}
                    >
                      <TableCell
                        align="left"
                        className={clsx(
                          classes.typography,
                          classes.projectCell
                        )}
                        sx={{ color: project?.colour }}
                      >
                        {project?.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={clsx(
                          classes.typography,
                          classes.projectCell
                        )}
                      >
                        {expertise || title}
                      </TableCell>
                      <TableCell align="left" className={classes.typography}>
                        {description}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={clsx(classes.typography, classes.nameCell)}
                      >
                        {name}
                      </TableCell>
                      <TableCell align="center" className={classes.typography}>
                        <CurrencyRuble
                          fontSize="small"
                          color={billable ? 'primary' : 'disabled'}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        className={clsx(classes.typography, classes.timeCell)}
                      >
                        <span>
                          <span>{formatTime(startedAt, 'HH:mm')}</span>
                          <span> – </span>
                          <span>
                            {endedAt ? formatTime(endedAt, 'HH:mm') : '..'}
                          </span>
                        </span>
                        <span>{dayjs(startedAt).format('MM/DD/YYYY')}</span>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={clsx(
                          classes.typography,
                          classes.durationCell
                        )}
                      >
                        <span className={classes.duration}>
                          {totalTime.toFixed(2).replace('.', ',')}
                        </span>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={clsx(classes.typography)}
                      >
                        <Divider flexItem hidden orientation="vertical" />
                        <IconButton>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!loading ? (
        <TableFooter
          rowsLength={records?.length}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      ) : null}
    </div>
  );
};

export default observer(TableTimesheets);
