import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import BlockWrapper from 'components/BlockWrapper';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as PenIcon } from 'assets/icons/pen2.svg';

// Types
import {
  WorkspaceSettingsNames as Names,
  WorkspaceBlockProps
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import { IWorkspaceSettingsPatch } from 'types/workspaceSettings';

// Stores
import workspaceSettingsStore from 'stores/WorkspaceSettingsStore';

// Styles
import classes from './ClockifySettings.module.scss';

const ClockifySetting: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, handleChange } = useFormikContext<IWorkspaceSettingsPatch>();

  const [isEditAPI, setIsEditAPI] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [workspaces, setWorkspaces] = useState(
    workspaceSettingsStore.clockifyWorkspaces
  );

  const editAPIHandler = () => {
    setIsEditAPI(!isEditAPI);
  };

  const blurAPIHandler = () => {
    setIsEditAPI(false);
    const changeAPIKey = async () => {
      await workspaceSettingsStore.updateWorkspaceSettings({
        [Names.clockifyApiKey]: values.clockifyApiKey
      });
      await workspaceSettingsStore.getClockifyWorkspaces();
      setWorkspaces(workspaceSettingsStore.clockifyWorkspaces);
    };
    changeAPIKey();
  };

  useEffect(() => {
    const getWorkspaces = async () => {
      await workspaceSettingsStore.getClockifyWorkspaces();
      setWorkspaces(workspaceSettingsStore.clockifyWorkspaces);
    };
    getWorkspaces();
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditAPI]);

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.textInfoContainer}>
          <span className={classes.title}>Transferring data from Сlockify</span>
          <span className={classes.description}>
            Connect Clockify using your personal API key.
          </span>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.inputsContainer}>
            <Box>
              <span className={classes.label}>API key</span>
              <Box className={classes.inputRow}>
                <TextField
                  multiline
                  disabled={!isEditAPI}
                  color="primary"
                  variant="standard"
                  name={Names.clockifyApiKey}
                  onBlur={blurAPIHandler}
                  inputRef={inputRef}
                  value={values.clockifyApiKey}
                  placeholder={
                    workspaceSettingsStore.workspaceSettings?.isClockifyKeyExist
                      ? 'Key exist'
                      : 'No key'
                  }
                  onChange={(e) => {
                    handleChange(e);
                    onChange();
                  }}
                  fullWidth
                  InputLabelProps={{
                    className: classes.input
                  }}
                />
                <Box display="inline-block">
                  <IconButton
                    onClick={editAPIHandler}
                    size="medium"
                    className={classes.editIcon}
                  >
                    <PenIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>

            <Box>
              <span className={classes.label}>Workspace</span>
              <Select
                name={Names.workspaceClockifyId}
                value={values.workspaceClockifyId}
                defaultValue={values.workspaceClockifyId}
                variant="standard"
                className={classes.input}
                fullWidth
                onChange={(e) => {
                  handleChange(e);
                  onChange();
                }}
              >
                {workspaces?.map((value) => {
                  return (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default ClockifySetting;
