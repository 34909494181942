import { Placeholder } from './placeholder';
import { IProject } from './project';
import { User as IUser } from './user';

export interface PaginationData {
  totalItems: number;
  totalPages: number;
  currentPage: number;
}

export interface AssignmentUser {
  id: string;
  userId: string;
  projectId: string;
  rate: number;
  expertize?: string | null;
  expertiseId?: string | null;
  dailyHours: number;
  startAt: string;
  endAt: string;
}

export interface PlannerUser {
  userId: string;
  defaultRate: number;
  defaultWeeklyCapacity: number;
  defaultExpertize: string;
  workDays: number[];
}

export type PlannerPerson =
  | (Omit<IUser, 'projects'> & {
      type: 'user';
    })
  | (Placeholder & {
      type: 'placeholder';
    });

export interface TimelineUser extends Omit<IUser, 'projects'> {
  workTime: number[];
  intervals: string[][];
  projects: ({
    intervals: ({ workTime: number[] } & AssignmentUser)[];
    isGhost: boolean;
  } & IProject)[];
}

export interface TimelinePlaceholder extends Placeholder {
  workTime: number[];
  intervals: string[][];
  projects: ({
    intervals: ({ workTime: number[] } & AssignmentUser)[];
    isGhost: boolean;
  } & IProject)[];
}

export type TimelinePerson =
  | (TimelineUser & {
      type: 'user';
    })
  | (TimelinePlaceholder & {
      type: 'placeholder';
    });

export interface TimelineProject extends IProject {
  users: ({
    intervals: ({ workTime: number[] } & AssignmentUser)[];
  } & IUser)[];
  placeholders: ({
    intervals: ({ workTime: number[] } & AssignmentUser)[];
  } & TimelinePlaceholder)[];
  workTime: number[];
  intervals: string[][];
  allocations: number[];
  isGhost: boolean;
}

export enum TimelineAssignmentType {
  User,
  Project,
  NewAssignment
}
