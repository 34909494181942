import React, { FC, useEffect, useState } from 'react';

// Components
import Button from '@mui/material/Button';

// Types
import { WeekDay } from 'types/user';

// Styles
import classes from './WeekDayCheckbox.module.scss';

interface WeekDayCheckboxProps {
  day: number;
  checked: boolean[];
  onChange: () => void;
}

const WeekDayCheckbox: FC<WeekDayCheckboxProps> = ({
  day,
  checked,
  onChange
}) => {
  const [isChecked, setIsChecked] = useState<boolean>();
  useEffect(() => {
    setIsChecked(checked[day]);
  }, checked);
  const handleCLick = () => {
    setIsChecked(!isChecked);
    onChange();
  };
  const week = Object.values(WeekDay);

  return (
    <Button
      variant="outlined"
      className={isChecked ? classes.checked : classes.notChecked}
      onClick={handleCLick}
    >
      {week[day][0]}
    </Button>
  );
};

export default WeekDayCheckbox;
