import { AxiosRequestConfig, AxiosResponse } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

// API
import { getProfile, login, LoginResponse, refresh, TokenData } from 'api/auth';
import { recoveryPassword, updatePassword } from 'api/profileSettings';

// Helpers
import instance from 'api/helpers/axios';

// Types
import { IPasswordPatch, IPasswordRecoveryPatch } from 'types/user';

// Stores
import profileStore from 'stores/ProfileStore';
import workspaceSettingsStore from './WorkspaceSettingsStore';
import alertsStore from './alertsStore';

class AuthStore {
  @observable
  isLogin: boolean = false;

  @observable
  isChecking: boolean = false;

  @observable
  isChecked: boolean = false;

  refreshPromise: Promise<AxiosResponse<LoginResponse>> | null = null;

  @observable
  uuid: string | null = null;

  @observable
  accessToken: string | null = localStorage.getItem('accessToken') || null;

  @observable
  refreshToken: string | null = localStorage.getItem('refreshToken') || null;

  @observable
  expiresIn: Dayjs | null = localStorage.getItem('expiresIn')
    ? dayjs(localStorage.getItem('expiresIn'))
    : null;

  constructor() {
    makeObservable(this);

    this.uuid = localStorage.getItem('uuid');

    if (!this.uuid) {
      this.uuid = uuidv4();
      localStorage.setItem('uuid', this.uuid);
    }
  }

  @action
  setToken = (token: TokenData | null) => {
    this.accessToken = token?.accessToken || null;
    this.refreshToken = token?.refreshToken || null;
    this.expiresIn = token?.expiresIn ? dayjs(token?.expiresIn) : null;

    if (this.accessToken && this.refreshToken && this.expiresIn) {
      localStorage.setItem('accessToken', this.accessToken);
      localStorage.setItem('refreshToken', this.refreshToken);
      localStorage.setItem('expiresIn', this.expiresIn.toISOString());
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('expiresIn');
    }
  };

  login = async (email: string, password: string) => {
    try {
      const {
        data: { data }
      } = await login(email, password, this.uuid as string);

      profileStore.setProfile(data.profile);

      this.setToken(data.token);

      runInAction(() => {
        this.isLogin = true;
      });
    } catch (error) {
      console.error(error);
      runInAction(() => {
        this.isLogin = false;
      });
    }
  };

  @action
  refresh = async () => {
    if (!this.refreshToken || !this.uuid) {
      return Promise.reject();
    }

    if (!this.refreshPromise) {
      this.refreshPromise = refresh(this.refreshToken, this.uuid);
    }

    const result = await this.refreshPromise;
    this.refreshPromise = null;

    if (!result) {
      return Promise.reject();
    }

    this.setToken(result.data.data.token);

    return Promise.resolve();
  };

  @action
  checkAuth = async () => {
    if (this.isChecking) {
      return;
    }

    if (dayjs().isAfter(this.expiresIn)) {
      await this.refresh();
    }

    if (!this.accessToken) {
      this.isChecked = true;
      this.isChecking = false;
      this.isLogin = false;

      if (this.accessToken) {
        this.setToken(null);
      }

      return;
    }

    this.isChecked = false;
    this.isChecking = true;

    try {
      const { data } = await getProfile();

      profileStore.setProfile(data.data);

      await workspaceSettingsStore.getWorkspaceSettings();

      runInAction(() => {
        this.isLogin = true;
      });
    } catch (error) {
      console.warn('checkAuth error', error);

      runInAction(() => {
        this.isLogin = false;
      });
    } finally {
      runInAction(() => {
        this.isChecked = true;
        this.isChecking = false;
      });
    }
  };

  @action
  logout = (): void => {
    this.accessToken = null;
    this.refreshToken = null;
    this.expiresIn = null;
    this.isLogin = false;

    this.setToken(null);
  };

  @action
  updatePassword = async (passwordPatch: IPasswordPatch) => {
    try {
      const data = await updatePassword(passwordPatch);
      this.setToken(data.data.token);
    } catch (error) {
      console.error(error);
    }
  };

  @action
  updatePasswordRecovery = async (
    isEmail: string,
    firstPassword: string,
    secondPassword: string
  ) => {
    if (isEmail) {
      const passwordPatch: IPasswordRecoveryPatch = {
        email: isEmail,
        password: firstPassword,
        newPassword: secondPassword
      };

      try {
        const data = await recoveryPassword(passwordPatch);
        this.setToken(data.data.token);
      } catch (error) {
        console.error(error);
      }
    }
  };
}

const authStore = new AuthStore();

instance.interceptors.request.use(
  (config) => {
    if (!config.headers) {
      config.headers = {};
    }

    config.headers.Authorization = `Bearer ${authStore.accessToken || ''}`;

    return config;
  },
  (error) => {
    alertsStore.handleAnswer({
      success: false,
      message: `Error occured while sending request`
    });

    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (error: {
    message?: string;
    config: AxiosRequestConfig;
    response?: AxiosResponse;
    statusCode: number;
  }) => {
    const isInIgnoredUrlList = [
      '/auth/login',
      '/auth/refresh-token',
      '/auth/logout'
    ].includes(error.config?.url || '');

    if (!error.response && !isInIgnoredUrlList) {
      // eslint-disable-next-line no-alert,
      alertsStore.handleAnswer({
        success: false,
        message: `No internet connection.\nPlease, check your internet and try again`
      });

      return Promise.reject(error);
    }

    if (
      authStore.refreshToken &&
      authStore.uuid &&
      !isInIgnoredUrlList &&
      (error.response?.status === 401 ||
        error.message === 'Error: Request failed with status code 401' ||
        ((error.response?.status === 403 ||
          error.message === 'Error: Request failed with status code 403') &&
          error.response?.data === 'User is not authenticated'))
    ) {
      await authStore.refresh();

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      error.config.headers!.Authorization = `Bearer ${
        authStore.accessToken || ''
      }`;
      error.config.baseURL = undefined;

      return instance.request(error.config);
    }

    // if (error.response.status === 400 || error.message === 'Error: Request failed with status code 400') {
    //   Toast.show('Fields are filled in incorrectly.\nPlease, set correctly values', {
    //     duration: 1500,
    //   });
    // }

    console.warn('HTTP Error', error);

    if (
      !isInIgnoredUrlList &&
      error.response?.status &&
      error.response?.status >= 400 &&
      error.response?.status !== 401 &&
      error.response?.data !== 'User is not authenticated'
    ) {
      alertsStore.handleAnswer({
        success: false,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        message:
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          error.response?.data?.error?.message ||
          'Server error.\nPlease, try again later'
      });
    }

    if (error.response?.status) {
      error.statusCode = error.response.status;
    }

    return Promise.reject(error);
  }
);

export default authStore;
