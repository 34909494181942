import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import AccessTime from '@mui/icons-material/AccessTime';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Close from '@mui/icons-material/Close';
import CurrencyExchange from '@mui/icons-material/CurrencyExchange';
import CurrencyRuble from '@mui/icons-material/CurrencyRuble';

// Types
import { User, UserRole } from 'types/user';
import { Placeholder } from 'types/placeholder';

// Stores
import rateStore from 'stores/RateStore';
import membersStore from 'stores/MembersStore';
import plannerStore from 'stores/PlannerStore';
import profileStore from 'stores/ProfileStore';

// Components
import Profile from 'layouts/ProfileLayout';
import ButtonWorkHours from 'components/ButtonWorkHours/ButtonWorkHours';
import AddPlaceholderModal from '../AddPlaceholderModal';

// Styles
import classes from './PlannerPersonModal.module.scss';

interface PlannerPersonModalProps {
  isOpen: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  title: string;
  user?: User;
  placeholder?: Placeholder;
}

interface PlannerPerson {
  id: string;
  name: string;
  defaultRate: number;
  defaultWeeklyCapacity: number;
  defaultExpertize: string;
  workDays: number[];
}

const expertises = [
  {
    value: 'PA'
  },
  {
    value: 'QA'
  },
  {
    value: 'Fullstack'
  },
  {
    value: 'Design'
  },
  {
    value: 'Frontend'
  },
  {
    value: 'Flutter'
  },
  {
    value: 'IOS'
  },
  {
    value: 'Android'
  },
  {
    value: 'Backend'
  }
];

const PlannerPersonModal: FC<PlannerPersonModalProps> = ({
  isOpen,
  setOpen,
  title,
  user,
  placeholder
}) => {
  const { members } = membersStore;

  const usersWithoutPlanner = useMemo(
    () => members.filter((member) => !member.hasPlanner),
    [members]
  );
  const [workDays, setWorkDays] = useState<number[]>(user?.workDays || []);
  const [userId, setUserId] = useState<string>(user?.id || '');
  const [userName, setName] = useState<string>(user?.name || '');
  const [rubleValue, setRubleValue] = useState<number>(0);
  const [expertiseValue, setExpertiseValue] = useState<string>('');
  const [hoursValue, setHoursValue] = useState<number>(0);
  const [capacityValue, setCapacityValue] = useState<number>(0);
  const [placeholderName, setPlaceholderName] = useState<string | null>(
    placeholder?.name || null
  );
  const [isPlaceholder, setIsPlaceholder] = useState(false);
  const [isPlaceholderOpen, setPlaceholderOpen] = useState(false);
  const isEditPerson = title === 'Edit person';
  const [focusHours, setFocusHours] = useState<boolean>(false);
  const [focusUser, setFocusUser] = useState<boolean>(false);
  const [focusExpertize, setFocusExpertize] = useState<boolean>(false);
  const [focusBillable, setFocusBillable] = useState<boolean>(false);
  const [focusCapacity, setFocusCapacity] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const profileRole = profileStore.role;

  const handleAddPerson = useCallback((currentUser: PlannerPerson) => {
    if (!isEditPerson) {
      plannerStore.addPlannerUser({
        userId: currentUser.id,
        defaultWeeklyCapacity: currentUser.defaultWeeklyCapacity,
        workDays: [0, 1, 2, 3, 4]
      });
    } else {
      plannerStore.updatePlannerUser({
        userId: currentUser.id,
        defaultWeeklyCapacity: currentUser.defaultWeeklyCapacity,
        workDays: currentUser.workDays
      });
    }
  }, []);

  const handleDeletePerson = useCallback((plannerUserId: string) => {
    if (isEditPerson) {
      plannerStore.deletePlannerUser(plannerUserId);
    }
  }, []);

  // TODO: separate POST from PATCH
  const handleAddPlaceholder = useCallback(
    (currentPlaceholder: Placeholder) => {
      if (!isEditPerson) {
        plannerStore.addPlannerPlaceholder({
          name: currentPlaceholder.name,
          defaultRate: currentPlaceholder.defaultRate,
          defaultWeeklyCapacity: currentPlaceholder.defaultWeeklyCapacity,
          defaultExpertize: currentPlaceholder.defaultExpertize,
          workDays: [0, 1, 2, 3, 4]
        });
      } else {
        plannerStore.updatePlannerPlaceholder({
          id: currentPlaceholder.id,
          name: currentPlaceholder.name,
          defaultRate: currentPlaceholder.defaultRate,
          defaultWeeklyCapacity: currentPlaceholder.defaultWeeklyCapacity,
          defaultExpertize: currentPlaceholder.defaultExpertize,
          workDays: [0, 1, 2, 3, 4]
        });
      }
    },
    []
  );

  const handleDeletePlaceholder = useCallback((plannerUserId: string) => {
    if (isEditPerson) {
      plannerStore.deletePlannerPlaceholder(plannerUserId);
    }
  }, []);

  useEffect(() => {
    membersStore.loadMembers();
    rateStore.getDollarRate();

    if (user) {
      setUserId(user.id);
      setName(user.name);
      setIsPlaceholder(false);
      setWorkDays(user.workDays);
      setRubleValue(Math.round(user.defaultRate * 100) / 100);
      setExpertiseValue(user.defaultExpertize);
      setHoursValue(user.defaultWeeklyCapacity / 5);

      if (user.defaultWeeklyCapacity) {
        setCapacityValue(user.defaultWeeklyCapacity);
      }

      setDisabled(true);
    } else if (placeholder) {
      setUserId(placeholder.id);
      setName(placeholder.name);
      setIsPlaceholder(true);
      setWorkDays(placeholder.workDays ?? []);
      setRubleValue(placeholder.defaultRate ?? 0);
      setExpertiseValue(placeholder.defaultExpertize ?? '');
      setHoursValue((placeholder.defaultWeeklyCapacity ?? 0) / 5);
      setCapacityValue(placeholder.defaultWeeklyCapacity ?? 0);
      setDisabled(false);
    }
  }, [user, placeholder]);

  const close = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const setUser = (value: string) => {
    if (isEditPerson) {
      setName(value);
    } else {
      setUserId(value);
    }
  };

  const handleUser = (value: string) => {
    setUser(value);
    setPlaceholderName(null);

    if (!isEditPerson) {
      const foundMember = members.find((item) => item.id === value);

      if (!foundMember) {
        return;
      }

      setRubleValue(Math.round(foundMember.defaultRate * 100) / 100);
      setExpertiseValue(foundMember.defaultExpertize);
    } else if (!isPlaceholder) {
      setDisabled(true);
    }
  };

  const handlePlaceholderSubmit = (name: string, expertise: string) => {
    setUser(name);
    setPlaceholderName(name);
    setExpertiseValue(expertise);
    setDisabled(false);
  };

  const handlePlaceholderCancel = () => {
    if (placeholderName === null) {
      setUser('');
    }
  };

  const handlePlaceholderOpen = () => {
    setPlaceholderOpen(true);
  };

  const handleRate = (rate: number) => {
    if (rate < 999999) {
      setRubleValue(Math.round(rate * 100) / 100);
    }
  };
  const handleExpertise = (expertise: string) => {
    if (isPlaceholder) {
      setExpertiseValue(expertise);
    }
  };

  const handleHours = (hours: string) => {
    const hoursInt = parseFloat(hours);

    if (hoursInt > 0 && hoursInt <= 8) {
      setHoursValue(hoursInt);
    }
  };

  const handleCapacity = (capacity: number) => {
    if (capacity < 100 && capacity > -1) {
      setCapacityValue(capacity);
    }
  };

  return (
    <Profile isOpen={isOpen} handleClose={close}>
      <div className={classes.wrapper}>
        <div className={classes.headerContainer}>
          <div>{title}</div>
          <IconButton
            onClick={() => {
              close();
            }}
          >
            <Close className={classes.close} />
          </IconButton>
        </div>
        <div className={classes.fullTextFieldContainer}>
          <div
            className={classes.name}
            style={{ color: focusUser ? '#f36d25' : 'inherit' }}
          >
            Member name
          </div>
          <TextField
            id="standard-basic"
            variant="standard"
            select={!isEditPerson}
            color="primary"
            InputProps={{
              classes: {
                root: classes.font
              }
            }}
            onFocus={() => setFocusUser(true)}
            onBlur={() => setFocusUser(false)}
            className={classes.fullTextField}
            onChange={(e) => handleUser(e.target.value)}
            value={isEditPerson ? userName : userId}
            disabled={isEditPerson && !isPlaceholder}
          >
            <MenuItem
              onClick={handlePlaceholderOpen}
              value={placeholderName ?? 'placeholder'}
            >
              Placeholder{placeholderName ? `: ${placeholderName}` : ''}
            </MenuItem>
            {isEditPerson
              ? user?.name
              : usersWithoutPlanner.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
          </TextField>
        </div>
        <div className={classes.fullTextFieldContainer}>
          <div
            className={classes.name}
            style={{ color: focusBillable ? '#f36d25' : 'inherit' }}
          >
            Hourly rate
          </div>
          <TextField
            id="standard-basic"
            variant="standard"
            color="primary"
            onFocus={() => setFocusBillable(true)}
            onBlur={() => setFocusBillable(false)}
            InputProps={{
              startAdornment: <CurrencyRuble className={classes.icon} />,
              classes: {
                root: classes.font
              }
            }}
            value={rubleValue}
            disabled={disabled}
            onChange={(e) => handleRate(Number(e.target.value))}
            className={classes.rateTextField}
            style={{ marginRight: user?.currency === 'USD' ? '0%' : '54%' }}
          />
          <TextField
            id="standard-basic"
            variant="standard"
            color="primary"
            onFocus={() => setFocusBillable(true)}
            onBlur={() => setFocusBillable(false)}
            InputProps={{
              startAdornment: <AttachMoney className={classes.dollarIcon} />,
              classes: {
                root: classes.font
              }
            }}
            disabled={disabled}
            value={(Number(rubleValue) / rateStore.rate).toFixed(2)}
            className={classes.rateTextField}
            style={{ display: user?.currency === 'USD' ? 'flex' : 'none' }}
          />
          <TextField
            id="standard-basic"
            variant="standard"
            color="primary"
            onFocus={() => setFocusBillable(true)}
            onBlur={() => setFocusBillable(false)}
            InputProps={{
              startAdornment: (
                <CurrencyExchange className={classes.iconConvert} />
              ),
              classes: {
                root: classes.font
              }
            }}
            disabled={disabled}
            value={Math.round(rateStore.rate * 100) / 100}
            className={classes.rateTextField}
            style={{ display: user?.currency === 'USD' ? 'flex' : 'none' }}
          />
        </div>
        <div className={classes.fullTextFieldContainer}>
          <div
            className={classes.name}
            style={{ color: focusExpertize ? '#f36d25' : 'inherit' }}
          >
            Expertise
          </div>
          <TextField
            id="standard-basic"
            variant="standard"
            color="primary"
            onFocus={() => setFocusExpertize(true)}
            onBlur={() => setFocusExpertize(false)}
            onChange={(e) => handleExpertise(e.target.value)}
            InputProps={{
              classes: {
                root: classes.font
              }
            }}
            select
            value={expertiseValue}
            disabled={disabled}
            className={classes.fullTextField}
          >
            {expertises.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.minTextFieldContainer}>
          <Grid
            className={classes.nameMinTextField}
            item
            xs={2}
            style={{ color: focusHours ? '#f36d25' : 'inherit' }}
          >
            Hours/Day
          </Grid>
          <TextField
            id="standard-basic"
            variant="standard"
            color="primary"
            InputProps={{
              classes: {
                root: classes.font
              }
            }}
            sx={{
              '.MuiTextField-root': {
                color: '#f36d25'
              }
            }}
            type="number"
            value={hoursValue}
            onFocus={() => setFocusHours(true)}
            onBlur={() => setFocusHours(false)}
            onChange={(e) => handleHours(e.target.value)}
            className={classes.minTextField}
            placeholder="0 hrs"
            disabled={profileRole === UserRole.USER}
          />
          <div className={classes.info}>
            {Math.floor((hoursValue / 8) * 100)}% of 8 h/d
          </div>
        </div>
        <div className={classes.minTextFieldContainer}>
          <Grid
            className={classes.nameMinTextField}
            item
            xs={2}
            style={{ color: focusCapacity ? '#f36d25' : 'inherit' }}
          >
            Capacity
          </Grid>
          <TextField
            id="standard-basic"
            variant="standard"
            color="primary"
            onFocus={() => setFocusCapacity(true)}
            onBlur={() => setFocusCapacity(false)}
            className={classes.minTextField}
            InputProps={{
              startAdornment: <AccessTime className={classes.timeIcon} />,
              classes: {
                root: classes.font
              }
            }}
            sx={{
              '.MuiTextField-root': {
                color: '#f36d25'
              }
            }}
            type="number"
            value={capacityValue}
            disabled={profileRole === UserRole.USER}
            onChange={(e) => handleCapacity(Number(e.target.value))}
          />
          <div className={classes.info}>hours per week</div>
        </div>
        <div className={classes.workDaysContainer}>
          <div className={classes.name}>Work days</div>
          <div className={classes.buttonContainerWorkDays}>
            <ButtonWorkHours
              workDays={workDays}
              setWorkDays={setWorkDays}
              day={0}
            />
            <ButtonWorkHours
              workDays={workDays}
              setWorkDays={setWorkDays}
              day={1}
            />
            <ButtonWorkHours
              workDays={workDays}
              setWorkDays={setWorkDays}
              day={2}
            />
            <ButtonWorkHours
              workDays={workDays}
              setWorkDays={setWorkDays}
              day={3}
            />
            <ButtonWorkHours
              workDays={workDays}
              setWorkDays={setWorkDays}
              day={4}
            />
            <ButtonWorkHours
              workDays={workDays}
              setWorkDays={setWorkDays}
              day={5}
            />
            <ButtonWorkHours
              workDays={workDays}
              setWorkDays={setWorkDays}
              day={6}
            />
          </div>
        </div>
        {profileRole === UserRole.USER ? null : (
          <div className={classes.buttonBox}>
            {isEditPerson ? (
              <Button
                className={classes.buttonWithoutBorders}
                onClick={() => {
                  if (isPlaceholder) {
                    handleDeletePlaceholder(userId);
                  } else {
                    handleDeletePerson(userId);
                  }

                  close();
                }}
              >
                DELETE
              </Button>
            ) : null}
            <Button
              className={classes.buttonWithoutBorders}
              onClick={() => {
                close();
              }}
            >
              CANCEL
            </Button>
            <Button
              className={classes.save}
              onClick={() => {
                if (placeholderName !== null || isPlaceholder) {
                  handleAddPlaceholder({
                    id: placeholderName !== null ? userId : '',
                    name: placeholderName !== null ? placeholderName : userName,
                    defaultRate: rubleValue,
                    defaultWeeklyCapacity: capacityValue,
                    defaultExpertize: expertiseValue,
                    workDays
                  });
                } else {
                  handleAddPerson({
                    id: userId,
                    name: userName,
                    defaultRate: rubleValue,
                    defaultWeeklyCapacity: capacityValue,
                    defaultExpertize: expertiseValue,
                    workDays
                  });
                }

                close();
              }}
            >
              SAVE
            </Button>
          </div>
        )}
      </div>
      <AddPlaceholderModal
        isOpen={isPlaceholderOpen}
        setOpen={setPlaceholderOpen}
        expertises={expertises}
        onSubmit={handlePlaceholderSubmit}
        onCancel={handlePlaceholderCancel}
      />
    </Profile>
  );
};

export default observer(PlannerPersonModal);
