import React, { FC, ReactNode } from 'react';

// Components
import Box from '@mui/material/Box';

// Styles
import classes from './BlockWrapper.module.scss';

interface BlockWrapperProps {
  children: ReactNode;
}

const BlockWrapper: FC<BlockWrapperProps> = ({ children }) => {
  return <Box className={classes.wrapper}>{children}</Box>;
};

export default BlockWrapper;
