import React, { FC, useEffect, useState } from 'react';
import countryList from 'country-list';
import moment from 'moment-timezone';
import { useFormik } from 'formik';

// Types
import Hours, { DateFormat, TimeFormat, WeekDay } from 'types/user';

// Stores
import profileStore from 'stores/ProfileStore';

// Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import BlockWrapper from 'components/BlockWrapper';
import WeekDayCheckbox from '../../../components/WeekDayCheckbox';

// Styles
import classes from './WorkTimeSettings.module.scss';

enum Names {
  country = 'country',
  timeZone = 'timeZone',
  dateFormat = 'dateFormat',
  weekStart = 'weekStart',
  timeFormat = 'timeFormat',
  dayStart = 'dayStart',
  workDays = 'workDays'
}

const WorkTimeSettings: FC = () => {
  const [isEdit, setIsEdit] = useState(false);

  const countries = countryList.getNames();
  const timezones = moment.tz.names();

  const { profile } = profileStore;
  useEffect(() => {
    profileStore.getProfile();
  }, []);

  const formik = useFormik({
    initialValues: {
      [Names.country]: profile?.country,
      [Names.timeZone]: profile?.timeZone,
      [Names.dateFormat]: profile?.dateFormat,
      [Names.weekStart]: profile?.weekStart,
      [Names.timeFormat]: profile?.timeFormat,
      [Names.dayStart]: profile?.dayStart,
      [Names.workDays]: profile?.workDays
    },
    onSubmit: (values) => {
      setIsEdit(false);
      profileStore.updateProfile({
        userId: profileStore.profile?.id as string,
        [Names.country]: values.country,
        [Names.timeZone]: values.timeZone,
        [Names.dateFormat]: values.dateFormat,
        [Names.weekStart]: values.weekStart,
        [Names.timeFormat]: values.timeFormat,
        [Names.dayStart]: values.dayStart,
        [Names.workDays]: values.workDays
      });
    }
  });

  const weekList: string[] = Object.values(WeekDay);
  let workDaysBool: boolean[] = [];
  weekList.forEach((value, key) => {
    workDaysBool.push(profile?.workDays.includes(key) as boolean);
  });

  const [workDaysBoolState, setWorkDaysBoolState] = useState(workDaysBool);
  useEffect(() => {
    const newWorkDays: number[] = [];
    weekList.forEach((day, i) => {
      if (workDaysBoolState[i] === true) {
        newWorkDays.push(i);
      }
    });
    formik.values.workDays = newWorkDays;
  }, [workDaysBoolState]);

  const onChangeHandler = () => {
    setIsEdit(true);
  };
  const cancelHandler = () => {
    console.log(formik.values);
    formik.values.country = profile?.country;
    formik.values.timeZone = profile?.timeZone;
    formik.values.timeFormat = profile?.timeFormat;
    formik.values.weekStart = profile?.weekStart;
    formik.values.timeFormat = profile?.timeFormat;
    formik.values.dateFormat = profile?.dateFormat;
    formik.values.dayStart = profile?.dayStart;
    console.log(formik.values);

    workDaysBool = [];
    weekList.forEach((value, key) => {
      workDaysBool.push(profile?.workDays.includes(key) as boolean);
    });
    setWorkDaysBoolState(workDaysBool);
    setIsEdit(false);
  };

  const weekDaysHandler = (index: number) => () => {
    const newList = [...workDaysBoolState];
    newList[index] = !newList[index];
    setWorkDaysBoolState(newList);
    onChangeHandler();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <BlockWrapper>
        <Box className={classes.wrapper}>
          <Box className={classes.wrapper}>
            <Box className={classes.textInfoContainer}>
              <span className={classes.title}>Work time settings</span>
              <span className={classes.description}>
                Change time zone, when your week starts, and your preferred data
                and time format.
              </span>
            </Box>
            <Box className={classes.FieldsContainer}>
              <Box className={classes.column}>
                <Box className={classes.inputContainer}>
                  <span className={classes.label}>Location Country</span>
                  <Select
                    name={Names.country}
                    value={formik.values.country}
                    variant="standard"
                    className={classes.input}
                    fullWidth
                    onChange={(e) => {
                      formik.handleChange(e);
                      onChangeHandler();
                    }}
                  >
                    {countries.map((value) => {
                      return <MenuItem value={value}>{value}</MenuItem>;
                    })}
                  </Select>
                </Box>

                <Box className={classes.inputContainer}>
                  <span className={classes.label}>Time zone</span>
                  <Select
                    name={Names.timeZone}
                    value={formik.values.timeZone}
                    variant="standard"
                    className={classes.input}
                    fullWidth
                    onChange={(e) => {
                      formik.handleChange(e);
                      onChangeHandler();
                    }}
                  >
                    {timezones.map((value) => {
                      return <MenuItem value={value}>{value}</MenuItem>;
                    })}
                  </Select>
                </Box>

                <Box className={classes.inputContainer}>
                  <span className={classes.label}>Date format</span>
                  <Select
                    name={Names.dateFormat}
                    value={formik.values.dateFormat}
                    variant="standard"
                    className={classes.input}
                    fullWidth
                    onChange={(e) => {
                      formik.handleChange(e);
                      onChangeHandler();
                    }}
                  >
                    {Object.values(DateFormat).map((value) => {
                      return <MenuItem value={value}>{value}</MenuItem>;
                    })}
                  </Select>
                </Box>

                <Box className={classes.inputContainer}>
                  <span className={classes.label}>Week start</span>
                  <Select
                    name={Names.weekStart}
                    value={formik.values.weekStart}
                    variant="standard"
                    className={classes.input}
                    fullWidth
                    onChange={(e) => {
                      formik.handleChange(e);
                      onChangeHandler();
                    }}
                  >
                    {Object.values(WeekDay).map((value) => {
                      return <MenuItem value={value}>{value}</MenuItem>;
                    })}
                  </Select>
                </Box>
              </Box>

              <Box className={classes.column}>
                <Box className={classes.inputContainer}>
                  <span className={classes.label}>Time format</span>
                  <Select
                    name={Names.timeFormat}
                    value={formik.values.timeFormat}
                    variant="standard"
                    className={classes.input}
                    fullWidth
                    onChange={(e) => {
                      formik.handleChange(e);
                      onChangeHandler();
                    }}
                  >
                    {Object.values(TimeFormat).map((value) => {
                      return <MenuItem value={value}>{value}</MenuItem>;
                    })}
                  </Select>
                </Box>

                <Box className={classes.inputContainer}>
                  <span className={classes.label}>Day start</span>
                  <Select
                    name={Names.dayStart}
                    value={formik.values.dayStart}
                    variant="standard"
                    className={classes.input}
                    fullWidth
                    onChange={(e) => {
                      formik.handleChange(e);
                      onChangeHandler();
                    }}
                  >
                    {Hours.map((value) => {
                      return <MenuItem value={value}>{value}</MenuItem>;
                    })}
                  </Select>
                </Box>
                <Box className={classes.workDaysContainer}>
                  <span className={classes.title}>Work days</span>
                  <Box className={classes.workDays}>
                    {workDaysBool.map((dayBool, index) => (
                      <WeekDayCheckbox
                        day={index}
                        checked={workDaysBoolState}
                        onChange={weekDaysHandler(index)}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.buttonBox}>
              <Button
                className={isEdit ? classes.editCancel : classes.cancel}
                disabled={!isEdit}
                onClick={cancelHandler}
              >
                CANCEL
              </Button>
              <Button
                className={isEdit ? classes.editSave : classes.save}
                disabled={!isEdit}
                type="submit"
              >
                SAVE
              </Button>
            </Box>
          </Box>
        </Box>
      </BlockWrapper>
    </form>
  );
};

export default WorkTimeSettings;
