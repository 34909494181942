// Helpers
import axios from 'api/helpers/axios';

// Types
import { ColourCodeProjectVariant, IProject } from 'types/project';
import {
  AssignmentUser,
  TimelinePlaceholder,
  TimelineProject,
  TimelinePerson,
  PlannerPerson
} from 'types/planner';
import { Placeholder } from 'types/placeholder';
import { AxiosResponse } from 'axios';

interface PlannerPersonResponse {
  data: PlannerPerson[];
}

interface TimelinePersonResponse {
  data: {
    items: TimelinePerson[];
    totalItems: number;
    totalPages: number;
    currentPage: number;
  };
}

export interface PersonFilters {
  defaultLegalStatus?: 'all' | 'employee' | 'contractor';
}

interface PlannerProjectResponse {
  data: {
    items: TimelineProject[];
    totalItems: number;
    totalPages: number;
    currentPage: number;
  };
}

export interface PlannerAddingUserRequest {
  userId: string;
  defaultWeeklyCapacity: number;
  workDays: number[];
}

export interface PlannerAddingUserResponse {
  data: {
    userId: string;
    defaultRate: number;
    defaultWeeklyCapacity: number;
    defaultExpertize: string;
    workDays: number[];
  };
}

interface PlannerAddingProjectRequest {
  projectId: string;
  colour: ColourCodeProjectVariant;
}

export interface PlaceholderPatchRequest extends Partial<Placeholder> {
  id: string;
}

export interface PlannerPlaceholderResponse {
  data: Placeholder;
}

export interface PlannerPlaceholdersResponse {
  data: TimelinePlaceholder[];
}

interface PlannerAddingProjectResponse {
  data: IProject;
}

export interface AssignmentUserRequest extends Partial<AssignmentUser> {
  id: string;
}

export interface AssignmentUserResponse {
  data: AssignmentUser;
}

export interface AssignmentsUserResponse {
  data: AssignmentUser[];
}

export function getPlannerUsers(): Promise<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  AxiosResponse<PlannerPersonResponse, any>
>;
export function getPlannerUsers(
  page: number,
  rowsPerPage: number,
  query?: string,
  filters?: PersonFilters
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<TimelinePersonResponse, any>>;
export function getPlannerUsers(
  page?: number,
  rowsPerPage?: number,
  query?: string,
  filters?: PersonFilters
) {
  if (page !== undefined && rowsPerPage !== undefined) {
    return axios.get<TimelinePersonResponse>('/planner/users', {
      params: {
        withProjects: true,
        query,
        page,
        size: rowsPerPage,
        ...filters
      }
    });
  }

  return axios.get<PlannerPersonResponse>('/planner/users', {
    params: { withProjects: false }
  });
}

export const addPlannerUser = (plannerUserdata: PlannerAddingUserRequest) =>
  axios.post<PlannerAddingUserResponse>('/planner/users', plannerUserdata);

export const updatePlannerUser = (plannerUserdata: PlannerAddingUserRequest) =>
  axios.patch<PlannerAddingUserResponse>('/planner/users', plannerUserdata);

export const deletePlannerUser = (userId: string) =>
  axios.delete<PlannerAddingUserResponse>('/planner/users', {
    data: { userId }
  });

export const getPlannerProjects = (
  page: number,
  rowsPerPage: number,
  query?: string
) =>
  axios.get<PlannerProjectResponse>('/planner/projects', {
    params: {
      query,
      page,
      size: rowsPerPage
    }
  });

export const addPlannerProject = (
  plannerProjectData: PlannerAddingProjectRequest
) =>
  axios.post<PlannerAddingProjectResponse>(
    '/planner/projects',
    plannerProjectData
  );

export const addPlannerPlaceholder = (
  placeholderData: Omit<Placeholder, 'id'>
) =>
  axios.post<PlannerPlaceholderResponse>(
    '/planner/placeholders',
    placeholderData
  );

export const updatePlannerPlaceholder = (
  placeholderData: PlaceholderPatchRequest
) =>
  axios.patch<PlannerPlaceholderResponse>(
    '/planner/placeholders',
    placeholderData
  );

export const deletePlannerPlaceholder = (userId: string) =>
  axios.delete<PlannerPlaceholderResponse>('/planner/placeholders', {
    data: { userId }
  });

export const getPlannerPlaceholders = () =>
  axios.get<PlannerPlaceholdersResponse>('/planner/placeholders', {
    params: {
      withProjects: true
    }
  });

export const addPlannerAssignment = (assignmentData: AssignmentUser) => {
  return axios.post<AssignmentUserResponse>(
    `/planner/users/${assignmentData.userId}/assignment/${assignmentData.projectId}`,
    {
      rate: assignmentData.rate,
      expertize: assignmentData.expertize,
      expertiseId: assignmentData.expertiseId,
      dailyHours: assignmentData.dailyHours,
      startAt: assignmentData.startAt,
      endAt: assignmentData.endAt
    }
  );
};

export const updatePlannerAssignment = (
  assignmentData: AssignmentUserRequest
) =>
  axios.patch<AssignmentUserResponse>(
    `/planner/users/assignments/${assignmentData.id}`,
    {
      userId: assignmentData.userId,
      projectId: assignmentData.projectId,
      rate: assignmentData.rate,
      expertize: assignmentData.expertize,
      expertiseId: assignmentData.expertiseId,
      dailyHours: assignmentData.dailyHours,
      startAt: assignmentData.startAt,
      endAt: assignmentData.endAt
    }
  );

export const deletePlannerAssignment = (assignmentId: string) =>
  axios.delete<AssignmentUserResponse>(
    `/planner/users/assignments/${assignmentId}`
  );

export const getPlannerAssignment = (assignmentId: string) =>
  axios.get<AssignmentsUserResponse>(
    `/planner/users/assignments/${assignmentId}`
  );

export const splitPlannerAssignment = (date: string, assignmentIds: string[]) =>
  axios.post<AssignmentsUserResponse>(`/planner/users/assignments/split`, {
    date,
    assignmentIds
  });

export const updatePlaceholderAssignment = (
  assignmentData: AssignmentUserRequest
) =>
  axios.patch<AssignmentUserResponse>(
    `/planner/placeholders/assignments/${assignmentData.id}`,
    {
      userId: assignmentData.userId,
      projectId: assignmentData.projectId,
      rate: assignmentData.rate,
      expertize: assignmentData.expertize,
      expertiseId: assignmentData.expertiseId,
      dailyHours: assignmentData.dailyHours,
      startAt: assignmentData.startAt,
      endAt: assignmentData.endAt
    }
  );

export const addPlaceholderAssignment = (assignmentData: AssignmentUser) => {
  return axios.post<AssignmentUserResponse>(
    `/planner/placeholders/${assignmentData.userId}/assignment/${assignmentData.projectId}`,
    {
      rate: assignmentData.rate,
      expertize: assignmentData.expertize,
      expertiseId: assignmentData.expertiseId,
      dailyHours: assignmentData.dailyHours,
      startAt: assignmentData.startAt,
      endAt: assignmentData.endAt
    }
  );
};

export const deletePlaceholderAssignment = (assignmentId: string) =>
  axios.delete<AssignmentUserResponse>(
    `/planner/placeholders/assignments/${assignmentId}`
  );

export const getPlaceholderAssignment = (assignmentId: string) => {
  return axios.get<AssignmentsUserResponse>(
    `/planner/placeholders/assignments/${assignmentId}`
  );
};

export const splitPlaceholderAssignment = (
  date: string,
  assignmentIds: string[]
) =>
  axios.post<AssignmentsUserResponse>(
    `/planner/placeholders/assignments/split`,
    {
      date,
      assignmentIds
    }
  );
