import React, { FC } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import BlockWrapper from 'components/BlockWrapper';

// Types
import { IWorkspaceSettingsPatch, AdminsAnyone } from 'types/workspaceSettings';
import {
  WorkspaceSettingsNames as Names,
  WorkspaceBlockProps
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';

// Styles
import classes from './BillableHours.module.scss';

const BillableHours: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, handleChange } = useFormikContext<IWorkspaceSettingsPatch>();

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Billable hours</span>
            <span className={classes.description}>
              Set hourly rates and see earnings based on billable hours.
            </span>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.column}>
            <Box className={classes.inputContainer}>
              <Box className={classes.wrapper}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name={Names.isActivateBillableHours}
                        value={values.isActivateBillableHours}
                        checked={values.isActivateBillableHours}
                        onChange={(e) => {
                          handleChange(e);
                          onChange();
                        }}
                      />
                    }
                    label="Activate billable hours"
                  />
                </FormGroup>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.column}>
            <Box className={classes.inputContainer}>
              <Box className={classes.wrapper}>
                <Box className={classes.textInfoContainer}>
                  <span className={classes.description}>
                    Who can see hourly rates and amounts
                  </span>
                </Box>
              </Box>
              <Box className={classes.row}>
                <RadioGroup
                  row
                  name={Names.canSeeHourlyRates}
                  onChange={(e) => {
                    handleChange(e);
                    onChange();
                  }}
                  value={values.canSeeHourlyRates}
                  defaultValue={values.canSeeHourlyRates}
                >
                  <FormControlLabel
                    control={<Radio />}
                    value={AdminsAnyone.ADMINS}
                    label={
                      <Typography className={classes.font}>Admins</Typography>
                    }
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value={AdminsAnyone.ANYONE}
                    label={
                      <Typography className={classes.font}>Anyone</Typography>
                    }
                  />
                </RadioGroup>
              </Box>
            </Box>
            <Box className={classes.inputContainer}>
              <Box className={classes.wrapper}>
                <Box className={classes.textInfoContainer}>
                  <span className={classes.description}>
                    Who can see and change billable status of time entries
                  </span>
                </Box>
              </Box>
              <Box className={classes.row}>
                <RadioGroup
                  row
                  name={Names.canChangeBillable}
                  onChange={(e) => {
                    handleChange(e);
                    onChange();
                  }}
                  value={values.canChangeBillable}
                  defaultValue={values.canChangeBillable}
                >
                  <FormControlLabel
                    control={<Radio />}
                    value={AdminsAnyone.ADMINS}
                    label={
                      <Typography className={classes.font}>Admins</Typography>
                    }
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value={AdminsAnyone.ANYONE}
                    label={
                      <Typography className={classes.font}>Anyone</Typography>
                    }
                  />
                </RadioGroup>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default BillableHours;
