import React, { FC, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';

// Components
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

// Components
import Profile from 'layouts/ProfileLayout';
import SelectProjectButton from 'routes/TimeTracker/NewRecord/SelectProjectButton';
import TrackerTimePicker from 'components/TrackerTimePicker';
import TrackerDatePicker from 'components/TrackerDatePicker';

// Icons
import Close from '@mui/icons-material/Close';

// Types
import { ITimeTrackerRecord } from 'types/timetrackerRecord';
import { IProject } from 'types/project';

// Styles
import classes from './EditRecordsModal.module.scss';

interface IProps {
  isOpen: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  recordsCount: number;
  onUpdate: (values: ITimeTrackerRecord) => void;
}

const EditRecordsModal: FC<IProps> = ({
  isOpen,
  setOpen,
  recordsCount,
  onUpdate
}) => {
  const closeModal = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const initialValues: ITimeTrackerRecord = {
    id: 'id',
    description: '',
    project: undefined,
    title: undefined,
    expertise: undefined,
    startedAt: dayjs().toDate(),
    endedAt: dayjs().toDate(),
    billable: false,
    totalTime: 0
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      onUpdate(values);
      closeModal();
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().required('Please enter description')
    })
  });

  useEffect(() => {
    if (isOpen) {
      formik.setValues({
        ...initialValues
      });
    }
  }, [isOpen]);

  const handleStartedAtChange = (newDate: Dayjs | null) => {
    if (!newDate) {
      return;
    }

    if (newDate.isAfter(formik.values.endedAt)) {
      formik.setValues({
        ...formik.values,
        startedAt: newDate.toDate(),
        endedAt: newDate.toDate()
      });
    } else {
      formik.setValues({
        ...formik.values,
        startedAt: newDate.toDate()
      });
    }
  };

  const handleEndedAtChange = (newDate: Dayjs | null) => {
    if (!newDate) {
      return;
    }

    if (newDate.isBefore(formik.values.startedAt)) {
      formik.setValues({
        ...formik.values,
        startedAt: newDate.toDate(),
        endedAt: newDate.toDate()
      });
    } else {
      formik.setValues({
        ...formik.values,
        endedAt: newDate.toDate()
      });
    }
  };

  const handleDayChange = (day: Date) => {
    const startHour = dayjs(formik.values.startedAt).get('hours');
    const startMinute = dayjs(formik.values.startedAt).get('minutes');
    const endHour = dayjs(formik.values.endedAt).get('hours');
    const endMinute = dayjs(formik.values.endedAt).get('minutes');

    formik.setValues({
      ...formik.values,
      startedAt: dayjs(day).set('h', startHour).set('m', startMinute).toDate(),
      endedAt: dayjs(day).set('h', endHour).set('m', endMinute).toDate()
    });
  };

  const handleProjectChange = (proj: IProject) => {
    formik.setValues({
      ...formik.values,
      project: proj,
      title: undefined,
      expertise: undefined
    });
  };

  const handleExpertiseChange = (expertise: string) => {
    formik.setValues({
      ...formik.values,
      title: undefined,
      expertise
    });
  };

  const handleTaskChange = (task: string) => {
    formik.setValues({
      ...formik.values,
      title: task,
      expertise: undefined
    });
  };

  const getDatePickerLabel = (): string => {
    if (dayjs(formik.values.startedAt).isSame(dayjs(), 'd')) {
      return 'Today';
    }

    return dayjs(formik.values.startedAt).format('YYYY/MM/DD');
  };

  return (
    <Profile isOpen={isOpen} handleClose={closeModal}>
      <div className={classes.wrapper}>
        <div className={classes.headerContainer}>
          <div>Edit {recordsCount} records</div>
          <IconButton
            onClick={() => {
              closeModal();
            }}
          >
            <Close className={classes.close} />
          </IconButton>
        </div>
        <Grid
          container
          spacing={1}
          className={classes.gridBox}
          alignItems="center"
        >
          <Grid item xs={3}>
            <div className={classes.label}>Description</div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="standard-basic"
              name="description"
              value={formik.values.description}
              variant="standard"
              fullWidth
              color="primary"
              InputProps={{
                classes: {
                  root: classes.font
                }
              }}
              className={classes.fullTextField}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          {formik.errors.description && formik.touched.description && (
            <Grid item xs={12} className={classes.error}>
              {formik.errors.description}
            </Grid>
          )}
          <Grid item xs={3}>
            <div className={classes.label}>Project</div>
          </Grid>
          <Grid item xs={9}>
            <SelectProjectButton
              recordToEdit={formik.values}
              onSelectProject={handleProjectChange}
              onSelectExpertise={handleExpertiseChange}
              onSelectTask={handleTaskChange}
              placement="bottom-start"
            />
          </Grid>
          <Grid item xs={3}>
            <div className={classes.label}>Date</div>
          </Grid>
          <Grid item xs={9} className={classes.dateBlock}>
            <TrackerTimePicker
              value={formik.values.startedAt}
              onChange={handleStartedAtChange}
            />
            <span>-</span>
            <TrackerTimePicker
              value={formik.values.endedAt}
              onChange={handleEndedAtChange}
            />
            <TrackerDatePicker
              selected={formik.values.startedAt}
              onSelect={handleDayChange}
            />
            <span>{getDatePickerLabel()}</span>
          </Grid>
        </Grid>
        <div className={classes.buttonBox}>
          <Button
            className={classes.cancel}
            onClick={() => {
              closeModal();
            }}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            className={classes.add}
            onClick={() => formik.handleSubmit()}
            disabled={
              !(
                formik.values.description &&
                (formik.values.title || formik.values.expertise)
              )
            }
          >
            UPDATE
          </Button>
        </div>
      </div>
    </Profile>
  );
};

export default EditRecordsModal;
