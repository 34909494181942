import React, { FC, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';

// Components
import Checkbox from '@mui/material/Checkbox';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Components
import TrackerTimePicker from 'components/TrackerTimePicker';
import TrackerDatePicker from 'components/TrackerDatePicker';
import SelectProjectButton from 'routes/TimeTracker/NewRecord/SelectProjectButton';

// Icons
import CurrencyRuble from '@mui/icons-material/CurrencyRuble';
import MoreVert from '@mui/icons-material/MoreVert';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';

// Types
import { ITimeTrackerRecord } from 'types/timetrackerRecord';
import { IProject } from 'types/project';

// Stores
import timeTrackerStore from 'stores/TimeTrackerStore';

// Utils
import { formatTime } from 'utils/timetrackerUtils';

// Styles
import classes from '../RecordItem.module.scss';

interface IMenuFunctions {
  func: () => void;
  label: string;
}

interface IMenuProps {
  functions: IMenuFunctions[];
}

const IsolatedMenu: FC<IMenuProps> = ({ functions }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleMenuClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {functions.map((f) => (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              f.func();
            }}
          >
            {f.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

interface IProps {
  record: ITimeTrackerRecord;
  isBulkMode: boolean;
  checked: boolean;
  timeFormat?: string;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteRecords: (id?: string) => void;
}

const SingleRecord: FC<IProps> = ({
  record,
  isBulkMode,
  checked,
  timeFormat,
  handleCheckboxChange,
  handleDeleteRecords
}) => {
  const { id, totalTime, billable } = record;

  const initialValues = {
    ...record
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (JSON.stringify(formik.values) === JSON.stringify(record)) {
        return;
      }

      timeTrackerStore.patchRecords([id], values).catch(() => {
        formik.setValues({
          ...record
        });
      });
    }
  });

  useEffect(() => {
    formik.setValues({
      ...record
    });
  }, [record]);

  const handleRepeatRecord = () => {
    timeTrackerStore.runRecord(record);
  };

  const handleDuplicateRecord = () => {
    timeTrackerStore.duplicateRecord(record.id);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    formik.handleBlur(event);
    formik.handleSubmit();
  };

  const handleStartedAtChange = (newDate: Dayjs | null) => {
    if (!newDate) {
      return;
    }

    if (newDate.isAfter(formik.values.endedAt)) {
      formik.setValues({
        ...formik.values,
        startedAt: newDate.toDate(),
        endedAt: newDate.toDate()
      });
    } else {
      formik.setValues({
        ...formik.values,
        startedAt: newDate.toDate()
      });
    }
  };

  const handleEndedAtChange = (newDate: Dayjs | null) => {
    if (!newDate) {
      return;
    }

    if (newDate.isBefore(formik.values.startedAt)) {
      formik.setValues({
        ...formik.values,
        startedAt: newDate.toDate(),
        endedAt: newDate.toDate()
      });
    } else {
      formik.setValues({
        ...formik.values,
        endedAt: newDate.toDate()
      });
    }
  };

  const handleDayChange = (day: Date) => {
    const startHour = dayjs(formik.values.startedAt).get('hours');
    const startMinute = dayjs(formik.values.startedAt).get('minutes');
    const endHour = dayjs(formik.values.endedAt).get('hours');
    const endMinute = dayjs(formik.values.endedAt).get('minutes');

    formik.setValues({
      ...formik.values,
      startedAt: dayjs(day).set('h', startHour).set('m', startMinute).toDate(),
      endedAt: dayjs(day).set('h', endHour).set('m', endMinute).toDate()
    });
    formik.handleSubmit();
  };

  let selectedProject: IProject;

  const handleProjectChange = (project: IProject) => {
    selectedProject = project;
  };

  const handleExpertiseChange = (expertise: string) => {
    formik.setValues({
      ...formik.values,
      project: selectedProject,
      title: undefined,
      expertise
    });

    formik.handleSubmit();
  };

  const handleTaskChange = (task: string) => {
    formik.setValues({
      ...formik.values,
      project: selectedProject,
      title: task,
      expertise: undefined
    });

    formik.handleSubmit();
  };

  return (
    <>
      <div className={classes.titleBlock}>
        {isBulkMode && (
          <Checkbox
            sx={{
              padding: 0
            }}
            checked={checked}
            id={id}
            onChange={handleCheckboxChange}
          />
        )}
        <span>
          <InputBase
            onBlur={handleBlur}
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
          />
        </span>
      </div>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2.2}
        alignItems="center"
      >
        <div className={classes.projectText}>
          <SelectProjectButton
            recordToEdit={record}
            onSelectProject={handleProjectChange}
            onSelectExpertise={handleExpertiseChange}
            onSelectTask={handleTaskChange}
            placement="left"
          />
        </div>

        <IconButton disabled>
          <CurrencyRuble
            fontSize="small"
            color={billable ? 'primary' : 'disabled'}
          />
        </IconButton>
        <div className={classes.timeGroup}>
          <div>
            <TrackerTimePicker
              value={formik.values.startedAt}
              onChange={handleStartedAtChange}
              onBlur={handleBlur}
            />
            <div>–</div>
            <TrackerTimePicker
              value={formik.values.endedAt}
              onChange={handleEndedAtChange}
              onBlur={handleBlur}
            />
          </div>
          <TrackerDatePicker
            selected={dayjs(formik.values.startedAt).toDate()}
            onSelect={handleDayChange}
          />
          <div className={classes.timeText}>
            {formatTime(totalTime, timeFormat)}
          </div>
        </div>
        <IconButton onClick={() => handleRepeatRecord()}>
          <PlayIcon />
        </IconButton>
        <IsolatedMenu
          functions={[
            {
              func: () => handleDuplicateRecord(),
              label: 'Duplicate'
            },
            {
              func: () => handleDeleteRecords(id),
              label: 'Delete'
            }
          ]}
        />
      </Stack>
    </>
  );
};

export default SingleRecord;
