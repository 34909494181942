import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';

// API
import { passwordRecovery } from 'api/sendLink';

// Icons
import Close from '@mui/icons-material/Close';

// Styles
import classes from './ForgotPassword.module.scss';

const EnterMail: FC = () => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Wrong email format')
      .required('Email field is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      secondPassword: ''
    },
    validationSchema,
    onSubmit: ({ email }) => {
      if (!email?.length) {
        return;
      }

      localStorage.setItem('emailSave', email);
      passwordRecovery(email);
    }
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="100%"
      direction="column"
      wrap="nowrap"
      className={classes.formWrapper}
    >
      <Helmet title="Login" />
      <Grid item className={classes.headForm}>
        <Typography component="div" align="center">
          <img src="favicon.svg" alt="" className={classes.logoIcon} />
          <Grid className={classes.head}>Log In</Grid>
        </Typography>
      </Grid>
      <Paper variant="elevation" elevation={2} className={classes.paper}>
        <Grid container spacing={1} className={classes.gridBox}>
          <Grid item xs={11}>
            <h2 className={classes.pageName}>Forgot your password?</h2>
          </Grid>
          <Grid item xs={1}>
            <IconButton href="/">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <Typography>
            Enter the email you registered with and we`ll send a link to reset
            the password
          </Typography>
        </Grid>
        <Grid item>
          <form onSubmit={formik.handleSubmit} className={classes.form}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  type="email"
                  fullWidth
                  name="email"
                  label={t('Email')}
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  autoFocus
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                />
                {formik.touched.email && formik.errors.email ? (
                  <Typography style={{ color: 'red', paddingTop: '10px' }}>
                    {formik.errors.email}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.submitBtn}
                >
                  {t("Let's go!")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default observer(EnterMail);
