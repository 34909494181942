import { action, makeObservable, observable, runInAction } from 'mobx';

// Types
import {
  IClockifyWorkspace,
  IWorkspaceSettings,
  IWorkspaceSettingsPatch
} from 'types/workspaceSettings';

// Api
import {
  getWorkspaceSettings,
  patchWorkspaceSettings,
  getClockifyWorkspaces
} from 'api/workspaceSettings';
import alertsStore from './alertsStore';

class WorkspaceSettingsStore {
  @observable
  workspaceSettings: IWorkspaceSettings | null = null;

  @observable
  clockifyWorkspaces: IClockifyWorkspace[] | null = null;

  @observable
  loading: boolean = false;

  @observable
  clockifyLoading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  getWorkspaceSettings = async () => {
    try {
      this.loading = true;

      const data = await getWorkspaceSettings();
      runInAction(() => {
        this.workspaceSettings = data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  updateWorkspaceSettings = async (workspacePatch: IWorkspaceSettingsPatch) => {
    try {
      this.loading = true;

      const data = await patchWorkspaceSettings(workspacePatch);
      runInAction(() => {
        this.workspaceSettings = data;
        alertsStore.createAlert('success', 'Settings updated.');
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  getClockifyWorkspaces = async () => {
    try {
      this.clockifyLoading = true;

      const data = await getClockifyWorkspaces();
      runInAction(() => {
        this.clockifyWorkspaces = data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.clockifyLoading = false;
      });
    }
  };
}

const workspaceSettingsStore = new WorkspaceSettingsStore();

export default workspaceSettingsStore;
