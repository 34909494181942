import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';

// Layouts
import Profile from 'layouts/ProfileLayout';

// Stores
import clientsStore from 'stores/ClientsStore';
import plannerStore from 'stores/PlannerStore';
import projectsStore from 'stores/ProjectsStore';

// Types
import {
  ColourCodeProjectVariant,
  ColourProjectVariant,
  ColourProjectOptions,
  IProject,
  PlannerStatus
} from 'types/project';

// Styles
import classes from './PlannerProjectModal.module.scss';

interface PlannerProjectModalProps {
  isOpen: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const PlannerProjectModal: FC<PlannerProjectModalProps> = ({
  isOpen,
  setOpen
}) => {
  const [clientName, setClientName] = useState<string>(' ');
  const [project, setProject] = useState<IProject | null>(null);
  const [color, setColor] = useState<string>('');
  const [colorCode, setColorCode] = useState<string>('');
  const [selectedField, setSelectedField] = useState<string>('');

  const { clients } = clientsStore;
  const { projects } = projectsStore;

  const projectsWithoutPlanner = useMemo(
    () =>
      projects.filter((item) => item.plannerStatus === PlannerStatus.hidden),
    [projects]
  );

  useEffect(() => {
    clientsStore.loadClients();
    // TODO: load project's client after the project has been chosen
    projectsStore.loadProjects();
  }, []);

  const addProject = useCallback((projectId: string, colourCode: string) => {
    plannerStore.addPlannerProject(
      projectId,
      colourCode as ColourCodeProjectVariant
    );
  }, []);

  const closeModal = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const colorChange = useCallback((event: { target: { value: string } }) => {
    setColor(event.target.value);
  }, []);

  const onItemClick = useCallback(
    (item: IProject) => {
      setProject(item);
      const found = clients.find((client) => client.id === item.clientId);

      if (found) {
        setClientName(found.legalName);
      }

      if (!item.colour) {
        return;
      }

      const colors = Object.entries(ColourCodeProjectVariant);
      const colorName = colors.find((colour) => colour[1] === item.colour);

      if (colorName) {
        setColor(
          ColourProjectVariant[
            colorName[0] as keyof typeof ColourProjectVariant
          ]
        );
        setColorCode(item.colour);
      }
    },
    [clients]
  );

  return (
    <Profile isOpen={isOpen} handleClose={closeModal}>
      <div className={classes.wrapper}>
        <div className={classes.headerContainer}>
          <div>Add project</div>
          <IconButton
            onClick={() => {
              closeModal();
            }}
          >
            <Close className={classes.close} />
          </IconButton>
        </div>
        <Grid container spacing={1} className={classes.gridBox}>
          <Grid item xs={3}>
            <div
              className={clsx(classes.name, {
                [classes.selectedName]: selectedField === 'projectName'
              })}
            >
              Project name
            </div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="standard-basic"
              variant="standard"
              select
              fullWidth
              color="primary"
              InputProps={{
                classes: {
                  root: classes.font
                }
              }}
              onFocus={() => setSelectedField('projectName')}
              onBlur={() => setSelectedField('')}
              className={classes.fullTextField}
              value={project?.name ?? ''}
            >
              {projectsWithoutPlanner.map((item) => (
                <MenuItem
                  key={item.id}
                  onClick={() => onItemClick(item)}
                  value={item.name}
                >
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.gridBox}>
          <Grid item xs={3}>
            <div
              className={clsx(classes.name, {
                [classes.selectedName]: selectedField === 'client'
              })}
            >
              Client
            </div>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="body1"
              className={classes.font}
              sx={{
                '&:after': {
                  content: '"\\00a0"'
                }
              }}
            >
              {clientName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.gridBox}>
          <Grid item xs={3}>
            <div
              className={clsx(classes.name, {
                [classes.selectedName]: selectedField === 'colorLabel'
              })}
            >
              Color label
            </div>
          </Grid>
          <Grid item xs={9}>
            <Select
              className={clsx(classes.font, {
                [classes.colorRed]: color === ColourProjectVariant.red,
                [classes.colorBlue]: color === ColourProjectVariant.blue,
                [classes.colorPurple]: color === ColourProjectVariant.purple,
                [classes.colorLightPurple]:
                  color === ColourProjectVariant.lightPurple,
                [classes.colorOrange]: color === ColourProjectVariant.orange,
                [classes.colorLightGreen]:
                  color === ColourProjectVariant.lightGreen,
                [classes.colorGreen]: color === ColourProjectVariant.green,
                [classes.colorIndigo]: color === ColourProjectVariant.indigo,
                [classes.colorYellow]: color === ColourProjectVariant.yellow
              })}
              fullWidth
              onFocus={() => setSelectedField('colorLabel')}
              onBlur={() => setSelectedField('')}
              value={color}
              onChange={colorChange}
              variant="standard"
            >
              {ColourProjectOptions.map((option) => (
                <MenuItem
                  onClick={() => setColorCode(option.code)}
                  value={option.value}
                  style={{ color: option.code }}
                >
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <div className={classes.buttonBox}>
          <Button
            className={classes.cancel}
            onClick={() => {
              closeModal();
            }}
          >
            CANCEL
          </Button>
          <Button
            className={classes.add}
            onClick={() => {
              closeModal();

              if (project) {
                addProject(project.id, colorCode);
              }
            }}
          >
            ADD
          </Button>
        </div>
      </div>
    </Profile>
  );
};

export default observer(PlannerProjectModal);
