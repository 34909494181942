import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';

// Stores
import authStore from 'stores/AuthStore';

// Icons
import Close from '@mui/icons-material/Close';

// Styles
import classes from './ForgotPassword.module.scss';

const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const [isEmail, setIsEmail] = useState<string>('');
  const emailStorage = localStorage.getItem('emailSave');
  const navigate = useNavigate();

  useEffect(() => {
    if (emailStorage) {
      setIsEmail(emailStorage);
    }
  }, []);

  const validationSchema = yup.object().shape({
    firstPassword: yup
      .string()
      .min(6, 'Password must contain at least 6 characters')
      .required('Password field is required'),
    secondPassword: yup
      .string()
      .min(6, 'Password must contain at least 6 characters')
      .required('Password field is required')
      .oneOf([yup.ref('firstPassword'), ''], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      firstPassword: '',
      secondPassword: ''
    },
    validationSchema,
    onSubmit: ({ firstPassword, secondPassword }, actions) => {
      if (!firstPassword?.length || !secondPassword.length) {
        return;
      }

      authStore
        .updatePasswordRecovery(isEmail, firstPassword, secondPassword)
        .finally(() => {
          formik.values.firstPassword = '';
          formik.values.secondPassword = '';
          actions.setSubmitting(false);
          navigate('/');
        });
    }
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="100%"
      direction="column"
      wrap="nowrap"
      className={classes.formWrapper}
    >
      <Helmet title="Login" />
      <Grid item className={classes.headForm}>
        <Typography component="div" align="center">
          <img src="favicon.svg" alt="" className={classes.logoIcon} />
          <Grid className={classes.head}>Log In</Grid>
        </Typography>
      </Grid>
      <Paper variant="elevation" elevation={2} className={classes.paper}>
        <Grid container spacing={1} className={classes.gridBox}>
          <Grid item xs={11}>
            <h2>Password recovery</h2>
          </Grid>
          <Grid item xs={1}>
            <IconButton href="/">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <Typography>
            Enter the email you registered with, new password and repeat it
          </Typography>
        </Grid>
        <Grid item>
          <form onSubmit={formik.handleSubmit} className={classes.form}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  type="password"
                  label={t('New password')}
                  fullWidth
                  name="firstPassword"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.firstPassword}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                />
                {formik.touched.firstPassword && formik.errors.firstPassword ? (
                  <Typography style={{ color: 'red', paddingTop: '10px' }}>
                    {formik.errors.firstPassword}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item>
                <TextField
                  type="password"
                  label="Confrim password"
                  fullWidth
                  name="secondPassword"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.secondPassword}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                />
                {formik.touched.secondPassword &&
                formik.errors.secondPassword ? (
                  <Typography style={{ color: 'red', paddingTop: '10px' }}>
                    {formik.errors.secondPassword}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.submitBtn}
                >
                  {t("Let's go!")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ForgotPassword;
