import React, { FC, useCallback } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

// Icons
import Close from '@mui/icons-material/Close';

// Layouts
import Profile from 'layouts/ProfileLayout';

// Styles
import classes from './GenerateReport.module.scss';

interface IProps {
  isOpen: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const GenerateReport: FC<IProps> = ({ isOpen, setOpen }) => {
  const handleClose = useCallback(() => {
    setOpen((prev) => !prev);
  }, [isOpen]);

  return (
    <Profile isOpen={isOpen}>
      <Box className={classes.nameBox}>
        <h2>Generate report</h2>
        <IconButton onClick={() => handleClose()}>
          <Close />
        </IconButton>
      </Box>
      <Button className={classes.button}>Export</Button>
      <Button className={classes.button}>Send by Email</Button>
    </Profile>
  );
};
export default GenerateReport;
