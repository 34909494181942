interface HidedPages {
  management: boolean;
  reports: boolean;
  planner: boolean;
  invoices: boolean;
  expences: boolean;
}

export interface RequiredForTask {
  project: boolean;
  task: boolean;
  tag: boolean;
  description: boolean;
}

export interface IWorkspaceSettings {
  id: string;
  companyName: string;
  companyLogo?: string;
  workDays: number[];
  isClockifyKeyExist: boolean;
  clockifyApiKey?: string;
  workspaceClockifyId?: string;
  defaultPrivacy: string;
  defaultBillability: string;
  durationFormat: string;
  isActivateBillableHours: boolean;
  isActivateFavorites: boolean;
  currency: string;
  numberFormat: string;
  currencyFormat: string;
  requiredForTask: RequiredForTask;
  timeRounding: number;
  canSeeHourlyRates: string;
  canChangeBillable: string;
  canCreateProjectsClients: string;
  canCreateTasks: string;
  timeVisibility: string;
  regularUserSee: string;
  hidedPages: HidedPages;
  canEditElsesTime: string;
  isLockTime: boolean;
  lockTimeBefore?: string;
  isLockTimeAutoUpdate: boolean;
  isForceTimer: boolean;
}

export interface IWorkspaceSettingsPatch {
  companyName?: string;
  companyLogo?: string;
  workDays?: number[];
  workspaceClockifyId?: string;
  clockifyApiKey?: string;
  defaultPrivacy?: string;
  defaultBillability?: string;
  durationFormat?: string;
  isActivateBillableHours?: boolean;
  isActivateFavorites?: boolean;
  currency?: string;
  numberFormat?: string;
  currencyFormat?: string;
  requiredForTask?: RequiredForTask;
  timeRounding?: number;
  canSeeHourlyRates?: string;
  canChangeBillable?: string;
  canCreateProjectsClients?: string;
  canCreateTasks?: string;
  timeVisibility?: string;
  regularUserSee?: string;
  hidedPages?: HidedPages;
  canEditElsesTime?: string;
  isLockTime?: boolean;
  lockTimeBefore?: string;
  isLockTimeAutoUpdate?: boolean;
  isForceTimer?: boolean;
}

export interface IClockifyWorkspace {
  id: string;
  name: string;
}

export enum AdminsAnyone {
  ADMINS = 'ADMINS',
  ANYONE = 'ANYONE'
}

export enum AdminsOrAdminsPmOrAnyone {
  ADMINS = 'ADMINS',
  ADMINSMANAGER = 'ADMINS AND MANAGER',
  ANYONE = 'ANYONE'
}

export enum AdminsOrAdminsAndPM {
  ADMINS = 'ADMINS',
  ADMINS_AND_PM = 'ADMINS_AND_PM'
}

export enum Billability {
  BILLABLE = 'BILLABLE',
  NON_BILLABLE = 'NON_BILLABLE'
}

export enum Privacy {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export enum TimeVisibility {
  ALL_USERS = 'ALL_USERS',
  ONLY_PUBLIC_PROJECTS = 'ONLY_PUBLIC_PROJECTS',
  ONLY_OWN = 'ONLY_OWN'
}

export enum DurationFormat {
  FULL = 'Full',
  COMPACT = 'Compact',
  DECIMAL = 'Decimal'
}

export enum NumberFormat {
  COMMA_COMMA = '3,456,78',
  COMMA_DOT = '3,456.78',
  APOSTROPHE_DOT = '3’456.78',
  APOSTROPHE_COMMA = '3’456,78'
}

export enum CurrencyFormat {
  CUR_NUMBER = 'RUB 123',
  NUMBER_CUR = '123 RUB',
  CURNUMBER = 'RUB123',
  NUMBERCUR = '123RUB'
}
