import React, { FC, PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';

// Components
import NewRecord from './NewRecord';
import RecordItemList from './RecordItemList';

// Styles
import classes from './TimeTracker.module.scss';

const TimeTrackerWrapper = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <div className={classes.wrapper}>
      <div className={classes.upperLevel}>{children}</div>
    </div>
  );
};

const TimeTracker: FC = () => {
  return (
    <TimeTrackerWrapper>
      <div className={classes.titleContainer}>
        <div className={classes.title}>Time Tracker</div>
      </div>
      <NewRecord />
      <RecordItemList />
    </TimeTrackerWrapper>
  );
};

export default observer(TimeTracker);
