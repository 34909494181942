import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Components
import ProjectAdminModal from 'components/modals/ProjectAdminModal';
import ProjectManagerModal from 'components/modals/ProjectManagerModal';

// Types
import { IProject } from 'types/project';
import { UserRole } from 'types/user';

// Stores
import profileStore from 'stores/ProfileStore';

// Styles
import classes from './PlannerProject.module.scss';

interface PlannerProjectProps {
  project: IProject;
  openViewProjects: boolean;
  setOpenViewProjects: (openViewProjects: boolean) => void;
}

const PlannerProject: FC<PlannerProjectProps> = ({
  project,
  openViewProjects,
  setOpenViewProjects
}) => {
  const { name } = project;
  const [isOpen, setIsOpen] = useState(false);

  // TODO: move modal logic to ProjectsTimeline
  const hasAdmin = [
    UserRole.ADMIN.toString(),
    UserRole.OWNER.toString()
  ].includes(profileStore.role);

  const handleClick = () => {
    setOpenViewProjects(!openViewProjects);
  };

  return (
    <div style={openViewProjects ? { marginBottom: '4px' } : {}}>
      <div className={classes.userItem}>
        <div className={classes.userInfo}>
          <span className={classes.name} style={{ color: project.colour }}>
            {name}
          </span>
        </div>
        <div className={classes.buttons}>
          <button
            type="button"
            className={classes.buttonDot}
            onClick={() => setIsOpen(!isOpen)}
          >
            <MoreVertIcon className={classes.dot} fontSize="inherit" />
          </button>
          <button
            type="button"
            className={classes.buttonArrow}
            onClick={handleClick}
            style={{
              transform: openViewProjects ? 'rotate(180deg)' : 'rotate(0)'
            }}
          >
            <KeyboardArrowDownIcon />
          </button>
        </div>
      </div>
      {hasAdmin ? (
        <ProjectAdminModal item={project} isOpen={isOpen} setOpen={setIsOpen} />
      ) : null}
      {!hasAdmin ? (
        <ProjectManagerModal
          item={project}
          isOpen={isOpen}
          setOpen={setIsOpen}
        />
      ) : null}
    </div>
  );
};

export default observer(PlannerProject);
