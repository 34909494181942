import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';

// Api
import { register } from 'api/register';

// Styles
import classes from './Registration.module.scss';

const Registration: FC = () => {
  const { search } = useLocation();
  const hash = new URLSearchParams(search).get('hash');
  const navigate = useNavigate();

  const reg = async (firstPassword: string) => {
    if (!hash) return null;

    try {
      const { data } = await register(firstPassword, hash);
      navigate('/');

      return data;
    } catch (error) {
      console.warn(error);

      return null;
    }
  };

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(6, 'Password must contain at least 6 characters')
      .required('Password field is required'),
    secondPassword: yup
      .string()
      .min(6, 'Password must contain at least 6 characters')
      .required('Password field is required')
      .oneOf([yup.ref('password'), ''], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      secondPassword: ''
    },
    validationSchema,
    onSubmit: ({ password, secondPassword }) => {
      if (
        !password?.length ||
        !secondPassword?.length ||
        password !== secondPassword
      ) {
        return;
      }

      reg(password);
    }
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="100%"
      direction="column"
      wrap="nowrap"
      className={classes.formWrapper}
    >
      <Helmet title="Registration" />
      <Grid item className={classes.headForm}>
        <Typography component="div" align="center">
          <img src="favicon.svg" alt="" className={classes.logoIcon} />
          <Grid className={classes.head}>Sign Up</Grid>
        </Typography>
      </Grid>
      <Paper variant="elevation" elevation={2} className={classes.paper}>
        <Grid item>
          <form onSubmit={formik.handleSubmit} className={classes.form}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  type="password"
                  fullWidth
                  name="password"
                  label="New Password"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  helperText
                  autoFocus
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                />
                {formik.touched.password && formik.errors.password ? (
                  <Typography>
                    <p style={{ color: 'red', paddingTop: '10px' }}>
                      {formik.errors.password}
                    </p>
                  </Typography>
                ) : null}
              </Grid>
              <Grid item>
                <TextField
                  type="password"
                  label="Repeat Password"
                  fullWidth
                  name="secondPassword"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.secondPassword}
                  helperText
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                />
                {formik.touched.secondPassword &&
                formik.errors.secondPassword ? (
                  <Typography>
                    <p style={{ color: 'red', paddingTop: '10px' }}>
                      {formik.errors.secondPassword}
                    </p>
                  </Typography>
                ) : null}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.submitBtn}
                >
                  {t("Let's go!")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Registration;
