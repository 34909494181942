import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';

// Components
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import BlockWrapper from 'components/BlockWrapper';
import TrackerDatePicker from 'components/TrackerDatePicker';

// Types
import {
  WorkspaceSettingsNames as Names,
  WorkspaceBlockProps
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import { IWorkspaceSettingsPatch } from 'types/workspaceSettings';

// Styles
import Typography from '@mui/material/Typography';
import classes from './LockTime.module.scss';

const LockTime: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, handleChange, setFieldValue } =
    useFormikContext<IWorkspaceSettingsPatch>();

  const handleLockTimeChange = (day: Date | null) => {
    if (day) {
      setFieldValue(Names.lockTimeBefore, day.toISOString());
    }
  };

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Lock time</span>
            <span className={classes.description}>
              Prevent regular users from editing their past time or adding new
              entries to past dates.
            </span>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.column}>
            <Box className={classes.inputContainer}>
              <Box className={classes.wrapper}>
                <FormGroup>
                  <Box className={classes.row}>
                    <FormControlLabel
                      control={
                        <Switch
                          name={Names.isLockTime}
                          value={values.isLockTime}
                          checked={values.isLockTime}
                          onChange={(e) => {
                            handleChange(e);
                            onChange();
                          }}
                        />
                      }
                      label="Lock time before "
                    />
                    <Box
                      className={
                        !values.isLockTime ? classes.hiddenDate : classes.date
                      }
                    >
                      <TrackerDatePicker
                        selected={new Date(values.lockTimeBefore as string)}
                        onSelect={handleLockTimeChange}
                      />
                      <Typography>
                        {dayjs(values.lockTimeBefore).format('YYYY/MM/DD')}
                      </Typography>
                    </Box>
                  </Box>
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name={Names.isLockTimeAutoUpdate}
                        value={values.isLockTimeAutoUpdate}
                        checked={values.isLockTimeAutoUpdate}
                        onChange={(e) => {
                          handleChange(e);
                          onChange();
                        }}
                      />
                    }
                    label="Automatically update lock date "
                  />
                </FormGroup>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default LockTime;
