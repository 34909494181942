import React, { FC, useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { v4 } from 'uuid';

// Components
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Paper, { PaperProps } from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton';

// Components
import AddTaskModal from 'components/modals/AddTaskModal';
import AddProjectModal from 'components/modals/AddProjectModal';
import SetNewBillableRateModal from 'components/modals/SetNewBillableRateModal';

// Icons
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ProjectArrowIcon } from 'assets/icons/projectArrow.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen2.svg';
import ExpandMore from '@mui/icons-material/ExpandMore';

// Stores
import timetrackerStore from 'stores/TimeTrackerStore';
import profileStore from 'stores/ProfileStore';
import expertiseStore from 'stores/ExpertiseStore';

// Types
import {
  IProjectWithTasks,
  ITask,
  ITimeTrackerRecord
} from 'types/timetrackerRecord';
import { UserRole } from 'types/user';
import { IProject } from 'types/project';

// Styles
import classes from './SelectProjectButton.module.scss';

const PaperComponent = (props: PaperProps) => {
  const { children, id } = props;

  const handleClick = () => {
    if (id) {
      const event = new Event(`clicked-${id}`);
      document.dispatchEvent(event);
    }
  };

  return (
    <Paper {...props}>
      {children}
      <Box className={classes.bottomBox}>
        {profileStore.role !== UserRole.USER.toString() && (
          <Button onClick={handleClick}>
            <PlusIcon />
            <span>Create new project</span>
          </Button>
        )}
      </Box>
    </Paper>
  );
};

interface IProps {
  recordToEdit?: ITimeTrackerRecord;
  onSelectProject?: (project: IProject) => void;
  onSelectTask?: (task: string) => void;
  onSelectExpertise?: (expertise: string) => void;
  placement?: 'left' | 'bottom-end' | 'bottom-start';
}

const SelectProjectButton: FC<IProps> = ({
  recordToEdit,
  onSelectProject,
  onSelectTask,
  onSelectExpertise,
  placement = 'bottom-end'
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isProjModalOpen, setIsProjModalOpen] = useState<boolean>(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState<boolean>(false);
  const [isExpertiseModalOpen, setIsExpertiseModalOpen] =
    useState<boolean>(false);
  const [isEditTask, setIsEditTask] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [accordionExpanded, setAccordionExpanded] = useState<string>('');

  let project: IProject | undefined;
  let title: string | undefined;
  let expertise: string | undefined;

  if (recordToEdit) {
    project = recordToEdit.project;
    title = recordToEdit.title;
    expertise = recordToEdit.expertise;
  } else {
    project = timetrackerStore.currentRecord.project;
    title = timetrackerStore.currentRecord.title;
    expertise = timetrackerStore.currentRecord.expertise;
  }

  const { projectTasks } = timetrackerStore;
  const { expertises } = expertiseStore;

  const openProjModal = () => {
    setIsProjModalOpen(true);
  };

  const paperId = useMemo(() => v4(), []);

  useEffect(() => {
    document.addEventListener(`clicked-${paperId}`, openProjModal);

    return () => {
      document.removeEventListener(`clicked-${paperId}`, openProjModal);
    };
  }, []);

  const handleExpandAccordion = (accordion: string) => {
    if (accordion !== accordionExpanded) {
      setAccordionExpanded(accordion);
    } else {
      setAccordionExpanded('');
    }
  };

  const openTaskAddModal = () => {
    timetrackerStore.changeTaskInModal('');
    setIsEditTask(false);
    setIsTaskModalOpen(true);
  };

  const openTaskEditModal = (task: ITask) => {
    timetrackerStore.changeTaskInModal(task);
    setIsEditTask(true);
    setIsTaskModalOpen(true);
  };

  const openExpertiseModal = () => {
    expertiseStore.loadExpertises(project?.id as string).then(() => {
      setIsExpertiseModalOpen(true);
    });
  };

  const onExpertisesUpdate = () => {
    timetrackerStore.getProjectTasks();
    timetrackerStore.getRecords();
    timetrackerStore.changeCurrentRecordTitle('');
    timetrackerStore.changeCurrentRecordExpertise('');
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
      setIsOpen(false);
    } else {
      setAnchorEl(e.currentTarget);
      setTimeout(() => {
        setIsOpen(true);
      }, 50);
    }
  };

  const handleSelectProject = (
    e: React.SyntheticEvent,
    value: IProjectWithTasks | null
  ) => {
    if (onSelectProject) {
      onSelectProject(value?.project as IProject);

      return;
    }

    if (value) {
      timetrackerStore.changeCurrentRecordProject(value);
      timetrackerStore.changeCurrentRecordTitle('');
    }
  };

  const isUser = (): boolean => {
    return profileStore.role === UserRole.USER.toString();
  };

  const handleSelectExpertise = (
    expertiseId: string,
    expertiseName: string,
    proj: IProjectWithTasks
  ) => {
    setIsOpen(false);
    setAnchorEl(null);

    if (onSelectExpertise && onSelectProject) {
      onSelectProject(proj.project);
      onSelectExpertise(expertiseName);

      return;
    }

    if (expertiseId && expertiseName && proj) {
      timetrackerStore.changeCurrentRecordProject(proj);
      timetrackerStore.changeCurrentRecordExpertise(expertiseId);
      timetrackerStore.changeCurrentRecordTitle(expertiseName);
      timetrackerStore.changeCurrentRecordBillable(true);
    }
  };

  const handleSelectTask = (newTitle: string, proj: IProjectWithTasks) => {
    setIsOpen(false);
    setAnchorEl(null);

    if (onSelectTask && onSelectProject) {
      onSelectProject(proj.project);
      onSelectTask(newTitle);

      return;
    }

    if (newTitle && proj) {
      timetrackerStore.changeCurrentRecordProject(proj);
      timetrackerStore.changeCurrentRecordExpertise('');
      timetrackerStore.changeCurrentRecordTitle(newTitle);
      timetrackerStore.changeCurrentRecordBillable(false);
    }
  };

  return (
    <>
      <Button
        className={clsx({
          [classes.projectButton]: !recordToEdit,
          [classes.projectButtonEdit]: recordToEdit,
          [classes.buttonActive]: anchorEl && recordToEdit
        })}
        onClick={handleButtonClick}
      >
        {project ? (
          <>
            <ProjectArrowIcon fill={project?.colour} />
            <span style={{ color: project?.colour }}>
              {project?.name} {title && `: ${title}`}
              {recordToEdit && expertise && `: ${expertise}`}
            </span>
          </>
        ) : (
          <>
            <PlusIcon />
            <span>Task @Project</span>
          </>
        )}
      </Button>
      <ClickAwayListener
        onClickAway={() => {
          if (
            isOpen &&
            !isExpertiseModalOpen &&
            !isTaskModalOpen &&
            !isProjModalOpen
          ) {
            setAnchorEl(null);
            setIsOpen(false);
          }
        }}
      >
        <Popper
          className={classes.wrapper}
          open={isOpen}
          anchorEl={anchorEl}
          placement={placement}
        >
          <Autocomplete
            className={classes.autocomplete}
            open
            disablePortal
            PaperComponent={PaperComponent}
            componentsProps={{
              paper: {
                id: paperId
              }
            }}
            onChange={handleSelectProject}
            noOptionsText="No projects found"
            getOptionLabel={(option) => option.project.name}
            clearOnBlur
            renderInput={(params) => (
              <InputBase
                className={classes.input}
                inputProps={params.inputProps}
                ref={params.InputProps.ref}
                placeholder="Find project or clients"
              />
            )}
            options={projectTasks}
            renderOption={(props, option) => (
              <li
                {...props}
                className={classes.projectItem}
                style={{
                  color: option.project.colour,
                  listStyleType: 'none'
                }}
              >
                <Accordion
                  square
                  disableGutters
                  className={classes.accordion}
                  sx={{
                    '.MuiAccordionDetails-root': {
                      padding: 0
                    }
                  }}
                  expanded={accordionExpanded === option.project.id}
                  onChange={() => handleExpandAccordion(option.project.id)}
                >
                  <AccordionSummary
                    expandIcon={
                      (option.expertises.length !== 0 ||
                        option.tasks.length !== 0) && <ExpandMore />
                    }
                  >
                    <Grid container alignItems="center" justifyContent="left">
                      <ProjectArrowIcon
                        fill={option.project.colour}
                        style={{ marginRight: 10 }}
                      />
                      <span style={{ color: option.project.colour }}>
                        {option.project.name}
                      </span>
                      {!isUser() && (
                        <Button
                          style={{
                            marginLeft: 'auto',
                            display:
                              option.expertises.length === 0 &&
                              option.tasks.length === 0
                                ? 'initial'
                                : 'none'
                          }}
                          size="small"
                          onClick={openTaskAddModal}
                        >
                          Create task
                        </Button>
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      display:
                        option.expertises.length === 0 &&
                        option.tasks.length === 0
                          ? 'none'
                          : 'initial'
                    }}
                    className={classes.accordionDetails}
                  >
                    <Grid container direction="column" alignItems="stretch">
                      {option.expertises.map((exp) => {
                        const { expertiseId, expertiseName } = exp;

                        return (
                          <Button
                            className={classes.taskButton}
                            onClick={(e) => {
                              e.stopPropagation();

                              if (accordionExpanded === option.project.id) {
                                handleSelectExpertise(
                                  expertiseId,
                                  expertiseName,
                                  option
                                );
                              }
                            }}
                          >
                            {expertiseName}
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                openExpertiseModal();
                              }}
                            >
                              <PenIcon />
                            </IconButton>
                          </Button>
                        );
                      })}
                      {option.tasks.map((task) => (
                        <Button
                          className={classes.taskButton}
                          onClick={(e) => {
                            e.stopPropagation();

                            if (accordionExpanded === option.project.id) {
                              handleSelectTask(task.title, option);
                            }
                          }}
                        >
                          {task.title}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              openTaskEditModal(task);
                            }}
                          >
                            <PenIcon />
                          </IconButton>
                        </Button>
                      ))}
                      {!isUser() && (
                        <Button onClick={openTaskAddModal}>Create task</Button>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </li>
            )}
          />
        </Popper>
      </ClickAwayListener>
      <AddTaskModal
        isOpen={isTaskModalOpen}
        setOpen={setIsTaskModalOpen}
        isEditMode={isEditTask}
        project={project as IProject}
      />
      <AddProjectModal
        isOpen={isProjModalOpen}
        // setOpen={timetrackerStore.setIsProjectModalOpen}
        setOpen={setIsProjModalOpen}
      />
      <SetNewBillableRateModal
        project={project as IProject}
        item={expertises.find((exp) => exp.id === expertise)}
        isOpen={isExpertiseModalOpen}
        setOpen={setIsExpertiseModalOpen}
        checked={false}
        onExpertisesUpdate={onExpertisesUpdate}
      />
    </>
  );
};

export default observer(SelectProjectButton);
