import React, { FC, Suspense } from 'react';
import { Route, Routes as CRoutes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

// Components
import Loading from 'components/Loading';

// Layouts
import { LoggedLayout } from 'layouts/LoggedLayout';

// Stores
import authStore from 'stores/AuthStore';

// Routes
import Login from 'routes/Login';
import Registration from './Registration';
import NewPlanner from './NewPlanner';
import TimeTracker from './TimeTracker';
import ProfileSettings from './ProfileSettings';
import WorkspaceSettings from './WorkspaceSettings';
import Timesheets from './Timesheets';
import ForgotPassword from './Login/ForgotPassword';
import EnterMail from './Login/ForgotPassword/EnterMail';

const Clients = React.lazy(() => import('routes/Clients'));
const Projects = React.lazy(() => import('routes/Projects'));
const ProfitForecasted = React.lazy(() => import('./Reports/ProfitForecasted'));
const ProfitGenerated = React.lazy(() => import('./Reports/ProfitGenerated'));
const PlannedVSFact = React.lazy(() => import('./Reports/PlannedVSFact'));
const Team = React.lazy(() => import('routes/Team'));

const Routes: FC = () => {
  const { isChecking, isLogin } = authStore;

  if (isChecking) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <CRoutes>
        {isLogin ? (
          <Route path="/" element={<LoggedLayout />}>
            <Route index element={<NewPlanner />} />
            <Route path="team" element={<Team />} />
            <Route path="projects" element={<Projects />} />
            <Route path="clients" element={<Clients />} />
            <Route
              path="reports/profit_forecasted"
              element={<ProfitForecasted />}
            />
            <Route
              path="reports/profit_generated"
              element={<ProfitGenerated />}
            />
            <Route
              path="reports/planned_fact_profit"
              element={<PlannedVSFact hours={false} />}
            />
            <Route
              path="reports/planned_fact_hours"
              element={<PlannedVSFact hours />}
            />
            <Route path="timetracker" element={<TimeTracker />} />
            <Route path="sheets" element={<Timesheets />} />
            <Route path="profile-settings" element={<ProfileSettings />} />
            <Route path="settings" element={<WorkspaceSettings />} />
          </Route>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="forgot-mail" element={<EnterMail />} />
            <Route path="recovery-password" element={<ForgotPassword />} />
            <Route path="register" element={<Registration />} />
          </>
        )}
      </CRoutes>
    </Suspense>
  );
};

export default observer(Routes);
