import React, { FC } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import BlockWrapper from 'components/BlockWrapper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Types
import {
  WorkspaceSettingsNames as Names,
  WorkspaceBlockProps
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import {
  IWorkspaceSettingsPatch,
  DurationFormat as DurationFormatEnum
} from 'types/workspaceSettings';

// Styles
import classes from './DurationFormat.module.scss';

const formatExamples = [' (hh:mm:ss)', ' (h:mm)', ' (h.mm)'];

const DurationFormat: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, handleChange } = useFormikContext<IWorkspaceSettingsPatch>();

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Duration format</span>
            <span className={classes.description}>
              Display time in clock format (with or without seconds).
            </span>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.column}>
            <Box className={classes.inputContainer}>
              <Box className={classes.wrapper}>
                <Select
                  name={Names.durationFormat}
                  value={values.durationFormat}
                  defaultValue={values.durationFormat}
                  variant="standard"
                  className={classes.input}
                  fullWidth
                  onChange={(e) => {
                    handleChange(e);
                    onChange();
                  }}
                >
                  {Object.values(DurationFormatEnum).map((value, key) => {
                    return (
                      <MenuItem value={value}>
                        {value + formatExamples[key]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default DurationFormat;
