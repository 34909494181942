import React, {
  FC,
  useState,
  ChangeEvent,
  useEffect,
  useCallback
} from 'react';
import { observer } from 'mobx-react-lite';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import NativeSelect from '@mui/material/NativeSelect';
import Switch from '@mui/material/Switch';
import Fade from '@mui/material/Fade';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';

// Icons
import Close from '@mui/icons-material/Close';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// Layouts
import Profile from 'layouts/ProfileLayout';

// Types
import {
  ContractStatusVariant,
  IProject,
  WorkStatusVariant,
  ColourProjectOptions,
  ColourType
} from 'types/project';
import { IClient } from 'types/client';
import { ContractCurrencyVariant } from 'types/rate';

// Components
import SetNewBillableRate from 'components/modals/SetNewBillableRateModal';
import DatePicker from 'components/DatePicker';
import ListBillableRateUSD from 'components/ListBillableRateModalUSD';

// Stores
import rateStore from 'stores/RateStore';
import projectsStore from 'stores/ProjectsStore';
import clientsStore from 'stores/ClientsStore';
import membersStore from 'stores/MembersStore';

// Styles
import classes from './ProjectAdminModal.module.scss';

interface IProps {
  item: IProject;
  isOpen: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

enum Names {
  name = 'name',
  startAt = 'startAt',
  endAt = 'endAt',
  billableStatus = 'billableStatus',
  clientId = 'clientId',
  manager = 'manager',
  contractStatus = 'contractStatus',
  status = 'status',
  currency = 'currency',
  archivedLink = 'archivedLink',
  colour = 'colour'
}

const validationSchema = yup.object({
  [Names.name]: yup.string(),
  [Names.startAt]: yup.object(),
  [Names.endAt]: yup.object(),
  [Names.billableStatus]: yup.string(),
  [Names.clientId]: yup.string(),
  [Names.manager]: yup.string(),
  [Names.contractStatus]: yup.string(),
  [Names.status]: yup.string(),
  [Names.currency]: yup.string(),
  [Names.archivedLink]: yup.string().nullable(),
  [Names.colour]: yup.object()
});

const getClientName = (
  clients: IClient[],
  clientId: string
): string | undefined => {
  return clients.find((client) => clientId === client.id)?.legalName;
};

const getClientId = (clients: IClient[], name: string): string | undefined => {
  return clients.find((client) => name === client.legalName)?.id;
};
const ProjectAdmin: FC<IProps> = ({ item, isOpen, setOpen }) => {
  const handleClose = useCallback(() => {
    setOpen((prev) => !prev);
  }, [isOpen]);

  const formik = useFormik({
    initialValues: {
      [Names.name]: item.name,
      [Names.startAt]: dayjs(item.startAt),
      [Names.endAt]: dayjs(item.endAt),
      [Names.billableStatus]: false,
      [Names.clientId]: item.clientId,
      [Names.manager]: item.manager,
      [Names.contractStatus]: item.contractStatus,
      [Names.status]: item.status,
      [Names.currency]: item.currency,
      [Names.archivedLink]: item.archivedLink,
      [Names.colour]: ColourProjectOptions.find(
        (col) => col.code === item.colour
      )
    },
    validationSchema,
    onSubmit: (values) => {
      projectsStore.updateProjects(item.id, {
        [Names.name]: values.name,
        [Names.startAt]: values.startAt.toISOString(),
        [Names.endAt]: values.endAt.toISOString(),
        [Names.billableStatus]: values.billableStatus,
        [Names.clientId]: values.clientId,
        [Names.manager]: values.manager,
        [Names.contractStatus]: values.contractStatus,
        [Names.status]: values.status,
        [Names.currency]: values.currency,
        [Names.archivedLink]: values.archivedLink,
        [Names.colour]: values.colour?.code
      });
      handleClose();
    }
  });

  const handleUpdate = useCallback(() => {
    formik.setValues({
      [Names.name]: item.name,
      [Names.startAt]: dayjs(item.startAt),
      [Names.endAt]: dayjs(item.endAt),
      [Names.billableStatus]: true,
      [Names.clientId]: item.clientId,
      [Names.manager]: item.manager,
      [Names.contractStatus]: item.contractStatus,
      [Names.status]: item.status,
      [Names.currency]: item.currency,
      [Names.archivedLink]: item.archivedLink,
      [Names.colour]: ColourProjectOptions.find(
        (col) => col.code === item.colour
      )
    });
  }, [item]);

  const { members } = membersStore;
  const { clients } = clientsStore;

  const [colour, setColour] = useState<ColourType | undefined>(
    ColourProjectOptions.find((col) => col.code === item.colour)
  );
  const [clientLegalName, setClientLegalName] = useState<string | undefined>(
    getClientName(clients, item.clientId)
  );
  const [onSetClick, setOnSetClick] = useState<boolean>(false);
  const [buttonContractStatus, setButtonContractStatus] =
    useState<boolean>(true);

  const lastCustomStatus = item.contractStatus as string;

  useEffect(() => {
    if (isOpen) {
      handleUpdate();
    }
  }, [isOpen, item]);

  useEffect(() => {
    projectsStore.loadProjects();
    clientsStore.loadClients();
    membersStore.loadMembers();
  }, []);

  const clientNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setClientLegalName(e.target.value);
    formik.values.clientId = getClientId(clients, e.target.value) as string;
  }, []);

  const colorChange = (e: { target: { value: string } }) => {
    setColour(ColourProjectOptions.find((col) => col.value === e.target.value));
    formik.values.colour = ColourProjectOptions.find(
      (col) => col.value === e.target.value
    );
  };

  const startAtChange = useCallback((changeEventDate: Dayjs | null) => {
    if (changeEventDate) {
      if (
        changeEventDate.isBefore(formik.values.endAt) ||
        !formik.values.endAt.isValid()
      ) {
        formik.values.startAt = changeEventDate;
        formik.setFieldValue(Names.startAt, changeEventDate);
      }
    }
  }, []);

  const endAtChange = useCallback((changeEventDate: Dayjs | null) => {
    if (changeEventDate) {
      if (
        changeEventDate.isAfter(formik.values.startAt) ||
        !formik.values.startAt.isValid()
      ) {
        formik.values.endAt = changeEventDate;
        formik.setFieldValue(Names.endAt, changeEventDate);
      }
    }
  }, []);

  const billableChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
  }, []);

  const handleOnClick = useCallback((cIsButtonClick: boolean) => {
    setOnSetClick(!cIsButtonClick);
  }, []);

  return (
    <Profile isOpen={isOpen} handleClose={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} className={classes.gridBox}>
          <Grid item xs={11}>
            <h2 className={classes.pageName}>Project settings</h2>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => handleClose()}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Box className={classes.textFieldBox}>
          <Grid container spacing={2} className={classes.gridBox}>
            <Grid item xs={3}>
              <Typography className={classes.font}>Project name</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                variant="standard"
                name={Names.name}
                fullWidth
                autoFocus
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                InputProps={{
                  classes: {
                    root: classes.font
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.gridBox}>
            <Grid item xs={3}>
              <Typography className={classes.font}>Client</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                variant="standard"
                name={Names.clientId}
                select
                color="primary"
                fullWidth
                InputProps={{
                  classes: {
                    root: classes.font
                  }
                }}
                onChange={clientNameChange}
                value={clientLegalName}
              >
                {clients.map((option) => (
                  <MenuItem key={option.id} value={option.legalName}>
                    {option.legalName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.gridBox}>
            <Grid item xs={3}>
              <Typography className={classes.font}>Project manager</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="standard-basic"
                name={Names.manager}
                variant="standard"
                select
                color="primary"
                fullWidth
                required
                InputProps={{
                  classes: {
                    root: classes.font
                  }
                }}
                onChange={formik.handleChange}
                value={formik.values.manager}
              >
                {members
                  .filter((member) => member.role === 'MANAGER')
                  .map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.gridBox}>
            <Grid item xs={3}>
              <Typography className={classes.font}>Status</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                name={Names.status}
                variant="standard"
                select
                color="primary"
                fullWidth
                InputProps={{
                  classes: {
                    root: classes.font
                  }
                }}
                onChange={formik.handleChange}
                value={formik.values.status}
              >
                {Object.values(WorkStatusVariant).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.font}>Contract status</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                name={Names.contractStatus}
                variant="standard"
                select={buttonContractStatus}
                color="primary"
                fullWidth
                required
                InputProps={{
                  classes: {
                    root: classes.font
                  }
                }}
                onChange={formik.handleChange}
                value={formik.values.contractStatus}
              >
                <MenuItem onClick={() => setButtonContractStatus(false)}>
                  <AddCircleOutlineIcon />
                  Add new contract status
                </MenuItem>
                {!Object.keys(ContractStatusVariant).includes(
                  lastCustomStatus
                ) ? (
                  <MenuItem key={lastCustomStatus} value={lastCustomStatus}>
                    {lastCustomStatus}
                  </MenuItem>
                ) : null}
                {Object.values(ContractStatusVariant).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.gridBox}>
            <Grid item xs={3}>
              <Typography className={classes.font}>Color label</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="standard-basic"
                name={Names.colour}
                variant="standard"
                select
                color="primary"
                fullWidth
                InputProps={{
                  classes: {
                    root: classes.font
                  }
                }}
                onChange={colorChange}
                value={colour?.value}
              >
                {ColourProjectOptions.map((col) => {
                  return (
                    <MenuItem
                      key={col.code}
                      value={col.value}
                      style={{ color: col.code }}
                    >
                      {col.value}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.gridBox}>
            <Grid item xs={3}>
              <Typography className={classes.font}>
                Link to legal archive
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                variant="standard"
                name={Names.archivedLink}
                onChange={formik.handleChange}
                value={formik.values.archivedLink}
                fullWidth
                autoFocus
                InputProps={{
                  classes: {
                    root: classes.font
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.gridBox}>
            <Grid item xs={3}>
              <Typography className={classes.font}>Start date</Typography>
            </Grid>
            <Grid item xs={9}>
              <DatePicker
                date={formik.values.startAt}
                onChange={startAtChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.font}>End date</Typography>
            </Grid>
            <Grid item xs={9}>
              <DatePicker date={formik.values.endAt} onChange={endAtChange} />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.gridBox}>
            <Grid item xs={3}>
              <Typography className={classes.font}>Billable rate</Typography>
            </Grid>
            <Grid item xs={3}>
              <Switch
                name={Names.billableStatus}
                checked={formik.values.billableStatus}
                onChange={billableChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Fade in={formik.values.billableStatus}>
                <Grid container spacing={1} className={classes.gridBox}>
                  <Grid item xs={7}>
                    <Typography className={classes.font}>
                      Contract currency
                    </Typography>
                  </Grid>

                  <Grid item xs={5}>
                    <NativeSelect
                      className={classes.font}
                      name={Names.currency}
                      value={formik.values.currency}
                      onChange={formik.handleChange}
                    >
                      <option value={ContractCurrencyVariant.rub}>
                        Rubles
                      </option>
                      <option value={ContractCurrencyVariant.usd}>USD</option>
                    </NativeSelect>
                  </Grid>
                </Grid>
              </Fade>
            </Grid>
          </Grid>

          <Collapse in={formik.values.billableStatus}>
            <Grid container className={classes.gridBox}>
              <Grid item xs={12}>
                <Typography className={classes.font}>
                  Project billable rate
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ListBillableRateUSD
                  project={item}
                  checked={
                    formik.values.currency === ContractCurrencyVariant.usd
                  }
                />
              </Grid>
            </Grid>
          </Collapse>

          <Collapse in={formik.values.billableStatus}>
            <Grid container spacing={1} className={classes.gridBox}>
              <Grid item xs={3}>
                <Chip label="n/a" className={classes.chip} variant="filled" />
              </Grid>

              {formik.values.currency === ContractCurrencyVariant.usd && (
                <Grid item xs={2}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyIcon />
                        </InputAdornment>
                      ),
                      classes: {
                        root: classes.font
                      },
                      disableUnderline: true
                    }}
                    variant="standard"
                    value={0}
                    disabled
                  />
                </Grid>
              )}

              <Grid item xs={2}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRubleIcon />
                      </InputAdornment>
                    ),
                    classes: {
                      root: classes.font
                    },
                    disableUnderline: true
                  }}
                  disabled
                  value={0}
                  variant="standard"
                />
              </Grid>

              {formik.values.currency === ContractCurrencyVariant.usd && (
                <Grid item xs={2}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyExchangeIcon />
                        </InputAdornment>
                      ),
                      classes: {
                        root: classes.font
                      },
                      disableUnderline: true
                    }}
                    variant="standard"
                    value={rateStore.rate.toFixed(2)}
                    disabled
                  />
                </Grid>
              )}

              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  className={classes.setButton}
                  onClick={() => {
                    handleOnClick(onSetClick);
                  }}
                >
                  SET
                </Button>
                <SetNewBillableRate
                  project={item}
                  isOpen={onSetClick}
                  setOpen={setOnSetClick}
                  checked={
                    formik.values.currency === ContractCurrencyVariant.usd
                  }
                />
              </Grid>
            </Grid>
          </Collapse>

          <Box className={classes.buttonBox}>
            <Button className={classes.cancel} onClick={() => handleClose()}>
              CANCEL
            </Button>
            <Button className={classes.save} type="submit">
              SAVE
            </Button>
          </Box>
        </Box>
      </form>
    </Profile>
  );
};
export default observer(ProjectAdmin);
