import axios from 'api/helpers/axios';

// Types
import {
  ITimeTrackerClockifyRecord,
  ITimeTrackerRecord
} from 'types/timetrackerRecord';
import { ITimeTrackerTasksResponse } from './timetracker';

export interface ITimeTrackerClockifyRecordsResponse {
  data: ITimeTrackerClockifyRecord[];
}

export const getTimesheetRecords = (
  from: Date,
  to: Date
): Promise<ITimeTrackerRecord[]> => {
  return axios
    .get<ITimeTrackerTasksResponse>(
      `/timesheets?from=${from.toISOString()}&to=${to.toISOString()}`
    )
    .then((response) => {
      return response.data.data;
    });
};

export const getTimesheetClockifyRecords = (
  from: Date,
  to: Date
): Promise<ITimeTrackerClockifyRecord[]> => {
  return axios
    .get<ITimeTrackerClockifyRecordsResponse>(
      `/timesheets/clockify?from=${from.toISOString()}&to=${to.toISOString()}`
    )
    .then((response) => {
      return response.data.data;
    });
};
