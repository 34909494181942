import React, { FC, useState, useCallback } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import moment from 'moment';

import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth
} from 'date-fns';

// Components
import { DateRange, DayPicker } from 'react-day-picker';

// Styles
import styles from './DateRangePicker.module.scss';

interface IProps {
  isOpen: boolean;
  onClose: (val: boolean) => void;
  firstDate: Date;
  onFirstDateChange: (date: Date) => void;
  secondDate: Date;
  onSecondDateChange: (date: Date) => void;
  rangeType: string;
  onRangeTypeChange: (str: string) => void;
  datesRange: DateRange;
  onDatesRangeChange: (r: DateRange) => void;
}

enum RangeType {
  ThisWeek = 'ThisWeek',
  LastWeek = 'LastWeek',
  ThisMonth = 'ThisMonth',
  Last3Months = 'Last3Months',
  Last6Months = 'Last6Months',
  Next3Months = 'Next3Months',
  Next6Months = 'Next6Months'
}

const DateRangePicker: FC<IProps> = ({
  isOpen,
  onClose,
  firstDate,
  onFirstDateChange,
  secondDate,
  onSecondDateChange,
  rangeType,
  onRangeTypeChange,
  datesRange,
  onDatesRangeChange
}) => {
  const [range, setRange] = useState<DateRange>(datesRange);
  const [startDate, setStartDate] = useState<Date>(startOfMonth(firstDate));
  const [endDate, setEndDate] = useState<Date>(endOfMonth(secondDate));
  const [activeRange, setActiveRange] = useState<string>(rangeType);

  const handleChange = useCallback((value?: DateRange) => {
    if (!value) {
      return;
    }

    setRange(value);

    if (value.from) {
      setStartDate(value.from);
    }

    if (value.to) {
      setEndDate(value.to);
    }
  }, []);

  const onCancel = useCallback(() => {
    onClose(false);
  }, []);

  const onSubmit = () => {
    onDatesRangeChange(range);
    onFirstDateChange(startDate);
    onSecondDateChange(endDate);
    onRangeTypeChange(activeRange);
    onClose(false);
  };

  const changeRange = useCallback(
    (
      newRange: string,
      primaryDate: Date | number,
      secondaryDate: Date | number
    ) => {
      const temporaryDateRange: DateRange = [
        RangeType.ThisWeek,
        RangeType.LastWeek
      ].includes(newRange as RangeType)
        ? {
            from: startOfWeek(primaryDate, { weekStartsOn: 1 }),
            to: endOfWeek(secondaryDate, { weekStartsOn: 1 })
          }
        : {
            from:
              typeof primaryDate === 'number'
                ? new Date(primaryDate)
                : primaryDate,
            to:
              typeof secondaryDate === 'number'
                ? new Date(secondaryDate)
                : secondaryDate
          };

      setRange(temporaryDateRange);
      setActiveRange(newRange);

      if (temporaryDateRange.from) {
        setStartDate(temporaryDateRange.from);
      }

      if (temporaryDateRange.to) {
        setEndDate(temporaryDateRange.to);
      }
    },
    []
  );

  const changeHandler = useCallback((newRange: string) => {
    switch (newRange) {
      case RangeType.ThisWeek: {
        const start = moment().startOf('isoWeek').toDate();
        const end = moment().endOf('isoWeek').toDate();
        setStartDate(start);
        setEndDate(end);
        changeRange(newRange, start, end);
        break;
      }
      case RangeType.LastWeek: {
        const start = moment().startOf('isoWeek').subtract(1, 'week').toDate();
        const end = moment().endOf('isoWeek').subtract(1, 'week').toDate();
        setStartDate(start);
        setEndDate(end);
        changeRange(newRange, start, end);
        break;
      }
      case RangeType.ThisMonth: {
        const start = moment().startOf('month').toDate();
        const end = moment().endOf('month').toDate();
        setStartDate(start);
        setEndDate(end);
        changeRange(newRange, start, end);
        break;
      }
      case RangeType.Last3Months: {
        const start = moment().startOf('month').subtract(3, 'month').toDate();
        const end = moment().endOf('month').subtract(1, 'month').toDate();
        setStartDate(start);
        setEndDate(end);
        changeRange(newRange, start, end);
        break;
      }
      case RangeType.Last6Months: {
        const start = moment().startOf('month').subtract(6, 'month').toDate();
        const end = moment().endOf('month').subtract(1, 'month').toDate();
        setStartDate(start);
        setEndDate(end);
        changeRange(newRange, start, end);
        break;
      }
      case RangeType.Next3Months: {
        const start = moment().startOf('month').add(1, 'month').toDate();
        const end = moment().endOf('month').add(3, 'month').toDate();
        setStartDate(start);
        setEndDate(end);
        changeRange(newRange, start, end);
        break;
      }
      case RangeType.Next6Months: {
        const start = moment().startOf('month').add(1, 'month').toDate();
        const end = moment().endOf('month').add(6, 'month').toDate();
        setStartDate(start);
        setEndDate(end);
        changeRange(newRange, start, end);
        break;
      }
      default:
        break;
    }
  }, []);

  return (
    <div>
      {isOpen && (
        <ClickAwayListener onClickAway={onCancel}>
          <div className={styles.container}>
            <div className={styles.main}>
              <div className={styles.sidebar}>
                <div className={styles.list}>
                  <div
                    className={clsx({
                      [styles.active]: activeRange === RangeType.ThisWeek
                    })}
                    onClick={() => changeHandler(RangeType.ThisWeek)}
                  >
                    <p>This week</p>
                  </div>
                  <div
                    className={clsx({
                      [styles.active]: activeRange === RangeType.LastWeek
                    })}
                    onClick={() => changeHandler(RangeType.LastWeek)}
                  >
                    <p>Last week</p>
                  </div>
                  <div
                    className={clsx({
                      [styles.active]: activeRange === RangeType.ThisMonth
                    })}
                    onClick={() => changeHandler(RangeType.ThisMonth)}
                  >
                    <p>This month</p>
                  </div>
                  <div
                    className={clsx({
                      [styles.active]: activeRange === RangeType.Last3Months
                    })}
                    onClick={() => changeHandler(RangeType.Last3Months)}
                  >
                    <p>Last 3 month</p>
                  </div>
                  <div
                    className={clsx({
                      [styles.active]: activeRange === RangeType.Last6Months
                    })}
                    onClick={() => changeHandler(RangeType.Last6Months)}
                  >
                    <p>Last 6 month</p>
                  </div>
                  <div
                    className={clsx({
                      [styles.active]: activeRange === RangeType.Next3Months
                    })}
                    onClick={() => changeHandler(RangeType.Next3Months)}
                  >
                    <p>Next 3 month</p>
                  </div>
                  <div
                    className={clsx({
                      [styles.active]: activeRange === RangeType.Next6Months
                    })}
                    onClick={() => changeHandler(RangeType.Next6Months)}
                  >
                    <p>Next 6 month</p>
                  </div>
                </div>
              </div>
              <div className={styles.dates}>
                <div className={styles.inputs}>
                  <input
                    type="text"
                    value={format(startDate, 'dd.MM.yyyy')}
                    readOnly
                  />
                  <p>—</p>
                  <input
                    type="text"
                    value={format(endDate, 'dd.MM.yyyy')}
                    readOnly
                  />
                </div>
                <DayPicker
                  mode="range"
                  selected={range}
                  onSelect={handleChange}
                  showOutsideDays
                  weekStartsOn={1}
                />
              </div>
            </div>
            <div className={styles.footer}>
              <button type="button" onClick={onCancel}>
                CANCEL
              </button>
              <button type="button" onClick={() => onSubmit()}>
                APPLY
              </button>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default observer(DateRangePicker);
