import api from 'api';
import { action, makeObservable, observable, runInAction } from 'mobx';

class RateStore {
  @observable
  rate: number = 0;

  constructor() {
    makeObservable(this);
  }

  @action
  getDollarRate = async () => {
    try {
      const data = await api.rate.getDollarRate();
      runInAction(() => {
        this.rate = data;
      });
    } catch (error) {
      console.error(error);
    }
  };
}

const rateStore = new RateStore();

export default rateStore;
