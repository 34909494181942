import React, { FC } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import BlockWrapper from 'components/BlockWrapper';

// Types
import {
  WorkspaceSettingsNames as Names,
  WorkspaceBlockProps
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import { IWorkspaceSettingsPatch } from 'types/workspaceSettings';

// Styles
import classes from './ProjectFavorite.module.scss';

const ProjectFavorite: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, handleChange } = useFormikContext<IWorkspaceSettingsPatch>();

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Project and task favorites</span>
            <span className={classes.description}>
              Let people mark their most used projects and tasks as favorite so
              they appear at the top of the list when tracking time.
            </span>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.column}>
            <Box className={classes.inputContainer}>
              <Box className={classes.row}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name={Names.isActivateFavorites}
                        value={values.isActivateFavorites}
                        checked={values.isActivateFavorites}
                        onChange={(e) => {
                          handleChange(e);
                          onChange();
                        }}
                      />
                    }
                    label="Activate project and task"
                  />
                </FormGroup>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default ProjectFavorite;
