import { IProject } from './project';
import { ISalary } from './salary';
import { IUserRate } from './userRate';

export enum UserRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  USER = 'USER'
}

export enum WorkStatusVariant {
  active = 'Active',
  archived = 'Archived'
}

export enum LevelVariant {
  intern = 'Intern',
  junior = 'Junior',
  middle = 'Middle',
  highMiddle = 'High Middle',
  senior = 'Senior'
}

export enum ExpertizeVariant {
  pa = 'PA',
  qa = 'QA',
  design = 'Design',
  fullstack = 'Fullstack',
  frontend = 'Frontend',
  backend = 'Backend',
  flutter = 'Flutter',
  ios = 'IOS',
  android = 'Android'
}

export enum DefaultLegalStatus {
  employee = 'EMPLOYEE',
  contractor = 'CONTRACTOR'
}

export enum WeekDay {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday'
}

export enum TimeFormat {
  MILITARY_TIME = '24-hour',
  AM_PM = '12-hour'
}

export enum DateFormat {
  DD_MM_YYYY = 'dd.mm.yyyy',
  MM_DD_YYYY = 'mm.dd.yyyy',
  YYYY_MM_DD = 'yyyy.mm.dd'
}

const Hours: string[] = [];
for (let i = 0; i < 24; i += 1) {
  Hours.push(`${i}:00`);
}

export default Hours;

export interface User {
  id: string;
  img?: string;
  createdAt?: string;
  updatedAt?: string;
  email: string;
  name: string;
  phone?: string;
  hasPlanner: boolean;
  role: string;
  colour: string;
  defaultRate: number;
  currency?: string;
  status: WorkStatusVariant;
  defaultLevel: string;
  defaultLegalStatus: DefaultLegalStatus;
  defaultWeeklyCapacity: number;
  defaultExpertize: string;
  workDays: number[];
  isProject?: boolean;
  projects?: IProject[];
  salary?: ISalary;
  rate?: IUserRate;
  country: string;
  timeZone: string;
  timeFormat: TimeFormat;
  dateFormat: DateFormat;
  dayStart: string;
  weekStart: WeekDay;
}

export interface IUserPatch {
  userId: string;
  email?: string;
  name?: string;
  phone?: string;
  country?: string;
  timeZone?: string;
  timeFormat?: TimeFormat;
  dateFormat?: DateFormat;
  dayStart?: string;
  weekStart?: WeekDay;
  workDays?: number[];
}

export interface IPasswordPatch {
  userId: string;
  deviceId: string;
  password: string;
  newPassword: string;
}

export interface IPasswordRecoveryPatch {
  email: string;
  password: string;
  newPassword: string;
}
