import { action, makeObservable, observable } from 'mobx';

class SearchStore {
  @observable
  query: string = '';

  @observable
  selected: unknown = null;

  @observable
  options: unknown[] = [];

  @observable
  loading: boolean = false;

  @observable
  tracking: number = 0;

  @observable
  getOptionLabel?: (option: unknown) => string;

  constructor() {
    makeObservable(this);
  }

  @action
  reset = () => {
    this.setQuery('');
    this.setSelectedOption(null);
    this.options = [];
    this.loading = false;
    this.getOptionLabel = undefined;
    this.tracking += 1;
  };

  @action
  setQuery = (query: string) => {
    this.query = query;
  };

  @action
  setSelectedOption = (option: unknown) => {
    this.selected = option;
  };

  @action
  setOptions = (options: typeof this.options) => {
    this.options = options;
  };

  @action
  setLoadingState = (isLoading: boolean) => {
    this.loading = isLoading;
  };

  @action
  setOptionLabelHandler = (handler: (option: unknown) => string) => {
    this.getOptionLabel = handler;
  };
}

const searchStore = new SearchStore();
export default searchStore;
