import React, { FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';

// Components
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';

// Styles
import classes from './TrackerTimePicker.module.scss';

interface TimePickerProps {
  value: Date | undefined;
  onChange: (newDate: Dayjs | null) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const TrackerTimePicker: FC<TimePickerProps> = ({
  value,
  onChange,
  onBlur
}) => {
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }

    const enteredTime = e.currentTarget.value.replace(':', '');

    if (enteredTime.length === 4) {
      return;
    }

    if (enteredTime.length < 3) {
      let hour: number = Number(enteredTime);
      let minute: number = 0;

      if (hour > 23) {
        const [newHour, newMinute] = enteredTime.split('');
        hour = Number(newHour);
        minute = Number(newMinute);
      }

      const date = dayjs().startOf('d').set('h', hour).set('m', minute);
      onChange(date);
    }

    // if (enteredTime.length === 3) {
    //   const [hour, minute] = [
    //     Number(enteredTime.substring(0, 1)),
    //     Number(enteredTime.slice(1))
    //   ];
    //
    //   console.log(`${hour} ${minute}`);
    // }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        onChange={onChange}
        value={value}
        views={['hours', 'minutes']}
        ampm={false}
        disableOpenPicker
        renderInput={(params) => (
          <TextField
            {...params}
            onBlur={handleBlur}
            sx={{ textAlign: 'center' }}
            variant="standard"
            InputProps={{
              className: classes.timeField,
              disableUnderline: true,
              sx: {
                '.MuiInputBase-input': {
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  display: 'flex',
                  alignItems: 'center'
                }
              }
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default TrackerTimePicker;
