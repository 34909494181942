import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

// Icons
import { ReactComponent as Search } from 'assets/icons/search.svg';

// Styles
import searchStore from 'stores/SearchStore';
import classes from './SearchBase.module.scss';

interface SearchBaseProps {
  onSearchToggle?: (isOpen: boolean) => void;
  getInputProps: () => React.InputHTMLAttributes<HTMLInputElement>;
}

const SearchBase: FC<SearchBaseProps> = ({ onSearchToggle, getInputProps }) => {
  const { loading, tracking } = searchStore;
  const [isButtonClick, setButtonClick] = useState<boolean>(false);

  const setStateOnClick = useCallback((state: boolean) => {
    setButtonClick(state);

    if (onSearchToggle) {
      onSearchToggle(state);
    }
  }, []);

  useEffect(() => {
    setStateOnClick(false);
  }, [tracking]);

  return (
    <Box className={classes.search}>
      <IconButton
        aria-label="search"
        onClick={() => setStateOnClick(!isButtonClick)}
      >
        <Search />
      </IconButton>
      <InputBase
        onClick={() => setStateOnClick(true)}
        className={clsx({
          [classes.hiddenBase]: !isButtonClick
        })}
        placeholder="Search all..."
        inputProps={{
          ...getInputProps(),
          onBlur: () => false,
          className: classes.font,
          style: {
            width: '100vh'
          }
        }}
        endAdornment={
          loading ? <CircularProgress color="inherit" size={20} /> : null
        }
      />
      <Box
        className={clsx({
          [classes.openBackground]: isButtonClick,
          [classes.closeBackground]: !isButtonClick
        })}
        onClick={() => setStateOnClick(false)}
      />
    </Box>
  );
};

export default observer(SearchBase);
