import React, { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import BlockWrapper from 'components/BlockWrapper';

// Types
import {
  WorkspaceBlockProps,
  RequiredForTaskNames
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import { IWorkspaceSettingsPatch } from 'types/workspaceSettings';

// Styles
import classes from './RequiredForTask.module.scss';

const RequiredForTask: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, setFieldValue } = useFormikContext<IWorkspaceSettingsPatch>();

  const [project, setProject] = useState<boolean | null>(null);
  const [task, setTask] = useState<boolean | null>(null);
  const [tag, setTag] = useState<boolean | null>(null);
  const [description, setDescription] = useState<boolean | null>(null);

  const handleUpdate = (property: RequiredForTaskNames) => {
    if (values.requiredForTask) {
      setFieldValue('requiredForTask', {
        ...values.requiredForTask,
        [property]: !values.requiredForTask[property]
      });
      onChange();
    }
  };

  useEffect(() => {
    setProject(values.requiredForTask?.project as boolean);
    setTask(values.requiredForTask?.task as boolean);
    setTag(values.requiredForTask?.tag as boolean);
    setDescription(values.requiredForTask?.description as boolean);
  }, [values.requiredForTask]);

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>
              Do not allow saving time without
            </span>
            <span className={classes.description}>
              {`Entries with missing required fields won't be saved. Only visible custom fields can be required.`}
            </span>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.inputContainer}>
            <Box className={classes.row}>
              <RadioGroup row>
                <div className={classes.checkboxArea}>
                  <Checkbox
                    id={classes.checkbox1}
                    value={project}
                    checked={project as boolean}
                    onChange={() => {
                      handleUpdate(RequiredForTaskNames.project);
                    }}
                  />
                  <label htmlFor={classes.checkbox1}>Project</label>
                </div>
                <div className={classes.checkboxArea}>
                  <Checkbox
                    id={classes.checkbox2}
                    value={task}
                    checked={task as boolean}
                    onChange={() => {
                      handleUpdate(RequiredForTaskNames.task);
                    }}
                  />
                  <label htmlFor={classes.checkbox2}>Task</label>
                </div>
                <div className={classes.checkboxArea}>
                  <Checkbox
                    id={classes.checkbox3}
                    value={tag}
                    checked={tag as boolean}
                    onChange={() => {
                      handleUpdate(RequiredForTaskNames.tag);
                    }}
                  />
                  <label htmlFor={classes.checkbox3}>Tag</label>
                </div>
                <div className={classes.checkboxArea}>
                  <Checkbox
                    id={classes.checkbox4}
                    value={description}
                    checked={description as boolean}
                    onChange={() => {
                      handleUpdate(RequiredForTaskNames.description);
                    }}
                  />
                  <label htmlFor={classes.checkbox4}>Description</label>
                </div>
              </RadioGroup>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default RequiredForTask;
