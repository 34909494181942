import React, { FC } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import BlockWrapper from 'components/BlockWrapper';

// Types
import {
  WorkspaceSettingsNames as Names,
  WorkspaceBlockProps
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import { IWorkspaceSettingsPatch } from 'types/workspaceSettings';

// Styles
import classes from './ForceTimer.module.scss';

const ForceTimer: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, handleChange } = useFormikContext<IWorkspaceSettingsPatch>();

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Force timer</span>
            <span className={classes.description}>
              Prevent users from adding time manually and editing time of their
              existing entries.
            </span>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.column}>
            <Box className={classes.inputContainer}>
              <Box className={classes.wrapper}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name={Names.isForceTimer}
                        value={values.isForceTimer}
                        checked={values.isForceTimer}
                        onChange={(e) => {
                          handleChange(e);
                          console.log(values.isForceTimer);
                          onChange();
                        }}
                      />
                    }
                    label="Disable adding and editing time manually "
                  />
                </FormGroup>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default ForceTimer;
