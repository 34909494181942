import React, {
  FC,
  useCallback,
  useState,
  ChangeEvent,
  useEffect
} from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

// formik
import { useFormik } from 'formik';
import * as yup from 'yup';

// Icons
import Close from '@mui/icons-material/Close';

// Layouts
import Profile from 'layouts/ProfileLayout';

// Stores
import profileStore from 'stores/ProfileStore';

// API
import { inviteMemberOwner, inviteMemberAdmin } from 'api/sendLink';

// Types
import { UserRole } from 'types/user';

// Styles
import classes from './AddMemberModal.module.scss';

interface AddMemberProps {
  isOpen: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const AddMember: FC<AddMemberProps> = ({ isOpen, setOpen }) => {
  const [role, setRole] = useState<string>('USER');
  const [focusName, setFocusName] = useState<boolean>(false);
  const [focusEmail, setFocusEmail] = useState<boolean>(false);

  const profileRole = profileStore.role;

  const handleClose = useCallback((cIsOpen: boolean) => {
    setOpen(!cIsOpen);
  }, []);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Wrong email format')
      .matches(/\./, 'Email address must contain a dot')
      .required('Email field is required'),
    name: yup
      .string()
      .min(3, 'name must contain at least 3 characters')
      .required('Name field is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      name: ''
    },
    validationSchema,
    onSubmit: ({ email, name }) => {
      if (email.length && name.length && role.length) {
        if (profileRole === UserRole.OWNER) {
          inviteMemberOwner(email, name, role);
        }

        if (profileRole === UserRole.ADMIN) {
          inviteMemberAdmin(email, name, role);
        }

        if (profileRole === UserRole.ADMIN) {
          inviteMemberAdmin(email, name, role);
        }

        if (profileRole === UserRole.MANAGER) {
          inviteMemberAdmin(email, name, UserRole.USER);
        }
      }

      handleClose(isOpen);
    }
  });

  const roleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRole(e.target.value);
  }, []);

  useEffect(() => {
    formik.values.name = '';
    formik.values.email = '';
  }, [isOpen]);

  return (
    <Profile isOpen={isOpen}>
      <Grid container spacing={1} className={classes.nameContainer}>
        <Grid item xs={11}>
          <h2>Adding a member</h2>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => handleClose(isOpen)}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Grid container spacing={1} className={classes.box}>
          <Grid item xs={3}>
            <Typography
              className={classes.font}
              style={{ color: focusName ? '#f36d25' : 'inherit' }}
            >
              Full name
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="standard"
              fullWidth
              name="name"
              placeholder="Name and Last Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onFocus={() => setFocusName(true)}
              onBlur={() => setFocusName(false)}
              autoFocus
              InputProps={{
                classes: {
                  root: classes.font
                }
              }}
            />
          </Grid>
          {formik.touched.name && formik.errors.name ? (
            <Typography>
              <p style={{ color: 'red', paddingTop: '10px' }}>
                {formik.errors.name}
              </p>
            </Typography>
          ) : null}
        </Grid>

        <Grid container spacing={1} className={classes.box}>
          <Grid item xs={3}>
            <Typography
              className={classes.font}
              style={{ color: focusEmail ? '#f36d25' : 'inherit' }}
            >
              Email
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="standard"
              fullWidth
              name="email"
              placeholder="name@gmail.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              onFocus={() => setFocusEmail(true)}
              onBlur={() => setFocusEmail(false)}
              autoFocus
              InputProps={{
                classes: {
                  root: classes.font
                }
              }}
            />
          </Grid>
          {formik.touched.email && formik.errors.email ? (
            <Typography>
              <p style={{ color: 'red', paddingTop: '10px' }}>
                {formik.errors.email}
              </p>
            </Typography>
          ) : null}
        </Grid>

        {(profileRole === UserRole.OWNER || profileRole === UserRole.ADMIN) && (
          <Grid container spacing={1} className={classes.box}>
            <Grid item xs={3}>
              <Typography className={classes.font}>Role</Typography>
            </Grid>
            <Grid item xs={9}>
              <RadioGroup row onChange={roleChange}>
                <FormControlLabel
                  control={<Radio />}
                  value="USER"
                  checked={role === 'USER'}
                  label={<Typography className={classes.font}>User</Typography>}
                />
                <FormControlLabel
                  control={<Radio />}
                  value="MANAGER"
                  checked={role === 'MANAGER'}
                  label={
                    <Typography className={classes.font}>
                      Project Manager
                    </Typography>
                  }
                />
                {profileRole === UserRole.OWNER && (
                  <FormControlLabel
                    control={<Radio />}
                    value="ADMIN"
                    checked={role === 'ADMIN'}
                    label={
                      <Typography className={classes.font}>Admin</Typography>
                    }
                  />
                )}
              </RadioGroup>
            </Grid>
          </Grid>
        )}
        <Box className={classes.buttonBox}>
          <Button
            className={classes.buttonWithoutBorders}
            onClick={() => handleClose(isOpen)}
          >
            CANCEL
          </Button>
          <Button className={classes.inviteButton} type="submit">
            INVITE
          </Button>
        </Box>
      </form>
    </Profile>
  );
};

export default AddMember;
