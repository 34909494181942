import React, { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import BlockWrapper from 'components/BlockWrapper';

// Types
import {
  WorkspaceBlockProps,
  HidedPagesNames
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import { IWorkspaceSettingsPatch } from 'types/workspaceSettings';

// Styles
import classes from './HidePages.module.scss';

const HidePages: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, setFieldValue } = useFormikContext<IWorkspaceSettingsPatch>();

  const [management, setManagement] = useState<boolean>(
    values.hidedPages?.management as boolean
  );
  const [reports, setReports] = useState<boolean>(
    values.hidedPages?.reports as boolean
  );
  const [planner, setPlanner] = useState<boolean>(
    values.hidedPages?.planner as boolean
  );
  const [invoices, setInvoices] = useState<boolean>(
    values.hidedPages?.invoices as boolean
  );
  const [expences, setExpences] = useState<boolean>(
    values.hidedPages?.expences as boolean
  );

  const handleUpdate = (property: HidedPagesNames) => {
    if (values.hidedPages) {
      setFieldValue('hidedPages', {
        ...values.hidedPages,
        [property]: !values.hidedPages[property]
      });
      onChange();
    }
  };

  useEffect(() => {
    setManagement(values.hidedPages?.management as boolean);
    setReports(values.hidedPages?.reports as boolean);
    setPlanner(values.hidedPages?.planner as boolean);
    setInvoices(values.hidedPages?.invoices as boolean);
  }, [values.hidedPages]);

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Hide pages from regular users</span>
            <span className={classes.description}>
              {`Remove pages from sidebar so regular users can't access them.`}
            </span>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.inputContainer}>
            <Box className={classes.row}>
              <RadioGroup row>
                <div className={classes.checkboxArea}>
                  <Checkbox
                    id={classes.checkbox1}
                    checked={management}
                    onChange={() => handleUpdate(HidedPagesNames.management)}
                  />
                  <label htmlFor={classes.checkbox1}>Managment</label>
                </div>
                <div className={classes.checkboxArea}>
                  <Checkbox
                    id={classes.checkbox2}
                    checked={reports}
                    onChange={() => handleUpdate(HidedPagesNames.reports)}
                  />
                  <label htmlFor={classes.checkbox2}>Reports</label>
                </div>
                <div className={classes.checkboxArea}>
                  <Checkbox
                    id={classes.checkbox3}
                    checked={planner}
                    onChange={() => handleUpdate(HidedPagesNames.planner)}
                  />
                  <label htmlFor={classes.checkbox3}>Planner</label>
                </div>
                <div className={classes.checkboxArea}>
                  <Checkbox
                    id={classes.checkbox4}
                    checked={invoices}
                    onChange={() => handleUpdate(HidedPagesNames.invoices)}
                  />
                  <label htmlFor={classes.checkbox4}>Invoices</label>
                </div>
                <div className={classes.checkboxArea}>
                  <Checkbox
                    id={classes.checkbox5}
                    checked={expences}
                    onChange={() => setExpences(!expences)}
                  />
                  <label htmlFor={classes.checkbox5}>Expences</label>
                </div>
              </RadioGroup>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default HidePages;
