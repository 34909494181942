import React, { FC } from 'react';

// Components
import Box from '@mui/material/Box';
import TitleSetingsPages from './TitileSettingsPages/TitleSettingsPages';
import PersonalInfoSettings from './PersonalInfoSettings/PersonalInfoSettings';
import PasswordSettings from './PasswordSettings/PasswordSettings';
import WorkTimeSettings from './WorkTimeSettings';

// Styles
import classes from './ProfileSettings.module.scss';

const ProfileSettings: FC = () => {
  return (
    <Box className={classes.wrapper}>
      <Box>
        <TitleSetingsPages title="Settings of profile" />
      </Box>
      <Box>
        <PersonalInfoSettings />
      </Box>
      <Box>
        <PasswordSettings />
      </Box>
      <Box>
        <WorkTimeSettings />
      </Box>
    </Box>
  );
};

export default ProfileSettings;
