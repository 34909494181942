import axios from 'api/helpers/axios';

// Types
import { IUserRate } from 'types/userRate';

interface IUserRateResponse {
  data: IUserRate;
}

export const addRate = async (
  userId: string,
  rate: number,
  startAt: Date,
  currency?: number
) =>
  axios.post<IUserRateResponse>('/rate', {
    userId,
    rate,
    startAt,
    currency
  });

export const getRateForUser = async (userId: string) =>
  axios.get<IUserRateResponse>(`/rate/user/${userId}`);
