import React, { FC, useState, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';

// Components
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import BlockWrapper from 'components/BlockWrapper';

// Stores
import profileStore from 'stores/ProfileStore';

// Types
import {
  WorkspaceSettingsNames as Names,
  WorkspaceBlockProps
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
// Styles
import { IWorkspaceSettingsPatch } from 'types/workspaceSettings';
import classes from './CompanyInfo.module.scss';

const CompanyInfo: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, handleChange } = useFormikContext<IWorkspaceSettingsPatch>();

  const [focusName, setFocusName] = useState(false);
  const { profile } = profileStore;
  useEffect(() => {
    profileStore.getProfile();
  }, []);

  const fullName = profile?.name;
  const initialOfName = useMemo(() => {
    const name: string[] = fullName ? fullName.split(' ') : [''];
    const firstValue: string = name[0] ? name[0].charAt(0) : '';
    const secondValue: string = name[1] ? name[1].charAt(0) : '';

    return firstValue + secondValue;
  }, [fullName]);

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.textInfoContainer}>
          <span className={classes.title}>Company info</span>
          <span className={classes.description}>
            Your log-in credentials and the name that is displayed in reports.
          </span>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.PhotoContainer}>
            <Box className={classes.textPhotoContainer}>
              <span className={classes.photoTitle}>Company logo</span>
              <span className={classes.photoDescription}>
                Formats: png, jpg, gif. Max size: 1 MB.
              </span>
            </Box>
            <Box className={classes.uploadContainer}>
              <Avatar
                style={{ backgroundColor: profile?.colour || '#ce93d8' }}
                className={classes.avatar}
              >
                {initialOfName}
              </Avatar>
              <Button className={classes.upload}>UPLOAD IMAGE</Button>
            </Box>
          </Box>
          <Box className={classes.inputsContainer}>
            <Box>
              <span
                className={classes.label}
                style={{ color: focusName ? '#f36d25' : 'inherit' }}
              >
                Workspace name
              </span>
              <TextField
                color="primary"
                variant="standard"
                name={Names.companyName}
                onFocus={() => setFocusName(true)}
                onBlur={() => setFocusName(false)}
                value={values.companyName}
                onChange={(e) => {
                  handleChange(e);
                  onChange();
                }}
                fullWidth
                InputLabelProps={{
                  className: classes.input
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default CompanyInfo;
