import React, { FC, useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

// Components
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import BlockWrapper from 'components/BlockWrapper';

// Stores
import profileStore from 'stores/ProfileStore';

// Styles
import classes from './PersonalInfoSettings.module.scss';

enum Names {
  userId = 'userId',
  name = 'name',
  email = 'email',
  phone = 'phone'
}

// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

const validationSchema = yup.object({
  [Names.userId]: yup.string(),
  [Names.name]: yup.string(),
  [Names.email]: yup.string().email('This email is not valid'),
  [Names.phone]: yup
    .string()
    .matches(
      phoneRegExp,
      'Phone number is not valid. Correct variant 8xxxxxxxxxx'
    )
});

const PersonalInfoSettings: FC = () => {
  const [isEdit, setIsEdit] = useState(false);

  const { profile } = profileStore;
  useEffect(() => {
    profileStore.getProfile();
  }, []);

  const formik = useFormik({
    initialValues: {
      [Names.userId]: profile?.id,
      [Names.name]: profile?.name,
      [Names.email]: profile?.email,
      [Names.phone]: profile?.phone
    },
    validationSchema,
    onSubmit: (values) => {
      setIsEdit(false);
      profileStore.updateProfile({
        userId: profile?.id as string,
        name: values.name,
        email: values.email,
        phone: values.phone
      });
    }
  });

  const fullName = profile?.name;
  const initialOfName = useMemo(() => {
    const name: string[] = fullName ? fullName.split(' ') : [''];
    const firstValue: string = name[0] ? name[0].charAt(0) : '';
    const secondValue: string = name[1] ? name[1].charAt(0) : '';

    return firstValue + secondValue;
  }, [fullName]);

  const onChangeHandler = () => {
    setIsEdit(true);
  };
  const cancelHandler = () => {
    formik.values.name = profile?.name;
    formik.values.email = profile?.email;
    formik.values.phone = profile?.phone;
    setIsEdit(false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <BlockWrapper>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Personal info</span>
            <span className={classes.description}>
              Your log-in credentials and the name that is displayed in reports.
            </span>
          </Box>
          <Box className={classes.FieldsContainer}>
            <Box className={classes.PhotoContainer}>
              <Box className={classes.textPhotoContainer}>
                <span className={classes.photoTitle}>Profile photo</span>
                <span className={classes.photoDescription}>
                  Formats: png, jpg, gif. Max size: 1 MB.
                </span>
              </Box>
              <Box className={classes.uploadContainer}>
                <Avatar
                  style={{ backgroundColor: profile?.colour || '#ce93d8' }}
                  className={classes.avatar}
                >
                  {initialOfName}
                </Avatar>
                <Button className={classes.upload}>UPLOAD IMAGE</Button>
              </Box>
            </Box>
            <Box className={classes.inputsContainer}>
              <Box>
                <span className={classes.label}>Full name</span>
                <TextField
                  color="primary"
                  variant="standard"
                  name={Names.name}
                  value={formik.values.name}
                  onChange={(e) => {
                    formik.handleChange(e);
                    onChangeHandler();
                  }}
                  fullWidth
                  InputLabelProps={{
                    className: classes.input
                  }}
                />
              </Box>
              <Box>
                <span className={classes.label}>Email</span>
                <TextField
                  variant="standard"
                  color="primary"
                  name={Names.email}
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.handleChange(e);
                    onChangeHandler();
                  }}
                  fullWidth
                  InputLabelProps={{
                    className: classes.input
                  }}
                />
              </Box>
              <>
                <span className={classes.label}>Phone</span>
                <TextField
                  variant="standard"
                  color="primary"
                  name={Names.phone}
                  value={formik.values.phone}
                  onChange={(e) => {
                    formik.handleChange(e);
                    onChangeHandler();
                  }}
                  fullWidth
                  InputLabelProps={{
                    className: classes.input
                  }}
                />
              </>
            </Box>
          </Box>
          <Box className={classes.buttonBox}>
            <Button
              className={isEdit ? classes.editCancel : classes.cancel}
              disabled={!isEdit}
              onClick={cancelHandler}
            >
              CANCEL
            </Button>
            <Button
              className={isEdit ? classes.editSave : classes.save}
              disabled={!isEdit}
              type="submit"
            >
              SAVE
            </Button>
          </Box>
        </Box>
      </BlockWrapper>
    </form>
  );
};

export default PersonalInfoSettings;
