import axios from 'api/helpers/axios';

// Types
import { IProject, IProjectPatch } from 'types/project';
import { IRate } from 'types/rate';
import { ITask, ITaskPatch } from 'types/task';

interface IProjectResponse {
  data: {
    id: string;
    name: string;
    startAt: string;
    endAt: string;
    hasActualContract: boolean;
    colour?: string;
    hasPlanner: boolean;
    manager: string;
    clientId: string;
    contractStatus?: string;
    status?: string;
    plannerStatus: string;
    contractCurrency?: string;
    currency?: string;
    assignedHours?: number;
    archivedLink?: string;
  };
}

interface IProjectsResponse {
  data: [
    {
      id: string;
      name: string;
      startAt: string;
      endAt: string;
      hasActualContract: boolean;
      colour?: string;
      hasPlanner: boolean;
      manager: string;
      clientId: string;
      contractStatus?: string;
      status?: string;
      plannerStatus: string;
      contractCurrency?: string;
      currency?: string;
      assignedHours?: number;
      archivedLink?: string;
    }
  ];
}

interface IProjectExpertiseResponse {
  data: IRate[];
}

interface ITaskResponse {
  data: ITask;
}

interface ITasksResponse {
  data: ITask[];
}

export const getProjects = (): Promise<IProject[]> => {
  return axios.get<IProjectsResponse>('/projects').then((response) => {
    return response.data.data.map((project) => {
      return <IProject>{
        id: project.id,
        name: project.name,
        startAt: project.startAt,
        endAt: project.endAt,
        hasActualContract: project.hasActualContract,
        colour: project.colour,
        manager: project.manager,
        clientId: project.clientId,
        contractStatus: project.contractStatus,
        status: project.status,
        plannerStatus: project.plannerStatus,
        currency: project.currency,
        contractCurrency: project.contractCurrency,
        assignedHours: project.assignedHours,
        archivedLink: project.archivedLink
      };
    });
  });
};

export const addProject = async (
  name: string,
  clientId: string,
  colour: string | undefined
) =>
  axios.post<IProjectResponse>('/projects', {
    name,
    clientId,
    colour
  });

export const refreshProject = (
  id: string,
  request: IProjectPatch
): Promise<IProject> => {
  return axios
    .patch<IProjectResponse>(`/projects/${id}`, request)
    .then((response) => {
      return <IProject>{
        id: response.data.data.id,
        name: response.data.data.name,
        startAt: response.data.data.startAt,
        endAt: response.data.data.endAt,
        hasActualContract: response.data.data.hasActualContract,
        colour: response.data.data.colour,
        manager: response.data.data.manager,
        clientId: response.data.data.clientId,
        contractStatus: response.data.data.contractStatus,
        status: response.data.data.status,
        currency: response.data.data.currency,
        contractCurrency: response.data.data.contractCurrency,
        assignedHours: response.data.data.assignedHours,
        archivedLink: response.data.data.archivedLink
      };
    });
};

export const getProjectExpertises = async (projectId: string) => {
  return axios.get<IProjectExpertiseResponse>(
    `/projects/${projectId}/expertises`
  );
};

export const addProjectTask = async (
  projectId: string,
  name: string,
  assigned: number,
  startAt: Date,
  endAt: Date
) =>
  axios.post<ITaskResponse>(`/projects/${projectId}/tasks`, {
    name,
    assigned,
    startAt,
    endAt
  });

export const getProjectTasks = async (projectId: string) =>
  axios.get<ITasksResponse>(`/projects/${projectId}/tasks`);

export const updateTask = async (taskId: string, request: ITaskPatch) => {
  return axios
    .patch<ITaskResponse>(`/projects/tasks/${taskId}`, request)
    .then((response) => {
      return <ITask>{
        id: response.data.data.id,
        projectId: response.data.data.projectId,
        name: response.data.data.name,
        assigned: response.data.data.assigned,
        startAt: response.data.data.startAt,
        endAt: response.data.data.endAt
      };
    });
};
