import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

// Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Types
import { UserRole, type User } from 'types/user';
import { TimelinePlaceholder } from 'types/planner';

// Store
import profileStore from 'stores/ProfileStore';

// Components
import PlaceholderAvatar from 'components/PlaceholderAvatar';
import PlannerPersonModal from 'components/modals/PlannerPersonModal';
import MemberAvatar from 'components/MemberAvatar';

// Styles
import classes from './PlannerMember.module.scss';

interface MemberInfo {
  name: string;
  role: string;
  colour: string;
  defaultExpertize: string;
  defaultLevel?: string;
  isPlaceholder?: boolean;
}

interface PlannerMemberProps {
  user?: User;
  placeholder?: TimelinePlaceholder;
  openViewProjects: boolean;
  setOpenViewProjects: (openViewProjects: boolean) => void;
}

const PlannerMember: FC<PlannerMemberProps> = ({
  user,
  placeholder,
  openViewProjects,
  setOpenViewProjects
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const info: MemberInfo = useMemo(() => {
    if (user) {
      return {
        name: user.name,
        role: user.role,
        colour: user.colour,
        defaultExpertize: user.defaultExpertize,
        defaultLevel: user.defaultLevel
      };
    }

    if (placeholder) {
      return {
        name: placeholder.name,
        role: UserRole.USER,
        colour: '#5c5c5c',
        defaultExpertize: placeholder.defaultExpertize ?? '',
        isPlaceholder: true
      };
    }

    throw new Error('either user or placeholder must be assigned');
  }, []);

  const [hasManagement, setHasManagment] = useState(false);
  useEffect(() => {
    setHasManagment(
      [
        UserRole.ADMIN.toString(),
        UserRole.MANAGER.toString(),
        UserRole.OWNER.toString()
      ].includes(profileStore.role)
    );
  }, [profileStore.role]);

  const handleClick = () => {
    setOpenViewProjects(!openViewProjects);
  };

  return (
    <div
      className={classes.member}
      style={openViewProjects ? { marginBottom: '2px' } : {}}
    >
      <div className={classes.userItem}>
        <div className={classes.userInfo}>
          {info.isPlaceholder ? (
            <PlaceholderAvatar />
          ) : (
            <MemberAvatar
              fullName={info.name}
              userRole={info.role}
              color={info.colour}
            />
          )}
          <div className={classes.textItem}>
            <span className={classes.name}>{info.name}</span>
            <span className={classes.expertize}>
              {info.defaultExpertize}
              {info.defaultLevel ? `, ${info.defaultLevel}` : null}
            </span>
          </div>
        </div>
        <div className={classes.buttons}>
          <button
            type="button"
            className={classes.buttonDot}
            onClick={() => setIsOpen(!isOpen)}
            style={{ display: hasManagement ? 'inherit' : 'none' }}
          >
            <MoreVertIcon className={classes.dot} fontSize="inherit" />
          </button>
          <button
            type="button"
            className={classes.buttonArrow}
            onClick={handleClick}
            style={{
              transform: openViewProjects ? 'rotate(180deg)' : 'rotate(0)'
            }}
          >
            <KeyboardArrowDownIcon />
          </button>
        </div>
        <PlannerPersonModal
          user={user}
          placeholder={placeholder}
          isOpen={isOpen}
          setOpen={setIsOpen}
          title="Edit person"
        />
      </div>
    </div>
  );
};

export default observer(PlannerMember);
