import { action, makeObservable, observable, runInAction } from 'mobx';
import moment, { MomentInput } from 'moment';

// API
import {
  addPlaceholderAssignment,
  addPlannerAssignment,
  addPlannerPlaceholder,
  addPlannerUser,
  addPlannerProject,
  getPlaceholderAssignment,
  getPlannerAssignment,
  getPlannerUsers,
  getPlannerProjects,
  updatePlaceholderAssignment,
  updatePlannerAssignment,
  updatePlannerPlaceholder,
  updatePlannerUser,
  AssignmentUserRequest,
  PlaceholderPatchRequest,
  PersonFilters,
  PlannerAddingUserRequest as PlannerUserRequest,
  deletePlaceholderAssignment,
  deletePlannerUser,
  deletePlannerPlaceholder,
  deletePlannerAssignment,
  splitPlannerAssignment,
  splitPlaceholderAssignment
} from 'api/planner';

// Types
import {
  AssignmentUser,
  PlannerUser,
  TimelineProject,
  TimelinePerson,
  PaginationData,
  PlannerPerson
} from 'types/planner';
import { Placeholder } from 'types/placeholder';
import { ColourCodeProjectVariant, IProject } from 'types/project';

class PlannerStore {
  @observable
  users: TimelinePerson[] = [];

  @observable
  allUsers: PlannerPerson[] = [];

  @observable
  usersPagination?: PaginationData;

  @observable
  projects: TimelineProject[] = [];

  @observable
  projectsPagination?: PaginationData;

  @observable
  addedUser?: PlannerUser;

  @observable
  addedPlaceholder?: Placeholder;

  @observable
  addedProject?: IProject;

  @observable
  openedUsers: { userId: string; projectIds: string[] }[] = [];

  @observable
  loading: boolean = false;

  @observable
  addAssignment?: AssignmentUser;

  @observable
  assignments?: AssignmentUser[];

  @observable
  assignment?: AssignmentUser;

  @observable
  datePickerStart?: Date;

  @observable
  datePickerEnd?: Date;

  visibleTimeStart: Date | number = moment().startOf('isoWeek').toDate();

  @observable
  visibleTimeEnd: Date | number = moment()
    .startOf('isoWeek')
    .add(5, 'week')
    .endOf('isoWeek')
    .toDate();

  @observable
  zoom: number = 0;

  @observable
  minZoom: number = 1000 * 60 * 60 * 24 * 7 * 3;

  @observable
  maxZoom: number = this.minZoom * 6;

  @observable
  offsetVisibleTimeEnd?: (
    start: Date | number | undefined,
    end: Date | number | undefined
  ) => Date | undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  getPlannerUsersPerPage = async (
    page: number,
    rowsPerPage: number,
    query?: string,
    filters?: PersonFilters
  ) => {
    try {
      this.loading = true;
      const { data } = await getPlannerUsers(page, rowsPerPage, query, filters);
      runInAction(() => {
        this.users = data.data.items;
        this.usersPagination = {
          totalItems: data.data.totalItems,
          totalPages: data.data.totalPages,
          currentPage: data.data.currentPage
        };
      });

      return data.data.items;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  getPlannerAllUsers = async () => {
    try {
      this.loading = true;
      const { data } = await getPlannerUsers();
      runInAction(() => {
        this.allUsers = data.data;
        this.usersPagination = undefined;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  addPlannerUser = async (plannerUser: PlannerUserRequest) => {
    try {
      const { data } = await addPlannerUser(plannerUser);
      runInAction(() => {
        this.addedUser = data.data;
      });
    } catch (error) {
      console.error(error);
    }
  };

  @action
  updatePlannerUser = async (plannerUser: PlannerUserRequest) => {
    try {
      this.loading = true;
      const { data } = await updatePlannerUser(plannerUser);
      runInAction(() => {
        this.addedUser = data.data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  };

  @action
  deletePlannerUser = async (userId: string) => {
    try {
      this.loading = true;
      const { data } = await deletePlannerUser(userId);
      runInAction(() => {
        this.addedUser = data.data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  };

  @action
  addPlannerPlaceholder = async (placeholderData: Omit<Placeholder, 'id'>) => {
    try {
      const { data } = await addPlannerPlaceholder(placeholderData);
      runInAction(() => {
        this.addedPlaceholder = data.data;
      });

      return data.data;
    } catch (error) {
      console.error(error);
    }

    return null;
  };

  @action
  updatePlannerPlaceholder = async (
    placeholderData: PlaceholderPatchRequest
  ) => {
    try {
      this.loading = true;
      const { data } = await updatePlannerPlaceholder(placeholderData);
      runInAction(() => {
        this.addedPlaceholder = data.data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  };

  @action
  deletePlannerPlaceholder = async (userId: string) => {
    try {
      this.loading = true;
      const { data } = await deletePlannerPlaceholder(userId);
      runInAction(() => {
        this.addedPlaceholder = data.data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  };

  @action
  getPlannerProjects = async (
    page: number,
    rowsPerPage: number,
    query?: string
  ): Promise<TimelineProject[]> => {
    try {
      this.loading = true;
      const { data } = await getPlannerProjects(page, rowsPerPage, query);
      runInAction(() => {
        this.projects = data.data.items;
        this.projectsPagination = {
          totalItems: data.data.totalItems,
          totalPages: data.data.totalPages,
          currentPage: data.data.currentPage
        };
      });

      return data.data.items;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  addPlannerProject = async (
    projectId: string,
    colour: ColourCodeProjectVariant
  ) => {
    try {
      const { data } = await addPlannerProject({ projectId, colour });
      runInAction(() => {
        this.addedProject = data.data;
      });
    } catch (error) {
      console.error(error);
    }
  };

  @action
  updateAssignmentPlannerUser = async (
    assignmentData: AssignmentUserRequest
  ) => {
    try {
      const { data } = await updatePlannerAssignment(assignmentData);
      runInAction(() => {
        this.addAssignment = data.data;
      });
    } catch (error) {
      localStorage.setItem('error', JSON.stringify(error));
      console.error(error);
    }
  };

  @action
  addAssignmentPlannerUser = async (assignmentData: AssignmentUser) => {
    try {
      const { data } = await addPlannerAssignment(assignmentData);
      runInAction(() => {
        this.addAssignment = data.data;
      });
    } catch (error) {
      console.error(error);
    }
  };

  @action
  deleteAssignmentPlannerUser = async (assignmentId: string) => {
    try {
      const { data } = await deletePlannerAssignment(assignmentId);
      runInAction(() => {
        this.addAssignment = data.data;
      });
    } catch (error) {
      console.error(error);
    }
  };

  @action
  getPlannerAssignment = async (assignmentId: string) => {
    try {
      const { data } = await getPlannerAssignment(assignmentId);
      data.data.map((item) => {
        runInAction(() => {
          this.assignment = item;
        });

        return null;
      });
    } catch (error) {
      console.error(error);
    }
  };

  @action
  splitPlannerAssignment = async (
    date: MomentInput,
    assignmentIds: string[]
  ) => {
    try {
      const { data } = await splitPlannerAssignment(
        moment(date).format('YYYY-MM-DD'),
        assignmentIds
      );
      runInAction(() => {
        this.assignments = data.data;
      });
    } catch (error) {
      console.error(error);
    }
  };

  @action
  updatePlaceholderAssignment = async (
    assignmentData: AssignmentUserRequest
  ) => {
    try {
      const { data } = await updatePlaceholderAssignment(assignmentData);
      runInAction(() => {
        this.addAssignment = data.data;
      });
    } catch (error) {
      localStorage.setItem('error', JSON.stringify(error));
      console.error(error);
    }
  };

  @action
  addPlaceholderAssignment = async (assignmentData: AssignmentUser) => {
    try {
      const { data } = await addPlaceholderAssignment(assignmentData);
      runInAction(() => {
        this.addAssignment = data.data;
      });
    } catch (error) {
      console.error(error);
    }
  };

  @action
  deletePlaceholderAssignment = async (assignmentId: string) => {
    try {
      const { data } = await deletePlaceholderAssignment(assignmentId);
      runInAction(() => {
        this.addAssignment = data.data;
      });
    } catch (error) {
      console.error(error);
    }
  };

  @action
  getPlaceholderAssignment = async (assignmentId: string) => {
    try {
      const { data } = await getPlaceholderAssignment(assignmentId);
      data.data.map((item) => {
        runInAction(() => {
          this.assignment = item;
        });

        return null;
      });
    } catch (error) {
      console.error(error);
    }
  };

  @action
  splitPlaceholderAssignment = async (
    date: MomentInput,
    assignmentIds: string[]
  ) => {
    try {
      const { data } = await splitPlaceholderAssignment(
        moment(date).format('YYYY-MM-DD'),
        assignmentIds
      );
      runInAction(() => {
        this.assignments = data.data;
      });
    } catch (error) {
      console.error(error);
    }
  };

  @action
  resetVisibleRange = (
    startTime: Date | undefined,
    endTime: Date | undefined
  ) => {
    if (!this.offsetVisibleTimeEnd || !startTime || !endTime) {
      return;
    }

    const start = new Date(startTime);
    const lengthWeeks = Math.ceil(moment(endTime).diff(start, 'weeks', true));
    const end = new Date(
      lengthWeeks < 3 ? start.valueOf() + this.minZoom : endTime
    );

    this.zoom = 0;
    this.datePickerStart = start;
    this.datePickerEnd = this.offsetVisibleTimeEnd(start, end) ?? end;
  };

  @action
  handleZoomButtons = (dir: number) => {
    if (!this.offsetVisibleTimeEnd) {
      return;
    }

    if (!this.zoom) {
      const weeks = Math.ceil(
        moment(this.visibleTimeEnd).diff(this.visibleTimeStart, 'weeks', true)
      );
      const rounded = 3 * 2 ** Math.round(Math.log2(weeks / 3));
      this.zoom = Math.min(Math.max(3, rounded), 24);

      if (Math.abs(rounded - weeks) <= 1) {
        this.handleZoomButtons(dir);

        return;
      }
    } else if (dir > 0 && this.zoom < 24) {
      this.zoom *= 2;
    } else if (dir < 0 && this.zoom > 3) {
      this.zoom /= 2;
    } else {
      return;
    }

    const newVisibleTimeEnd = moment(this.visibleTimeStart).add(
      this.zoom,
      'week'
    );

    this.setVisibleTimeEnd(
      this.offsetVisibleTimeEnd(
        this.visibleTimeStart,
        newVisibleTimeEnd.toDate()
      ) ?? newVisibleTimeEnd.toDate()
    );
  };

  @action
  handleArrowButtons = (dir: number) => {
    const scrollAmount = 7;

    let newVisibleTimeStart: moment.Moment;
    let newVisibleTimeEnd: moment.Moment;

    if (dir < 0) {
      newVisibleTimeStart = moment(this.visibleTimeStart)
        .subtract(1, 'hours')
        .endOf('isoWeek');
      newVisibleTimeEnd = moment(this.visibleTimeEnd)
        .subtract(1, 'hours')
        .endOf('isoWeek');
    } else {
      newVisibleTimeStart = moment(this.visibleTimeStart)
        .add(2, 'hours')
        .startOf('isoWeek');
      newVisibleTimeEnd = moment(this.visibleTimeEnd)
        .add(2, 'hours')
        .startOf('isoWeek');
    }

    this.visibleTimeStart = newVisibleTimeStart
      .add(scrollAmount * dir, 'days')
      .toDate();
    this.visibleTimeEnd = newVisibleTimeEnd
      .add(scrollAmount * dir, 'days')
      .toDate();
  };

  @action
  setVisibleTimeEndOffsetCallback = (
    callback:
      | ((
          start: Date | number | undefined,
          end: Date | number | undefined
        ) => Date | undefined)
      | undefined
  ) => {
    this.offsetVisibleTimeEnd = callback;
  };

  @action
  setVisibleTimeStart = (time: Date | number) => {
    this.visibleTimeStart = time;
  };

  @action
  setVisibleTimeEnd = (time: Date | number) => {
    this.visibleTimeEnd = time;
  };
}

const plannerStore = new PlannerStore();

export default plannerStore;
