import React, { FC, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';

// Utils
import { formatTime } from 'utils/timetrackerUtils';

// Stores
import timeTrackerStore from 'stores/TimeTrackerStore';

// Icons
import { ReactComponent as PenIcon } from 'assets/icons/pen2.svg';
import { ReactComponent as TrackIcon } from 'assets/icons/tracker-mode.svg';
import CloseIcon from '@mui/icons-material/Close';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';

// Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';

// Components
import TrackerDatePicker from 'components/TrackerDatePicker';
import TrackerTimePicker from 'components/TrackerTimePicker';
import SelectProjectButton from './SelectProjectButton/SelectProjectButton';

// Styles
import classes from './NewRecord.module.scss';

const NewRecord: FC = () => {
  const [isManual, setIsManual] = useState<boolean>(false);

  const { currentRecord, isRunning } = timeTrackerStore;

  useEffect(() => {
    timeTrackerStore.getRecords();
  }, []);

  useEffect(() => {
    setIsManual(false);
  }, [isRunning]);

  const handleStart = () => {
    timeTrackerStore.toggleRecordRun();
  };

  const handleManualAdd = () => {
    timeTrackerStore.addManualRecord();
  };

  const handleCancel = () => {
    timeTrackerStore.cancelRecord();
  };

  const handleRecordDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    timeTrackerStore.changeCurrentRecordDescription(e.target.value);
  };

  const handleStartedAtChange = (newDate: Dayjs | null) => {
    if (newDate) {
      timeTrackerStore.changeCurrentRecordStartedAt(
        newDate.startOf('minute').toDate()
      );
    }
  };

  const handleEndedAtChange = (newDate: Dayjs | null) => {
    if (newDate) {
      timeTrackerStore.changeCurrentRecordEndedAt(
        newDate.startOf('minute').toDate()
      );
    }
  };

  const handleDayChange = (day: Date) => {
    if (day) {
      timeTrackerStore.changeCurrentDay(day);
    }
  };

  const handleModeSwitch = () => {
    if (isManual) {
      handleStartedAtChange(dayjs().startOf('minute'));
      handleEndedAtChange(dayjs().startOf('minute'));
    }

    setIsManual((prev) => !prev);
  };

  const isStartedAtValid = (): boolean => {
    return dayjs(currentRecord.startedAt).isValid();
  };

  const isEndedAtValid = (): boolean => {
    return dayjs(currentRecord.endedAt).isValid();
  };

  const isDatesValid = (): boolean => {
    return isStartedAtValid() && isEndedAtValid();
  };

  const isButtonDisabled = (): boolean => {
    const isInfoEntered = Boolean(
      currentRecord.description && currentRecord.project && currentRecord.title
    );

    return !(isInfoEntered && isDatesValid());
  };

  const handleTimePickerBlur = () => {
    if (!isStartedAtValid()) {
      handleStartedAtChange(dayjs());
    }

    if (!isEndedAtValid()) {
      handleEndedAtChange(dayjs());
    }

    handleDayChange(dayjs().startOf('day').toDate());
  };

  const getDayLabel = (): string => {
    if (!isDatesValid()) {
      return '...';
    }

    if (dayjs(currentRecord.startedAt).isSame(dayjs(), 'day')) {
      return 'Today';
    }

    return dayjs(currentRecord.startedAt).format('YYYY/MM/DD');
  };

  const willDivideToTwo = (): boolean => {
    return dayjs(currentRecord.startedAt).isAfter(dayjs(currentRecord.endedAt));
  };

  return (
    <div className={classes.record}>
      <div className={classes.recordRowFirst}>
        <div className={classes.recordInput}>
          <InputBase
            value={currentRecord.description}
            placeholder={
              isManual ? 'What have you worked on?' : 'What are you working on?'
            }
            onChange={handleRecordDescriptionChange}
          />
        </div>
        <div>
          <SelectProjectButton />
        </div>
      </div>
      <div className={classes.recordRowSecond}>
        <IconButton disabled>
          <CurrencyRubleIcon
            fontSize="small"
            color={currentRecord.billable ? 'primary' : 'disabled'}
          />
        </IconButton>
        <Divider orientation="vertical" variant="fullWidth" flexItem />
        {!isRunning && isManual ? (
          <>
            <Box className={classes.timePickerBlock}>
              <TrackerTimePicker
                value={currentRecord.startedAt}
                onChange={handleStartedAtChange}
                onBlur={handleTimePickerBlur}
              />
              <div>–</div>
              <TrackerTimePicker
                value={currentRecord.endedAt}
                onChange={handleEndedAtChange}
                onBlur={handleTimePickerBlur}
              />
            </Box>
            <TrackerDatePicker
              selected={currentRecord.startedAt}
              onSelect={handleDayChange}
            />
            <Typography className={classes.manualCurrentDay}>
              {getDayLabel()}
              {willDivideToTwo() && (
                <Typography className={classes.nextDayIndicator}>+1</Typography>
              )}
            </Typography>
            <Typography className={classes.recordTimer}>
              {isDatesValid()
                ? formatTime(currentRecord.totalTime, 'HH:mm:ss')
                : formatTime(0, 'HH:mm:ss')}
            </Typography>
          </>
        ) : (
          <Typography className={classes.recordTimer}>
            {formatTime(currentRecord.totalTime, 'HH:mm:ss')}
          </Typography>
        )}
        <Button
          variant="contained"
          disabled={isButtonDisabled()}
          size="small"
          className={classes.startButton}
          color={isRunning ? 'error' : 'primary'}
          onClick={isManual ? handleManualAdd : handleStart}
        >
          {isRunning && 'STOP'}
          {!isRunning && (isManual ? 'ADD' : 'START')}
        </Button>
        <IconButton onClick={isRunning ? handleCancel : handleModeSwitch}>
          {isRunning && <CloseIcon />}
          {!isRunning && (isManual ? <TrackIcon /> : <PenIcon />)}
        </IconButton>
      </div>
    </div>
  );
};

export default observer(NewRecord);
