import React, { FC, useState } from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';

// Styles
import classes from './ButtonWorkHours.module.scss';

interface ButtonWorkHoursViewProps {
  workDays: number[];
  setWorkDays: (value: number[]) => void;
  day: number;
}

const getLabel = (day: number) => {
  switch (day) {
    case 0:
      return 'M';
    case 1:
      return 'T';
    case 2:
      return 'W';
    case 3:
      return 'T';
    case 4:
      return 'F';
    case 5:
      return 'S';
    case 6:
      return 'S';
    default:
      return '';
  }
};

const ButtonWorkHours: FC<ButtonWorkHoursViewProps> = ({
  workDays,
  setWorkDays,
  day
}) => {
  const [isActive, setIsActive] = useState<boolean>(workDays.indexOf(day) > -1);

  const handleClick = () => {
    if (!isActive) {
      setWorkDays([...workDays, day].sort());
    } else {
      setWorkDays(workDays.filter((i) => i !== day).sort());
    }

    setIsActive(!isActive);
  };

  return (
    <div>
      <Button
        className={clsx(classes.button, {
          [classes.buttonActive]: isActive,
          [classes.buttonInactive]: !isActive
        })}
        onClick={handleClick}
      >
        {getLabel(day)}
      </Button>
    </div>
  );
};

export default ButtonWorkHours;
