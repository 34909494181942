import { observer } from 'mobx-react-lite';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import moment from 'moment';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';

import Timeline, {
  SidebarHeader,
  TimelineHeaders,
  DateHeader,
  ReactCalendarItemRendererProps,
  IntervalRenderer,
  Interval,
  TimelineContext
} from 'react-calendar-timeline';

// Utils
import { boundedOffsetVisibleTimeEnd } from 'utils/plannerUtils';

// Stores
import plannerStore from 'stores/PlannerStore';
import projectsStore from 'stores/ProjectsStore';
import membersStore from 'stores/MembersStore';
import profileStore from 'stores/ProfileStore';
import clientStore from 'stores/ClientsStore';
import searchStore from 'stores/SearchStore';

// Styles
import 'react-calendar-timeline/lib/Timeline.css';
import './CalendarTimeline.scss';

// Types
import { DefaultLegalStatus, UserRole } from 'types/user';
import { IProject, PlannerStatus } from 'types/project';
import {
  AssignmentUser,
  TimelineUser,
  TimelineAssignmentType,
  TimelinePlaceholder,
  TimelinePerson
} from 'types/planner';

// Icons
import SplitIcon from 'assets/icons/split.svg';

// Components
import TabsView from 'components/Tabs/TabsView';
import TableFooter from 'components/TableFooter';
import Loading from 'components/Loading';
import NewPlannerAssignment from 'components/modals/NewPlannerAssignment';
import ProjectAdminModal from 'components/modals/ProjectAdminModal';
import ProjectManagerModal from 'components/modals/ProjectManagerModal';
import AddProjectModal from 'components/modals/AddProjectModal';
import PlannerMember from '../PlannerMember';
import PlannerSelector from '../PlannerSelector';

interface TimelineUserSubGroup {
  id: string;
  type: 'user';
  title: React.ReactNode;
  height?: number;
  member: TimelineUser;
  project?: IProject;
}

interface TimelinePlaceholderSubGroup {
  id: string;
  type: 'placeholder';
  title: React.ReactNode;
  height?: number;
  member: TimelinePlaceholder;
  project?: IProject;
}

type TimelineSubGroup = TimelineUserSubGroup | TimelinePlaceholderSubGroup;

interface TimelineUserGroup {
  idx: number;
  type: 'user';
  member: TimelineUser;
  isOpen: boolean;
  subGroups: TimelineUserSubGroup[];
}

interface TimelinePlaceholderGroup {
  idx: number;
  type: 'placeholder';
  member: TimelinePlaceholder;
  isOpen: boolean;
  subGroups: TimelinePlaceholderSubGroup[];
}

type TimelineGroup = TimelineUserGroup | TimelinePlaceholderGroup;

// TODO: remove fields that are originally taken from `user`, `project` etc. (redundant copies)
type TimelineUserAssignment = {
  id: string;
  memberType: 'user';
  user: TimelineUser;
  group: string;
  start_time: number;
  end_time: number;
} & (
  | {
      type: TimelineAssignmentType.User;
      weeksWorkTime: number[];
      weeklyCapacity: number;
      defaultLegalStatus: DefaultLegalStatus;
    }
  | {
      type: TimelineAssignmentType.Project;
      project: IProject;
      assignment: AssignmentUser;
      dailyHours: number;
      weeksWorkTime: number[];
      weeklyCapacity: number;
      isGhost: boolean;
    }
  | {
      type: TimelineAssignmentType.NewAssignment;
      project: IProject;
    }
);

type TimelinePlaceholderAssignment = {
  id: string;
  memberType: 'placeholder';
  user: TimelinePlaceholder;
  group: string;
  start_time: number;
  end_time: number;
} & (
  | {
      type: TimelineAssignmentType.User;
      weeksWorkTime: number[];
      weeklyCapacity?: number;
      defaultLegalStatus?: DefaultLegalStatus;
    }
  | {
      type: TimelineAssignmentType.Project;
      project: IProject;
      assignment: AssignmentUser;
      dailyHours: number;
      weeksWorkTime: number[];
      weeklyCapacity?: number;
      isGhost: boolean;
    }
  | {
      type: TimelineAssignmentType.NewAssignment;
      project: IProject;
    }
);

type TimelineAssignment =
  | TimelineUserAssignment
  | TimelinePlaceholderAssignment;

type SelectorItem =
  | {
      type: 'project';
      project: IProject;
    }
  | {
      type: 'custom';
    };

const CalendarTimeline: FC = () => {
  const isFirstRender = useRef(true);
  const isFirstLoading = useRef(true);
  const isTimeChangeFrozen = useRef(false);
  const headerRef = useRef<HTMLElement | null>(null);
  const scrollRef = useRef<HTMLElement | null>(null);

  const [activeUsersTab, setActiveUsersTab] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isProjectSettingsOpen, setProjectSettingsOpen] =
    useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<TimelineAssignment | null>(null);
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
  const [groups, setGroups] = useState<TimelineGroup[]>([]);
  const [items, setItems] = useState<TimelineAssignment[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isAddProjectModalOpen, setAddProjectModalOpen] = useState(false);
  const [newAssignment, setNewAssignment] = useState<TimelineAssignment | null>(
    null
  );

  const {
    usersPagination,
    assignments,
    loading,
    addedUser,
    addedPlaceholder,
    addAssignment,
    visibleTimeStart,
    visibleTimeEnd,
    datePickerStart,
    datePickerEnd,
    minZoom,
    maxZoom
  } = plannerStore;
  const { updatedMember } = membersStore;
  const { projects, addedProject, updatedProject } = projectsStore;
  const { query } = searchStore;

  const hasAdmin = [
    UserRole.ADMIN.toString(),
    UserRole.OWNER.toString()
  ].includes(profileStore.role);

  const changeDatePicker = () => {
    if (!datePickerStart || !datePickerEnd) {
      return;
    }

    plannerStore.setVisibleTimeStart(datePickerStart);
    plannerStore.setVisibleTimeEnd(datePickerEnd);
  };

  const calculateWeekCount = (
    start: moment.Moment | Date | number,
    end: moment.Moment | Date | number
  ) => {
    const weekLength = 604800000; // 24 * 7 * 60 * 60 * 1000
    const weekCount = Math.ceil((end.valueOf() - start.valueOf()) / weekLength);

    return weekCount;
  };

  const handleMouseUp = () => {
    if (isTimeChangeFrozen.current) {
      isTimeChangeFrozen.current = false;
    }
  };

  const handleSetActiveUsersTab = useCallback((tab: number) => {
    setPage(0);
    setActiveUsersTab(tab);
  }, []);

  const openProjectSettings = useCallback((project: IProject) => {
    setSelectedProject(project);
    setProjectSettingsOpen(true);
  }, []);

  const getAvailableProjects = useCallback(
    (hasProjects: { projects: IProject[] }) => {
      const available: SelectorItem[] = [{ type: 'custom' }];

      for (let i = 0; i < projects.length; i += 1) {
        let isAvailable = true;
        const first = projects[i];

        for (let j = 0; j < hasProjects.projects.length; j += 1) {
          const second = hasProjects.projects[j];

          if (first.id === second.id) {
            isAvailable = false;
            break;
          }
        }

        if (isAvailable) {
          available.push({ type: 'project', project: first });
        }
      }

      return available;
    },
    [projects]
  );

  const getSelectorChangeHandler = useCallback(
    (group: TimelineGroup) => (option: SelectorItem) => {
      const group0 = group as TimelineUserGroup;

      if (option.type === 'custom') {
        setAddProjectModalOpen(true);

        return false;
      }

      // generate new Project (subgroup) that was selected from selector
      group0.subGroups.splice(group0.subGroups.length - 1, 0, {
        id: `${group0.member.id},${option.project.id}`,
        type: group0.type,
        title: (
          <ButtonBase
            className="newSubGroup"
            onClick={() => openProjectSettings(option.project)}
          >
            {option.project.name}
          </ButtonBase>
        ),
        height: 49,
        member: group0.member,
        project: option.project
      });

      setGroups(groups.slice());

      return true;
    },
    [groups]
  );

  // TODO: extract everything into common functions
  const getPlannerPersons = useCallback(
    (users: TimelinePerson[]) => {
      const convertedGroups: TimelineGroup[] = [];
      for (let i = 0; i < users.length; i += 1) {
        const user = users[i];
        const isCached = groups.length > i && groups[i].member.id === user.id;

        if (isCached) {
          const group = groups[i];
          const newGroup = {
            ...group,
            member: user
          } as TimelineGroup;

          if (group.isOpen) {
            const subGroups = user.projects.map(
              (project) =>
                ({
                  id: `${user.id},${project.id}`,
                  type: user.type,
                  title: (
                    <ButtonBase
                      className="subGroup"
                      onClick={() => openProjectSettings(project)}
                    >
                      {project.name}
                    </ButtonBase>
                  ),
                  height: 49,
                  member: user,
                  project
                } as TimelineSubGroup)
            );
            subGroups.push({
              id: `selector${group.idx}`,
              type: user.type,
              title: (
                <PlannerSelector
                  placeholder="Assign to Project..."
                  options={getAvailableProjects(user)}
                  getOptionLabel={(option: SelectorItem) => {
                    if (option.type === 'custom') {
                      return 'New project';
                    }

                    return option.project.name;
                  }}
                  onChange={getSelectorChangeHandler(group)}
                />
              ),
              height: 48,
              member: user
            } as TimelineSubGroup);
            newGroup.subGroups = subGroups as
              | TimelineUserSubGroup[]
              | TimelinePlaceholderSubGroup[];
          }

          convertedGroups.push(newGroup);
        } else {
          convertedGroups.push({
            idx: i,
            type: user.type,
            member: user,
            isOpen: false,
            subGroups: []
          } as TimelineGroup);
        }
      }
      setGroups(convertedGroups);
    },
    [groups]
  );

  // TODO: rename this function to `getPlannerAssignments`
  const getPlannerProjects = useCallback((users: TimelinePerson[]) => {
    const convertedItems: TimelineAssignment[] = [];
    for (let i = 0; i < users.length; i += 1) {
      const user = users[i];
      let workTimeOffset = 0;
      for (let j = 0; j < user.intervals.length; j += 1) {
        const interval = user.intervals[j];

        const startTime = moment(interval[0]).valueOf();
        const endTime = moment(interval[1]).valueOf();
        const weekCount = calculateWeekCount(startTime, endTime);

        const weeksWorkTime = user.workTime.slice(
          workTimeOffset,
          workTimeOffset + weekCount
        );

        const item = {
          id: `${i},${j}`,
          memberType: user.type,
          type: TimelineAssignmentType.User,
          user,
          group: user.id,
          start_time: startTime,
          end_time: endTime,
          weeksWorkTime,
          weeklyCapacity: user.defaultWeeklyCapacity
        } as TimelineAssignment;

        if (user.type === 'user' && item.type === TimelineAssignmentType.User) {
          item.defaultLegalStatus = user.defaultLegalStatus;
        }

        convertedItems.push(item);
        workTimeOffset += weekCount;
      }

      for (let j = 0; j < user.projects.length; j += 1) {
        const project = user.projects[j];
        for (let k = 0; k < project.intervals.length; k += 1) {
          const assignment = project.intervals[k];
          convertedItems.push({
            id: `sub:${i},${j},${k}`,
            memberType: user.type,
            type: TimelineAssignmentType.Project,
            user,
            project,
            assignment,
            group: `${user.id},${project.id}`,
            start_time: moment(assignment.startAt).startOf('day').valueOf(),
            end_time: moment(assignment.endAt).endOf('day').valueOf(),
            weeksWorkTime: assignment.workTime,
            dailyHours: assignment.dailyHours,
            weeklyCapacity: user.defaultWeeklyCapacity,
            isGhost: project.isGhost
          } as TimelineAssignment);
        }
      }
    }

    setItems(convertedItems);
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  useEffect(() => {
    changeDatePicker();
  }, [datePickerStart, datePickerEnd]);

  useEffect(() => {
    setPage(0);
  }, [query]);

  const getPlannerData = useCallback(
    (users: TimelinePerson[]) => {
      getPlannerPersons(users);
      getPlannerProjects(users);

      if (query) {
        searchStore.setOptions(users);
      }

      searchStore.setLoadingState(false);
    },
    [query, groups]
  );

  useEffect(() => {
    if (isFirstLoading.current) {
      searchStore.setOptionLabelHandler(
        (option: unknown) => (option as TimelinePerson).name
      );
    }

    if (!isFirstRender.current && !loading) {
      isFirstLoading.current = false;
    }
  }, [loading]);

  useEffect(() => {
    let isActive = true;

    projectsStore.loadProjects();
    clientStore.loadClients();
    plannerStore
      .getPlannerUsersPerPage(page, rowsPerPage, query || undefined, {
        defaultLegalStatus: ['all', 'employee', 'contractor'][
          activeUsersTab
        ] as 'all' | 'employee' | 'contractor'
      })
      .then((users) => {
        if (!isActive) {
          return;
        }

        getPlannerData(users);
      });

    searchStore.setLoadingState(!isFirstRender.current);
    isFirstRender.current = false;

    return () => {
      isActive = false;
    };
  }, [
    assignments,
    addedProject,
    updatedProject,
    addedUser,
    addedPlaceholder,
    updatedMember,
    addAssignment,
    query,
    page,
    rowsPerPage,
    activeUsersTab
  ]);

  const flattenedGroups = useMemo(() => {
    const flattened = [];
    for (let i = 0; i < groups.length; i += 1) {
      const group = groups[i];

      const foldToggle = (isFoldOpen: boolean) => {
        const newGroups = groups.slice();
        const tmpGroup = newGroups[group.idx];

        tmpGroup.isOpen = isFoldOpen;

        if (isFoldOpen && tmpGroup.type === 'user') {
          // generate Projects (subgroups) from planner data
          for (let j = 0; j < tmpGroup.member.projects.length; j += 1) {
            const project = tmpGroup.member.projects[j];
            tmpGroup.subGroups.push({
              id: `${tmpGroup.member.id},${project.id}`,
              type: tmpGroup.type,
              title: (
                <ButtonBase
                  className="subGroup"
                  onClick={() => openProjectSettings(project)}
                >
                  {project.name}
                </ButtonBase>
              ),
              height: 49,
              member: tmpGroup.member,
              project
            });
          }

          // generate PlannerSelector
          tmpGroup.subGroups.push({
            id: `selector${group.idx}`,
            type: tmpGroup.type,
            title: (
              <PlannerSelector
                placeholder="Assign to Project..."
                options={getAvailableProjects(tmpGroup.member)}
                getOptionLabel={(option: SelectorItem) => {
                  if (option.type === 'custom') {
                    return 'New project';
                  }

                  return option.project.name;
                }}
                onChange={getSelectorChangeHandler(group)}
              />
            ),
            height: 48,
            member: tmpGroup.member
          });
        } else if (isFoldOpen && tmpGroup.type === 'placeholder') {
          // generate Projects (subgroups) from planner data
          for (let j = 0; j < tmpGroup.member.projects.length; j += 1) {
            const project = tmpGroup.member.projects[j];
            tmpGroup.subGroups.push({
              id: `${tmpGroup.member.id},${project.id}`,
              type: tmpGroup.type,
              title: (
                <ButtonBase
                  className="subGroup"
                  onClick={() => openProjectSettings(project)}
                >
                  {project.name}
                </ButtonBase>
              ),
              height: 49,
              member: tmpGroup.member,
              project
            });
          }

          // generate PlannerSelector
          tmpGroup.subGroups.push({
            id: `selector${group.idx}`,
            type: tmpGroup.type,
            title: (
              <PlannerSelector
                placeholder="Assign to Project..."
                options={getAvailableProjects(tmpGroup.member)}
                getOptionLabel={(option: SelectorItem) => {
                  if (option.type === 'custom') {
                    return 'New project';
                  }

                  return option.project.name;
                }}
                onChange={getSelectorChangeHandler(group)}
              />
            ),
            height: 48,
            member: tmpGroup.member
          });
        } else {
          tmpGroup.subGroups = [];
        }

        setGroups(newGroups);
      };

      // generate PlannerMember
      flattened.push({
        id: group.member.id,
        title:
          group.type === 'user' ? (
            <PlannerMember
              user={group.member}
              openViewProjects={group.isOpen}
              setOpenViewProjects={foldToggle}
            />
          ) : (
            <PlannerMember
              placeholder={group.member}
              openViewProjects={group.isOpen}
              setOpenViewProjects={foldToggle}
            />
          )
      });

      for (let j = 0; j < group.subGroups.length; j += 1) {
        const subGroup = group.subGroups[j];
        flattened.push({
          id: subGroup.id,
          title: subGroup.title,
          height: subGroup.height
        });
      }
    }

    return flattened;
  }, [groups]);

  const weekIntervalRenderer = useCallback(
    (data: IntervalRenderer<Interval> | undefined) => {
      const startMonth = moment(
        data?.intervalContext.interval.startTime
      ).format('MMM');
      const endMonth = moment(data?.intervalContext.interval.endTime).format(
        'MMM'
      );

      const format =
        (data?.intervalContext.interval.labelWidth ?? 130) >= 130
          ? 'MMMM'
          : 'MMM';

      const week = data?.intervalContext.intervalText;
      const month =
        startMonth === endMonth
          ? moment(data?.intervalContext.interval.startTime).format(format)
          : `${startMonth} - ${endMonth}`;

      return (
        <div
          {...data?.getIntervalProps({ style: { height: '100%' } })}
          className={clsx('rct-dateHeader', 'weekInterval', {
            current: week === moment().format('W')
          })}
        >
          <div className="week">{week ?? null} </div>
          <div className="month">{data ? month : null} </div>
        </div>
      );
    },
    []
  );

  const dayIntervalRenderer = useCallback(
    (data: IntervalRenderer<Interval> | undefined) => {
      // TODO: extract `now` into outer scope for better performance?
      const now = moment();
      const thisWeek = now.format('W');
      const thisDay = now.format('D');

      const startTime = moment(data?.intervalContext.interval.startTime);
      const week = startTime.format('W');
      const day = data?.intervalContext.intervalText;

      return (
        <div
          {...data?.getIntervalProps()}
          className={clsx('rct-dateHeader', 'dayInterval', {
            firstDay: day === startTime.startOf('isoWeek').format('D'),
            currentWeek: week === thisWeek,
            currentDay: week === thisWeek && day === thisDay
          })}
        >
          <span>{day}</span>
        </div>
      );
    },
    []
  );

  const itemRenderer = (
    data: ReactCalendarItemRendererProps<TimelineAssignment>
  ) => {
    const data0 = data as {
      timelineContext: TimelineContext;
    } & ReactCalendarItemRendererProps<TimelineAssignment>;

    const { start_time: startTime, end_time: endTime, type } = data0.item;
    const { canvasTimeStart, canvasTimeEnd } = data0.timelineContext;
    const { selected } = data0.itemContext;

    // see https://github.com/namespace-ee/react-calendar-timeline/blob/master/src/lib/utility/calendar.js#L216
    const calculateCanvasWidth = () => {
      const effectiveStartTime = Math.max(startTime, canvasTimeStart);
      const effectiveEndTime = Math.min(endTime, canvasTimeEnd);

      const { width } = data0.itemContext.dimensions;
      const start = canvasTimeStart;
      const end = canvasTimeEnd;
      const range = end - start;

      const canvasWidth =
        width / ((effectiveEndTime - effectiveStartTime) / range);

      return canvasWidth;
    };

    const canvasWidth = calculateCanvasWidth();
    // see https://github.com/namespace-ee/react-calendar-timeline/blob/master/src/lib/utility/calendar.js#L28
    const calculateXPositionForTime = (time: number) => {
      const widthToZoomRatio = canvasWidth / (canvasTimeEnd - canvasTimeStart);
      const timeOffset = time - canvasTimeStart;

      return timeOffset * widthToZoomRatio;
    };

    const calculateTimeForXPosition = (leftOffset: number) => {
      const timeToPxRatio = (canvasTimeEnd - canvasTimeStart) / canvasWidth;
      const timeFromCanvasTimeStart = timeToPxRatio * leftOffset;

      return timeFromCanvasTimeStart + canvasTimeStart;
    };

    const calculateWidth = (
      left: moment.Moment | Date | number,
      right: moment.Moment | Date | number
    ) => {
      return (
        calculateXPositionForTime(right.valueOf()) -
        calculateXPositionForTime(left.valueOf())
      );
    };

    const visibleStart = Math.max(canvasTimeStart, startTime);
    const end = endTime;

    const firstWeekStart = moment(visibleStart).startOf('isoWeek');
    const firstWeekEnd = moment(visibleStart).endOf('isoWeek');
    const lastWeekStart = moment(end).startOf('isoWeek');
    const isSameWeek =
      endTime - startTime <= firstWeekEnd.valueOf() - firstWeekStart.valueOf();

    let visibleEnd = moment(end).endOf('isoWeek');
    let isEndVisible = true;

    if (canvasTimeEnd < end) {
      isEndVisible = false;
      visibleEnd = moment(canvasTimeEnd).startOf('isoWeek');
    }

    const singleWeekWidth = calculateWidth(startTime, endTime);
    const firstWeekWidth = calculateWidth(visibleStart, firstWeekEnd);
    const weekWidth = calculateWidth(firstWeekStart, firstWeekEnd);
    const lastWeekWidth = calculateWidth(lastWeekStart, endTime);

    let offset = 0;

    if (canvasTimeStart > startTime) {
      const absoluteFirstWeekEnd = moment(startTime).endOf('isoWeek');
      offset = calculateWeekCount(absoluteFirstWeekEnd, visibleStart);
    }

    const visibleWeekCount = calculateWeekCount(visibleStart, visibleEnd);

    const minWidth = 40;

    const generateWeek = (
      id: string | number,
      workTimeHours: number,
      state: 'first' | 'mid' | 'last' | 'single',
      empty = false
    ) => {
      let width = weekWidth;

      if (state === 'first') {
        width = firstWeekWidth;
      } else if (state === 'last') {
        width = lastWeekWidth;
      } else if (state === 'single') {
        width = singleWeekWidth;
      }

      let colorWorkTime: string = '';
      let workTimePercentage: number | undefined;

      if (type !== TimelineAssignmentType.NewAssignment) {
        const { weeklyCapacity } = data0.item;
        workTimePercentage =
          Math.round((workTimeHours / (weeklyCapacity ?? workTimeHours)) * 10) *
          10;
      }

      if (type === TimelineAssignmentType.User) {
        const { defaultLegalStatus } = data0.item;

        if (!workTimePercentage) {
          colorWorkTime = '';
        } else if (defaultLegalStatus === DefaultLegalStatus.contractor) {
          colorWorkTime = 'colorContractor';
        } else if (workTimePercentage === 0) {
          colorWorkTime = 'colorWorkTime11';
        } else if (workTimePercentage <= 10) {
          colorWorkTime = 'colorWorkTime10';
        } else if (workTimePercentage <= 20) {
          colorWorkTime = 'colorWorkTime9';
        } else if (workTimePercentage <= 30) {
          colorWorkTime = 'colorWorkTime8';
        } else if (workTimePercentage <= 40) {
          colorWorkTime = 'colorWorkTime7';
        } else if (workTimePercentage <= 50) {
          colorWorkTime = 'colorWorkTime6';
        } else if (workTimePercentage <= 60) {
          colorWorkTime = 'colorWorkTime5';
        } else if (workTimePercentage <= 70) {
          colorWorkTime = 'colorWorkTime4';
        } else if (workTimePercentage <= 80) {
          colorWorkTime = 'colorWorkTime3';
        } else if (workTimePercentage <= 90) {
          colorWorkTime = 'colorWorkTime2';
        }
      }

      return (
        <div
          key={id}
          className={clsx('week', colorWorkTime, {
            firstWeek: state === 'first',
            lastWeek: state === 'last',
            singleWeek: state === 'single'
          })}
          style={{ width }}
        >
          {!empty ? (
            <div className="weekLoadWrapper">
              <div className="workTimeHours">
                {type === TimelineAssignmentType.Project
                  ? `${data0.item.dailyHours} h/d ${workTimeHours}h`
                  : `${workTimeHours} hrs`}
              </div>
              {type === TimelineAssignmentType.Project &&
              (data0.item.project.plannerStatus === PlannerStatus.unpaid ||
                data0.item.project.plannerStatus === PlannerStatus.sick) ? (
                <div className="workTimeOff">Off</div>
              ) : null}
              {type === TimelineAssignmentType.User &&
              data0.item.defaultLegalStatus !==
                DefaultLegalStatus.contractor ? (
                <div className="workTimePercentage">{workTimePercentage}%</div>
              ) : null}
            </div>
          ) : (
            ''
          )}
        </div>
      );
    };

    const weeks = [];

    if (type !== TimelineAssignmentType.NewAssignment) {
      const { weeksWorkTime } = data0.item;

      if (isSameWeek && singleWeekWidth > minWidth) {
        weeks.push(generateWeek(0, weeksWorkTime[0], 'single'));
      } else {
        let idx = offset;
        weeks.push(
          generateWeek(
            0,
            weeksWorkTime[idx],
            'first',
            firstWeekWidth <= minWidth
          )
        );

        idx += 1;
        for (let i = 2; i < visibleWeekCount; i += 1) {
          if (weeksWorkTime.length <= idx) {
            break;
          }

          weeks.push(generateWeek(i, weeksWorkTime[idx], 'mid'));
          idx += 1;
        }

        if (
          isEndVisible &&
          lastWeekWidth > minWidth &&
          weeksWorkTime.length > idx
        ) {
          weeks.push(
            generateWeek(visibleWeekCount, weeksWorkTime[idx], 'last')
          );
        }
      }
    }

    let backgroundColor: string;

    if (type === TimelineAssignmentType.User) {
      backgroundColor = '#4caf50'; // $color-green
    } else {
      backgroundColor = selected
        ? '#ffb400' // $color-accent
        : data0.item.project.colour ?? '#f36d25'; // $color-primary
    }

    const handleMouseMove = (e: React.MouseEvent) => {
      const { left } = data0.itemContext.dimensions;
      const rect = e.currentTarget.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const time = moment(calculateTimeForXPosition(left + offsetX));

      const start = moment(time).startOf('day').valueOf();
      const halfADay = 10.5 * 60 * 60 * 1000;
      const dayStartEnd = (time.valueOf() - start - halfADay) / halfADay;

      const edgeTime =
        dayStartEnd < 0 ? start : moment(time).endOf('day').valueOf();
      const splitTime =
        dayStartEnd < 0
          ? start
          : moment(time).add(1, 'day').startOf('day').valueOf();

      const closeToBounds =
        Math.abs(edgeTime - data0.item.end_time) < halfADay ||
        Math.abs(edgeTime - data0.item.start_time) < halfADay;

      let cursorWrapper =
        e.currentTarget.querySelector<HTMLDivElement>('.cursorWrapper');

      if (!closeToBounds && Math.abs(dayStartEnd) > 0.6) {
        if (!cursorWrapper) {
          cursorWrapper = document.createElement('div');
          cursorWrapper.classList.add('cursorWrapper');
          cursorWrapper.ariaHidden = 'true';

          cursorWrapper.onclick = () => {
            if (type === TimelineAssignmentType.User) {
              const assignmentIds: string[] = [];

              for (let i = 0; i < data0.item.user.projects.length; i += 1) {
                const project = data0.item.user.projects[i];
                const filteredAssignments = project.intervals.filter(
                  (assignment) => {
                    const startAt = moment(assignment.startAt).valueOf();
                    const endAt = moment(assignment.endAt).valueOf();

                    return startAt < splitTime && splitTime < endAt;
                  }
                );

                if (filteredAssignments.length !== 1) {
                  // eslint-disable-next-line no-continue
                  continue;
                }

                assignmentIds.push(filteredAssignments[0].id);
              }

              if (data0.item.memberType === 'user') {
                plannerStore.splitPlannerAssignment(splitTime, assignmentIds);
              } else if (data0.item.memberType === 'placeholder') {
                plannerStore.splitPlaceholderAssignment(
                  splitTime,
                  assignmentIds
                );
              }
            } else if (type === TimelineAssignmentType.Project) {
              if (data0.item.memberType === 'user') {
                plannerStore.splitPlannerAssignment(splitTime, [
                  data0.item.assignment.id
                ]);
              } else if (data0.item.memberType === 'placeholder') {
                plannerStore.splitPlaceholderAssignment(splitTime, [
                  data0.item.assignment.id
                ]);
              }
            }
          };

          const cursor = document.createElement('img');
          cursor.id = 'cursor';
          cursor.src = SplitIcon;
          cursor.ondragstart = () => false;

          cursorWrapper.append(cursor);
          e.currentTarget.append(cursorWrapper);
        }

        const edgePosition = calculateXPositionForTime(edgeTime);
        cursorWrapper.style.left = `${edgePosition - left}px`;
      } else if (cursorWrapper) {
        e.currentTarget.removeChild(cursorWrapper);
      }
    };

    const handleMouseOut = (e: React.MouseEvent) => {
      if ((e.target as HTMLElement).id !== 'cursor') {
        return;
      }

      const cursorWrapper =
        e.currentTarget.querySelector<HTMLDivElement>('.cursorWrapper');

      if (cursorWrapper) {
        e.currentTarget.removeChild(cursorWrapper);
      }
    };

    const handleMouseDown = () => {
      if (selected) {
        isTimeChangeFrozen.current = true;
      }
    };

    const props = data.getItemProps({ onMouseDown: handleMouseDown });

    return (
      <div
        {...(type === TimelineAssignmentType.NewAssignment ||
        type === TimelineAssignmentType.Project
          ? {
              ...props,
              onMouseMove: selected ? handleMouseMove : undefined,
              onMouseOut: handleMouseOut
            }
          : {
              onMouseMove: handleMouseMove,
              onMouseOut: handleMouseOut
            })}
        style={{
          // eslint-disable-next-line react/prop-types
          ...props.style,
          backgroundColor,
          ...(type === TimelineAssignmentType.Project && data0.item.isGhost
            ? { opacity: 0.5 }
            : {})
        }}
        className={clsx({
          item: type === TimelineAssignmentType.User,
          subItem: type === TimelineAssignmentType.Project,
          newItem: type === TimelineAssignmentType.NewAssignment
        })}
      >
        {weeks}
      </div>
    );
  };

  const handleBoundsChange = (
    canvasTimeStart: number,
    canvasTimeEnd: number
  ) => {
    if (!scrollRef.current || !headerRef.current) {
      plannerStore.setVisibleTimeEndOffsetCallback(undefined);

      return;
    }

    plannerStore.setVisibleTimeEndOffsetCallback(
      boundedOffsetVisibleTimeEnd(
        canvasTimeStart,
        canvasTimeEnd,
        scrollRef.current.offsetWidth,
        headerRef.current.offsetWidth,
        86_400_000 // 1000 * 60 * 60 * 24
      )
    );
  };

  const handleTimeChange = (timeStart: number, timeEnd: number) => {
    if (isTimeChangeFrozen.current) {
      return;
    }

    plannerStore.setVisibleTimeStart(timeStart);
    plannerStore.setVisibleTimeEnd(timeEnd);
  };

  const handleCanvasClick = (groupId: string, time: number) => {
    const newAssignmentDuration = 172_800_000; // 2 * 24 * 60 * 60 * 1000
    const startAt = moment(time).startOf('day').valueOf();
    const endAt = startAt + newAssignmentDuration;

    const foundOverlapping = items.find(
      (item) =>
        item.group === groupId &&
        item.start_time <= time &&
        item.end_time >= time
    );

    if (
      foundOverlapping ||
      (newAssignment &&
        newAssignment.group === groupId &&
        newAssignment.start_time <= time &&
        newAssignment.end_time >= time)
    ) {
      return;
    }

    const flatted = groups.reduce(
      (acc: TimelineSubGroup[], cur) => acc.concat(cur.subGroups),
      []
    );
    const projectFound = flatted.find((group) => group.id === groupId);

    if (!projectFound || !projectFound.project) {
      return;
    }

    let assignment: TimelineAssignment | null;

    if (projectFound.type === 'user') {
      assignment = {
        id: `new:${groupId}`,
        user: projectFound.member,
        group: groupId,
        memberType: 'user',
        type: TimelineAssignmentType.NewAssignment,
        start_time: startAt,
        end_time: endAt,
        project: projectFound.project
      };
    } else {
      assignment = {
        id: `new:${groupId}`,
        user: projectFound.member,
        group: groupId,
        memberType: 'placeholder',
        type: TimelineAssignmentType.NewAssignment,
        start_time: startAt,
        end_time: endAt,
        project: projectFound.project
      };
    }

    items.push(assignment);
    setNewAssignment(assignment);
    setItems(items.filter((item) => item !== newAssignment));
  };

  const handleItemDoubleClick = (itemId: string) => {
    const foundItem = items?.find((item) => item.id === itemId);

    if (foundItem && foundItem.type !== TimelineAssignmentType.User) {
      setIsOpen(true);
      setActiveItem(foundItem);
    }
  };

  const updateAssignmentTime = (
    item: TimelineAssignment,
    startAt: number,
    endAt: number
  ) => {
    if (item.type !== TimelineAssignmentType.Project) {
      return;
    }

    if (item.memberType === 'user') {
      plannerStore.updateAssignmentPlannerUser({
        id: item.assignment.id,
        startAt: moment(startAt).format(),
        endAt: moment(endAt).format()
      });
    } else {
      plannerStore.updatePlaceholderAssignment({
        id: item.assignment.id,
        startAt: moment(startAt).format(),
        endAt: moment(endAt).format()
      });
    }
  };

  const handleItemResize = (
    itemId: string,
    time: number,
    edge: 'left' | 'right'
  ) => {
    const foundItemIndex = items.findIndex((item) => item.id === itemId);

    if (foundItemIndex === -1) {
      return;
    }

    const foundItem = items[foundItemIndex];

    const startTime = edge === 'left' ? time : foundItem.start_time;
    const endTime = edge === 'left' ? foundItem.end_time : time;

    if (foundItem.type === TimelineAssignmentType.Project) {
      updateAssignmentTime(foundItem, startTime, endTime);
    }

    const newItems = items.slice();
    newItems[foundItemIndex].start_time = startTime;
    newItems[foundItemIndex].end_time = endTime;
    setItems(newItems);
  };

  const handleItemMove = (itemId: string, dragTime: number) => {
    const foundItemIndex = items.findIndex((item) => item.id === itemId);

    if (foundItemIndex === -1) {
      return;
    }

    const foundItem = items[foundItemIndex];

    const startTime = dragTime;
    const endTime = dragTime + (foundItem.end_time - foundItem.start_time);

    if (foundItem.type === TimelineAssignmentType.Project) {
      updateAssignmentTime(foundItem, startTime, endTime);
    }

    const newItems = items.slice();
    newItems[foundItemIndex].start_time = startTime;
    newItems[foundItemIndex].end_time = endTime;
    setItems(newItems);
  };

  return (
    <Box className="calendarBox">
      {isFirstLoading.current && loading ? (
        <Loading table />
      ) : (
        <Timeline
          headerRef={(e: HTMLElement) => {
            headerRef.current = e;
          }}
          scrollRef={(e: HTMLElement) => {
            scrollRef.current = e;
          }}
          groups={flattenedGroups}
          items={items}
          itemRenderer={itemRenderer}
          itemTouchSendsClick={false}
          canChangeGroup={false}
          canResize="both"
          itemHeightRatio={1}
          lineHeight={64}
          defaultTimeStart={moment().startOf('week').toDate()}
          defaultTimeEnd={moment().startOf('month').add(1, 'month').toDate()}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          minZoom={minZoom}
          maxZoom={maxZoom}
          onBoundsChange={handleBoundsChange}
          onTimeChange={handleTimeChange}
          onCanvasClick={handleCanvasClick}
          onItemDoubleClick={handleItemDoubleClick}
          onItemResize={handleItemResize}
          onItemMove={handleItemMove}
        >
          <TimelineHeaders className="sticky" style={{ display: 'flex' }}>
            <SidebarHeader>
              {({ getRootProps }) => {
                return (
                  <div {...getRootProps()} className="sidebarHeader">
                    <TabsView
                      activeTab={activeUsersTab}
                      setActiveTab={handleSetActiveUsersTab}
                      tabs={['All', 'Employee', 'Contractor']}
                    />
                  </div>
                );
              }}
            </SidebarHeader>
            <DateHeader
              unit="isoWeek"
              labelFormat="W"
              height={33}
              intervalRenderer={weekIntervalRenderer}
            />
            <DateHeader
              unit="day"
              labelFormat="D"
              intervalRenderer={dayIntervalRenderer}
            />
          </TimelineHeaders>
        </Timeline>
      )}
      {!isFirstLoading.current || !loading ? (
        <TableFooter
          rowsLength={usersPagination?.totalItems ?? groups.length}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      ) : null}
      {activeItem && activeItem.type !== TimelineAssignmentType.User ? (
        <NewPlannerAssignment
          active={isOpen}
          setActive={setIsOpen}
          member={activeItem.user}
          project={activeItem.project}
          assignment={
            activeItem.type === TimelineAssignmentType.Project
              ? activeItem.assignment
              : undefined
          }
          assignmentStart={activeItem.start_time}
          assignmentEnd={activeItem.end_time}
          edit={activeItem.type !== TimelineAssignmentType.NewAssignment}
          isPlaceholder={activeItem.memberType === 'placeholder'}
          readOnly={
            activeItem.type === TimelineAssignmentType.Project &&
            activeItem.isGhost
          }
        />
      ) : null}
      {hasAdmin && selectedProject ? (
        <ProjectAdminModal
          item={selectedProject}
          isOpen={isProjectSettingsOpen}
          setOpen={setProjectSettingsOpen}
        />
      ) : null}
      {!hasAdmin && selectedProject ? (
        <ProjectManagerModal
          item={selectedProject}
          isOpen={isProjectSettingsOpen}
          setOpen={setProjectSettingsOpen}
        />
      ) : null}
      <AddProjectModal
        isOpen={isAddProjectModalOpen}
        setOpen={setAddProjectModalOpen}
      />
    </Box>
  );
};

export default observer(CalendarTimeline);
