export const boundedOffsetVisibleTimeEnd = (
  canvasTimeStart: number,
  canvasTimeEnd: number,
  timelineWidth: number,
  visibleWidth: number,
  offset: number = 0
) => {
  return (
    start: Date | number | undefined,
    end: Date | number | undefined
  ): Date | undefined => {
    if (!start || !end) {
      return undefined;
    }

    const canvasWidth = timelineWidth * 3;

    // TODO: turn into global functions
    const calculateTimeForXPosition = (leftOffset: number) => {
      const timeToPxRatio = (canvasTimeEnd - canvasTimeStart) / canvasWidth;
      const timeFromCanvasStart = timeToPxRatio * leftOffset;

      return timeFromCanvasStart + canvasTimeStart;
    };

    const getCanvasBoundaries = (_start: number, _end: number) => {
      const zoom = _end - _start;
      canvasTimeStart = _start - zoom;
      canvasTimeEnd = canvasTimeStart + zoom * 3;
    };

    let diff = 0;
    let prevCanvasTimeEnd = 0;
    let prevDiff = 0;
    do {
      prevCanvasTimeEnd = canvasTimeEnd;
      prevDiff = diff;

      diff =
        calculateTimeForXPosition(2 * timelineWidth) -
        calculateTimeForXPosition(timelineWidth + visibleWidth);

      getCanvasBoundaries(
        start.valueOf(),
        end.valueOf() + (diff - prevDiff) * 2
      );
    } while (Math.abs(canvasTimeEnd - prevCanvasTimeEnd) > 20_000_000);

    return new Date(end.valueOf() + diff + offset);
  };
};
