import React, { FC } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import BlockWrapper from 'components/BlockWrapper';

// Types
import {
  WorkspaceBlockProps,
  WorkspaceSettingsNames as Names
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import {
  AdminsOrAdminsPmOrAnyone,
  IWorkspaceSettingsPatch
} from 'types/workspaceSettings';

// Styles
import classes from './CreateTasks.module.scss';

const CreateTasks: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, handleChange } = useFormikContext<IWorkspaceSettingsPatch>();

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Who can create tasks</span>
            <span className={classes.description}>
              Allow people to create new tasks on their projects.
            </span>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.inputContainer}>
            <Box className={classes.row}>
              <RadioGroup
                row
                name={Names.canCreateTasks}
                onChange={(e) => {
                  handleChange(e);
                  onChange();
                }}
                value={values.canCreateTasks}
                defaultValue={values.canCreateTasks}
              >
                <FormControlLabel
                  control={<Radio />}
                  value={AdminsOrAdminsPmOrAnyone.ADMINS}
                  label={
                    <Typography className={classes.font}>Admins</Typography>
                  }
                />
                <FormControlLabel
                  control={<Radio />}
                  value={AdminsOrAdminsPmOrAnyone.ADMINSMANAGER}
                  label={
                    <Typography className={classes.font}>
                      Admins and project managers
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={<Radio />}
                  value={AdminsOrAdminsPmOrAnyone.ANYONE}
                  label={
                    <Typography className={classes.font}>Anyone</Typography>
                  }
                />
              </RadioGroup>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default CreateTasks;
