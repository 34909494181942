import React, { FC, SyntheticEvent, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';

import clsx from 'clsx';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { startOfMonth, endOfMonth } from 'date-fns';
import { DateRange } from 'react-day-picker';

// Stores
import plannerStore from 'stores/PlannerStore';

// Components
import DateRangePicker from 'components/DateRangePicker';

// Styles
import classes from './TabsView.module.scss';

interface TabsViewProps {
  placeholder?: string;
  tabs: string[];
  activeTab: number;
  setActiveTab: (newActiveTab: number) => void;
  isPlanner?: boolean;
}

const TabsView: FC<TabsViewProps> = ({
  placeholder,
  tabs,
  activeTab,
  setActiveTab,
  isPlanner
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [firstDate, setFirstDate] = useState<Date>(new Date());
  const [secondDate, setSecondDate] = useState<Date>(new Date());
  const [rangeType, setRangeType] = useState<string>('ThisMonth');
  const [dateRange, setDateRange] = useState<DateRange>({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date())
  });

  const handleDateRangeChange = useCallback((range: DateRange) => {
    plannerStore.resetVisibleRange(range.from, range.to);
    setDateRange(range);
  }, []);

  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  const calendarStateChange = useCallback(() => {
    setIsCalendarOpen((val) => !val);
  }, []);

  const onZoomClick = (direction: number) => {
    plannerStore.handleZoomButtons(direction);
  };

  const onArrowClick = (direction: number) => {
    plannerStore.handleArrowButtons(direction);
  };

  return (
    <Box
      className={clsx(classes.box, {
        [classes.boxWithoutBorder]: !placeholder
      })}
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        className={classes.tabs}
        textColor="inherit"
        TabIndicatorProps={{ sx: { bgcolor: '#f36d25' } }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab}
            className={classes.itemButton}
            label={
              <span
                className={classes.tab}
                style={{
                  color: activeTab === tabs.indexOf(tab) ? '#f36d25' : 'black'
                }}
              >
                {tab}
              </span>
            }
          />
        ))}
      </Tabs>
      {!isPlanner ? null : (
        <Box className={classes.inputBox}>
          <div className={classes.buttonsZoom}>
            <button
              type="button"
              className={classes.buttonItem}
              onClick={() => onZoomClick(-1)}
            >
              <AddIcon />
            </button>
            <button
              type="button"
              className={classes.buttonItem}
              onClick={() => onZoomClick(1)}
            >
              <RemoveIcon />
            </button>
          </div>
          <div className={classes.buttonCalendar}>
            <button
              type="button"
              onClick={calendarStateChange}
              className={
                isCalendarOpen ? classes.calendarActive : classes.buttonItem
              }
            >
              <CalendarMonthIcon /> Select date range <ArrowDropDownIcon />
            </button>
            <DateRangePicker
              isOpen={isCalendarOpen}
              onClose={setIsCalendarOpen}
              firstDate={firstDate}
              onFirstDateChange={setFirstDate}
              secondDate={secondDate}
              onSecondDateChange={setSecondDate}
              rangeType={rangeType}
              onRangeTypeChange={setRangeType}
              datesRange={dateRange}
              onDatesRangeChange={handleDateRangeChange}
            />
          </div>
          <div className={classes.buttonsArrow}>
            <button
              type="button"
              className={classes.buttonItem}
              onClick={() => onArrowClick(-1)}
            >
              <KeyboardArrowLeftIcon />
            </button>
            <button
              type="button"
              className={classes.buttonItem}
              onClick={() => onArrowClick(1)}
            >
              <KeyboardArrowRightIcon />
            </button>
          </div>
        </Box>
      )}
    </Box>
  );
};

export default observer(TabsView);
