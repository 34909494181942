import { action, makeObservable, observable, runInAction } from 'mobx';

// Types
import {
  ITimeTrackerClockifyRecord,
  ITimeTrackerRecord
} from 'types/timetrackerRecord';

// API
import {
  getTimesheetClockifyRecords,
  getTimesheetRecords
} from 'api/timesheets';

type AvailableSort =
  | 'project'
  | 'task'
  | 'description'
  | 'billable'
  | 'name'
  | 'time'
  | 'duration';

class TimesheetsStore {
  @observable
  records: ITimeTrackerRecord[] = [];

  @observable
  sort: AvailableSort = 'name';

  @observable
  sortDir: 1 | -1 = 1;

  @observable
  clockifyRecords: ITimeTrackerClockifyRecord[] = [];

  @observable
  clockifySort: AvailableSort = 'name';

  @observable
  clockifySortDir: 1 | -1 = 1;

  @observable
  loading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  loadRecords = async (from: Date, to: Date) => {
    try {
      this.loading = true;

      const data = await getTimesheetRecords(from, to);

      runInAction(() => {
        this.records = data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  setSort = (newSort: AvailableSort): void => {
    this.sortDir = (this.sort === newSort ? -this.sortDir : 1) as 1 | -1;
    this.sort = newSort;
  };

  @action
  loadClockifyRecords = async (from: Date, to: Date) => {
    try {
      this.loading = true;

      const data = await getTimesheetClockifyRecords(from, to);

      runInAction(() => {
        this.clockifyRecords = data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  setClockifySort = (newSort: AvailableSort): void => {
    this.clockifySortDir = (
      this.clockifySort === newSort ? -this.clockifySortDir : 1
    ) as 1 | -1;
    this.clockifySort = newSort;
  };
}

const timesheetsStore = new TimesheetsStore();

export default timesheetsStore;
