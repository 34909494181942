import React, { FC } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import BlockWrapper from 'components/BlockWrapper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Types
import {
  WorkspaceSettingsNames as Names,
  WorkspaceBlockProps
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import { IWorkspaceSettingsPatch } from 'types/workspaceSettings';
import { ContractCurrencyVariant } from 'types/rate';

// Styles
import classes from './Currency.module.scss';

const Currency: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, handleChange } = useFormikContext<IWorkspaceSettingsPatch>();

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Currency</span>
            <span className={classes.description}>
              The currency in which the billable and cost amounts will be
              displayed in reports.
            </span>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.column}>
            <Box className={classes.inputContainer}>
              <Box className={classes.wrapper}>
                <Select
                  name={Names.currency}
                  value={values.currency}
                  variant="standard"
                  className={classes.input}
                  fullWidth
                  onChange={(e) => {
                    handleChange(e);
                    onChange();
                  }}
                >
                  {Object.values(ContractCurrencyVariant).map((value) => {
                    return <MenuItem value={value}>{value}</MenuItem>;
                  })}
                </Select>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default Currency;
