import React, { FC, useState } from 'react';

import { ButtonType } from 'components/ButtonView/ButtonView';

// Components
import TitleManagementPages from 'components/TitleManagementPages';
import CalendarTimeline from './CalendarTimeline';
import ProjectsTimeline from './ProjectsTimeline';

// Styles
import classes from './NewPlanner.module.scss';

const NewPlanner: FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <div className={classes.wrapper}>
      <TitleManagementPages
        title="Planner"
        buttonLabel={activeTab === 0 ? 'Add person' : 'Add project'}
        buttonType={
          activeTab === 0 ? ButtonType.PlannerTeam : ButtonType.PlannerProjects
        }
        placeholder="Search member..."
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={['Team', 'Projects']}
        isPlanner
      />
      <div className={classes.tableView}>
        {activeTab === 0 ? <CalendarTimeline /> : <ProjectsTimeline />}
      </div>
    </div>
  );
};

export default NewPlanner;
