import axios from 'api/helpers/axios';

// Types
import {
  IClockifyWorkspace,
  IWorkspaceSettings,
  IWorkspaceSettingsPatch
} from 'types/workspaceSettings';

interface IWorkspaceSettingsResponse {
  data: IWorkspaceSettings;
}

interface IClockifyWorkspacesResponse {
  data: IClockifyWorkspace[];
}

export const getWorkspaceSettings = (): Promise<IWorkspaceSettings> => {
  return axios
    .get<IWorkspaceSettingsResponse>('/workspace')
    .then((response) => {
      return response.data.data;
    });
};

export const patchWorkspaceSettings = (
  request: IWorkspaceSettingsPatch
): Promise<IWorkspaceSettings> => {
  return axios
    .patch<IWorkspaceSettingsResponse>('/workspace', request)
    .then((response) => {
      return response.data.data;
    });
};

export const getClockifyWorkspaces = (): Promise<IClockifyWorkspace[]> => {
  return axios
    .get<IClockifyWorkspacesResponse>('/workspace/clockify')
    .then((response) => {
      return response.data.data;
    });
};
