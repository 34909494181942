import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

// Stores
import timeTrackerStore from 'stores/TimeTrackerStore';
import workspaceSettingsStore from 'stores/WorkspaceSettingsStore';

// Utils
import {
  divideRecordsByWeeks,
  formatTime,
  groupRecordsByDays
} from 'utils/timetrackerUtils';

// Styles
import { DurationFormat } from 'types/workspaceSettings';
import classes from './RecordItemList.module.scss';

// Components
import RecordItem from './RecordItem';

// Types

const RecordItemList: FC = () => {
  const timeFormatName =
    workspaceSettingsStore.workspaceSettings?.durationFormat;
  let timeFormat: string = 'HH:mm:ss';

  if (timeFormatName === DurationFormat.FULL) {
    timeFormat = 'HH:mm:ss';
  } else if (timeFormatName === DurationFormat.COMPACT) {
    timeFormat = 'HH:mm';
  } else if (timeFormatName === DurationFormat.DECIMAL) {
    timeFormat = 'HH.mm';
  }

  useEffect(() => {
    timeTrackerStore.getRecords();
    timeTrackerStore.getProjectTasks();
  }, []);
  const { records } = timeTrackerStore;
  const dividedRecords = divideRecordsByWeeks(records);

  return (
    <>
      {Object.keys(dividedRecords).map((category) => {
        if (dividedRecords[category].length === 0) {
          return null;
        }

        const totalWeekTime = dividedRecords[category].reduce(
          (prev, next) => prev + next.totalTime,
          0
        );

        return (
          <div key={category}>
            <div className={classes.categoryContainer}>
              <div>{category}</div>
              <div>
                Week total: <span>{formatTime(totalWeekTime, timeFormat)}</span>
              </div>
            </div>
            {Object.keys(groupRecordsByDays(dividedRecords[category])).map(
              (day) => (
                <RecordItem
                  records={groupRecordsByDays(dividedRecords[category])[day]}
                  timeFormat={timeFormat}
                />
              )
            )}
          </div>
        );
      })}
    </>
  );
};

export default observer(RecordItemList);
