import { action, makeObservable, observable, runInAction } from 'mobx';
import { IAnswer } from 'types/commonInterfaces';

class AlertsStore {
  @observable
  alerts: {
    message: string;
    severity: 'error' | 'info' | 'success' | 'warning';
  }[] = [];

  @observable
  isAlertsOpen: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  createAlert = (
    severity: 'error' | 'info' | 'success' | 'warning',
    message: string
  ) => {
    runInAction(() => {
      if (this.alerts.length > 3) {
        this.alerts = this.alerts.slice(this.alerts.length - 3);
      }

      this.alerts.push({ severity, message });
      this.isAlertsOpen = true;
    });
  };

  @action
  clearAlerts = () => {
    runInAction(() => {
      this.alerts = [];
    });
  };

  @action
  closeAlert = (message: string) => {
    runInAction(() => {
      this.alerts = this.alerts.filter(
        (alertData) => alertData.message !== message
      );
    });
  };

  @action
  addLoadingAlert = (message?: string) => {
    runInAction(() => {
      this.createAlert('info', message ?? 'Loading...');
    });
  };

  @action
  handleAnswer = (data: IAnswer) => {
    runInAction(() => {
      if (data.isLoading) {
        this.addLoadingAlert(data.message);
      } else {
        this.createAlert(
          data.success === true ? 'success' : 'error',
          data.message
        );
      }
    });
  };

  @action
  showAlerts = (b: boolean) => {
    runInAction(() => {
      this.isAlertsOpen = b;
    });
  };
}

const alertsStore = new AlertsStore();

export default alertsStore;
