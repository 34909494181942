import React, { FC, useState } from 'react';
import { useFormik } from 'formik';

// Components
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import BlockWrapper from 'components/BlockWrapper';

// Icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Stores
import authStore from 'stores/AuthStore';
import profileStore from 'stores/ProfileStore';

// Styles
import classes from './PasswordSettings.module.scss';

const PasswordSettings: FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);

  const [isEdit, setIsEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      newPassword: ''
    },
    onSubmit: ({ password, newPassword }) => {
      if (!password?.length || !newPassword?.length) {
        return;
      }

      setIsEdit(false);
      authStore.updatePassword({
        userId: profileStore.profile?.id as string,
        deviceId: authStore.uuid as string,
        password,
        newPassword
      });
    }
  });

  const onChangeHandler = () => {
    setIsEdit(true);
  };

  const cancelHandler = () => {
    formik.values.password = '';
    formik.values.newPassword = '';
    setIsEdit(false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <BlockWrapper>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Password</span>
            <span className={classes.description}>
              In addition to Google log-in, you can also log in using email and
              password.
            </span>
          </Box>
          <Box className={classes.FieldsContainer}>
            <Box className={classes.inputContainer}>
              <span className={classes.label}>Current password</span>
              <TextField
                name="password"
                value={formik.values.password}
                color="primary"
                type={showPassword ? 'text' : 'password'}
                variant="standard"
                fullWidth
                InputLabelProps={{
                  className: classes.input
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onChange={(e) => {
                  formik.handleChange(e);
                  onChangeHandler();
                }}
              />
            </Box>
            <Box className={classes.inputContainer}>
              <span className={classes.label}>New password</span>
              <TextField
                name="newPassword"
                value={formik.values.newPassword}
                color="primary"
                type={showNewPassword ? 'text' : 'password'}
                variant="standard"
                fullWidth
                required
                InputLabelProps={{
                  className: classes.input
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowNewPassword}>
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onChange={(e) => {
                  formik.handleChange(e);
                  onChangeHandler();
                }}
              />
            </Box>
          </Box>
          <Box className={classes.buttonBox}>
            <Button
              className={isEdit ? classes.editCancel : classes.cancel}
              disabled={!isEdit}
              onClick={cancelHandler}
            >
              CANCEL
            </Button>
            <Button
              className={isEdit ? classes.editSave : classes.save}
              disabled={!isEdit}
              type="submit"
            >
              SAVE
            </Button>
          </Box>
        </Box>
      </BlockWrapper>
    </form>
  );
};

export default PasswordSettings;
