import React, { FC, useCallback, useState } from 'react';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

// Icons
import Close from '@mui/icons-material/Close';

// Layouts
import Profile from 'layouts/ProfileLayout';

// Types
import { ExpertizeVariant } from 'types/user';

// Styles
import classes from './AddPlaceholderModal.module.scss';

interface AddPlaceholderProps {
  isOpen: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  expertises?: { value: string }[];
  onSubmit?: (name: string, expertise: string) => void;
  onCancel?: () => void;
}

const defaultExpertises = Object.values(ExpertizeVariant).map((item) => ({
  value: item
}));

const AddPlaceholder: FC<AddPlaceholderProps> = ({
  isOpen,
  setOpen,
  expertises = defaultExpertises,
  onSubmit,
  onCancel
}) => {
  const [placeholderName, setPlaceholderName] = useState('');
  const [expertise, setExpertise] = useState('');
  const [selectedField, setSelectedField] = useState('');

  const closeModal = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const handleSubmit = useCallback(() => {
    closeModal();

    if (onSubmit && placeholderName && expertise) {
      onSubmit(placeholderName, expertise);
    } else if (onCancel) {
      // TODO: validation
      onCancel();
    }
  }, [onSubmit, placeholderName, expertise]);

  const handleCancel = useCallback(() => {
    closeModal();

    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const expertiseChange = useCallback((name: string) => {
    setExpertise(name.trim());
  }, []);

  const getOptionLabel = useCallback((option: { value: string } | string) => {
    if (typeof option === 'string') {
      return option;
    }

    return option.value;
  }, []);

  return (
    <Profile isOpen={isOpen} handleClose={handleCancel}>
      <Grid container className={classes.nameContainer}>
        <Grid item xs={11}>
          <h2>Add placeholder</h2>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={closeModal}>
            <Close className={classes.close} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.box}>
        <Typography className={classes.tipContainer}>
          Tip: Placeholder names should align with roles you need on a project
          (e.g. &quot;Designer&quot;). Our Placeholder Guide has more on how to
          use them effectively.
        </Typography>
      </Grid>

      <Grid container spacing={1} className={classes.box}>
        <Grid item xs={4}>
          <div
            className={clsx(classes.name, {
              [classes.selectedName]: selectedField === 'placeholderName'
            })}
          >
            Placeholder name
          </div>
        </Grid>
        <Grid item xs={8}>
          <Autocomplete
            freeSolo
            options={expertises ?? []}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <TextField {...params} placeholder="Anybody" variant="standard" />
            )}
            renderOption={(props, option) => (
              <Box
                sx={{
                  marginLeft: 0,
                  fontSize: '16px'
                }}
                component="li"
                {...props}
              >
                {getOptionLabel(option)}
              </Box>
            )}
            value={placeholderName}
            onInputChange={(_, value) => setPlaceholderName(value.trim())}
            onFocus={() => setSelectedField('placeholderName')}
            onBlur={() => setSelectedField('')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.box}>
        <Grid item xs={4}>
          <div
            className={clsx(classes.name, {
              [classes.selectedName]: selectedField === 'expertize'
            })}
          >
            Expertise
          </div>
        </Grid>
        <Grid item xs={8}>
          <TextField
            id="standard-basic"
            variant="standard"
            select
            fullWidth
            color="primary"
            InputProps={{
              classes: {
                root: classes.font
              }
            }}
            onChange={(e) => expertiseChange(e.target.value)}
            onFocus={() => setSelectedField('expertize')}
            onBlur={() => setSelectedField('')}
            value={expertise}
          >
            {expertises?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <div className={classes.buttonBox}>
        <Button className={classes.cancel} onClick={handleCancel}>
          CANCEL
        </Button>
        <Button className={classes.add} onClick={handleSubmit}>
          ADD
        </Button>
      </div>
    </Profile>
  );
};

export default AddPlaceholder;
