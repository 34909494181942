import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

// Types
import { IMemberPatch } from 'types/member';
import { DefaultLegalStatus, User } from 'types/user';

// Utils
import { sortingByField } from 'utils/sorting';

// API
import { getMembers, refreshMember } from 'api/members';
import { addSalary } from 'api/salary';
import { addRate } from 'api/userRate';

// Stores
import alertsStore from './alertsStore';

type AvailableSort =
  | 'name'
  | 'email'
  | 'defaultLevel'
  | 'defaultExpertize'
  | 'defaultRate';

class MembersStore {
  @observable
  members: User[] = [];

  @observable
  loading: boolean = false;

  @observable
  query: string = '';

  @observable
  sort: AvailableSort = 'name';

  @observable
  sortDir: 1 | -1 = 1;

  @observable
  updatedMember?: User;

  constructor() {
    makeObservable(this);
  }

  @action
  setQuery = (query: string): void => {
    this.query = query;
  };

  @action
  setSort = (newSort: AvailableSort): void => {
    this.sortDir = (this.sort === newSort ? -this.sortDir : 1) as 1 | -1;
    this.sort = newSort;
  };

  @computed
  get filteredMembers(): User[] {
    return sortingByField(
      this.members.filter((member) =>
        member.name.toLowerCase().includes(this.query.toLowerCase())
      ),
      this.sort as keyof User,
      this.sortDir
    );
  }

  @action
  loadMembers = async () => {
    try {
      this.loading = true;

      const { data } = await getMembers();

      runInAction(() => {
        this.members = data.data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  addSalary = async (
    userId: string,
    salary: number,
    startAt: Date,
    coefficients: string[]
  ) => {
    try {
      this.loading = true;
      alertsStore.addLoadingAlert('Adding salary');

      await addSalary(userId, salary, startAt, coefficients);

      this.loadMembers();
      alertsStore.handleAnswer({
        success: true,
        message: `Monthly salary is successfully added! Time entries from ${startAt.toLocaleDateString()}`
      });
    } catch (error) {
      console.error(error);
    } finally {
      alertsStore.closeAlert('Adding salary');
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  addRate = async (
    userId: string,
    rate: number,
    startAt: Date,
    currency?: number
  ) => {
    try {
      this.loading = true;

      alertsStore.addLoadingAlert('Adding rate');

      await addRate(userId, rate, startAt, currency);

      this.loadMembers();

      alertsStore.handleAnswer({
        success: true,
        message: `Rate is successfully added! Time entries from ${startAt.toLocaleDateString()}`
      });
    } catch (error) {
      console.error(error);
    } finally {
      alertsStore.closeAlert('Adding rate');
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  updateMembers = async (member: IMemberPatch) => {
    try {
      this.loading = true;

      alertsStore.addLoadingAlert('Updating user info');

      const data = await refreshMember(member);

      runInAction(() => {
        this.updatedMember = data;

        if (this.members) {
          const index = this.members.findIndex((x) => x.id === member.userId);
          this.members.map((user) => {
            if (user.id === member.userId) {
              const newUser = data;

              if (newUser.defaultLegalStatus !== user.defaultLegalStatus) {
                newUser.defaultRate = 0;

                return newUser;
              }

              newUser.defaultRate = user.defaultRate;

              if (member.defaultLegalStatus === DefaultLegalStatus.employee) {
                newUser.salary = user.salary;
              }

              if (member.defaultLegalStatus === DefaultLegalStatus.contractor) {
                newUser.rate = user.rate;
              }

              return newUser;
            }

            return user;
          });
          this.members.splice(index, 1, data);
        }
      });

      alertsStore.handleAnswer({
        success: true,
        message: `User ${
          member.name?.concat(' ') ?? ''
        }is successfully updated!`
      });
    } catch (error) {
      console.log(error);
    } finally {
      alertsStore.closeAlert('Updating user info');
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  getMemberById(id: string): User {
    return this.members.find((member) => member.id === id) ?? ({} as User);
  }
}

const membersStore = new MembersStore();

export default membersStore;
