import React, { FC } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import BlockWrapper from 'components/BlockWrapper';

// Types
import {
  WorkspaceBlockProps,
  WorkspaceSettingsNames as Names
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import {
  IWorkspaceSettingsPatch,
  Billability,
  Privacy
} from 'types/workspaceSettings';

// Styles
import classes from './NewProjects.module.scss';

const NewProjects: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, handleChange } = useFormikContext<IWorkspaceSettingsPatch>();

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>New projects are by default</span>
            <span className={classes.description}>
              {`When you create a project, make it public so it's available to
                all users, and billable so its time entries are set as billable.`}
            </span>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.column}>
            <Box className={classes.inputContainer}>
              <Box className={classes.row}>
                <RadioGroup
                  row
                  name={Names.defaultBillability}
                  defaultValue={values.defaultBillability}
                  value={values.defaultBillability}
                  onChange={(e) => {
                    handleChange(e);
                    onChange();
                  }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    value={Billability.NON_BILLABLE}
                    label={
                      <Typography className={classes.font}>
                        Non-billable
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value={Billability.BILLABLE}
                    label={
                      <Typography className={classes.font}>Billable</Typography>
                    }
                  />
                </RadioGroup>
              </Box>
            </Box>
            <Box className={classes.inputContainer}>
              <Box className={classes.row}>
                <RadioGroup
                  row
                  name={Names.defaultPrivacy}
                  defaultValue={values.defaultPrivacy}
                  value={values.defaultPrivacy}
                  onChange={(e) => {
                    handleChange(e);
                    onChange();
                  }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    value={Privacy.PUBLIC}
                    label={
                      <Typography className={classes.font}>Public</Typography>
                    }
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value={Privacy.PRIVATE}
                    label={
                      <Typography className={classes.font}>Private</Typography>
                    }
                  />
                </RadioGroup>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default NewProjects;
