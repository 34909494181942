import React, { FC } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import BlockWrapper from 'components/BlockWrapper';

// Types
import {
  WorkspaceBlockProps,
  WorkspaceSettingsNames as Names
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import {
  AdminsOrAdminsAndPM,
  IWorkspaceSettingsPatch
} from 'types/workspaceSettings';

// Styles
import classes from './EditForOthers.module.scss';

const EditForOthers: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, handleChange } = useFormikContext<IWorkspaceSettingsPatch>();

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Who can edit time for others</span>
            <span className={classes.description}>
              Who can edit time for others
            </span>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.inputContainer}>
            <Box className={classes.row}>
              <RadioGroup
                row
                name={Names.canEditElsesTime}
                onChange={(e) => {
                  handleChange(e);
                  onChange();
                }}
                value={values.canEditElsesTime}
                defaultValue={values.canEditElsesTime}
              >
                <FormControlLabel
                  control={<Radio />}
                  value={AdminsOrAdminsAndPM.ADMINS}
                  label={
                    <Typography className={classes.font}>Admins</Typography>
                  }
                />
                <FormControlLabel
                  control={<Radio />}
                  value={AdminsOrAdminsAndPM.ADMINS_AND_PM}
                  label={
                    <Typography className={classes.font}>
                      Admins and project managers
                    </Typography>
                  }
                />
              </RadioGroup>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default EditForOthers;
