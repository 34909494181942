import axios from 'api/helpers/axios';

// Types
import { IProjectWithTasks, ITimeTrackerRecord } from 'types/timetrackerRecord';

export interface ITimeTrackerTasksResponse {
  data: ITimeTrackerRecord[];
}

interface ITimeTrackerTaskResponse {
  data: ITimeTrackerRecord;
}

interface ITaskListResponse {
  data: IProjectWithTasks[];
}

interface IAddTaskResponse {
  data: {
    id: string;
    projectId: string;
    title: string;
  };
}

interface IStringResponse {
  data: string;
}

export const getRecords = (): Promise<ITimeTrackerRecord[]> => {
  return axios
    .get<ITimeTrackerTasksResponse>(`/timetracker`)
    .then((response) => {
      return response.data.data;
    });
};

export const addRecord = async (
  projectId: string,
  title: string | null,
  expertiseId: string | null,
  description: string,
  billable: boolean,
  startedAt: Date,
  endedAt?: Date
) =>
  axios.post<ITimeTrackerTaskResponse>(`/timetracker/${projectId}`, {
    title,
    expertiseId,
    description,
    billable,
    startedAt,
    endedAt
  });

export const getTasks = async () =>
  axios.get<ITaskListResponse>(`/timetracker/task`).then((response) => {
    return response.data.data;
  });

export const addTask = async (projectId: string, title: string) =>
  axios
    .post<IAddTaskResponse>(`/timetracker/task/${projectId}`, {
      title
    })
    .then((response) => response.data.data);

export const patchTask = async (id: string, title: string) =>
  axios
    .patch<IStringResponse>('/timetracker/task', {
      id,
      title
    })
    .then((response) => response.data.data);

export const deleteTask = async (id: string) =>
  axios
    .delete<IStringResponse>('/timetracker/task', {
      data: {
        id
      }
    })
    .then((response) => response.data.data);

export const deleteRecords = async (ids: string[]) =>
  axios
    .delete<IStringResponse>('/timetracker', {
      data: {
        ids
      }
    })
    .then((response) => response.data.data);

export const patchRecords = async (
  ids: string[],
  record: {
    projectId: string | undefined;
    title: string | null;
    expertiseId: string | null;
    description: string;
    billable: boolean;
    startedAt: Date;
    endedAt: Date | undefined;
  }
) =>
  axios
    .patch<ITimeTrackerTasksResponse>('/timetracker', {
      ids,
      ...record
    })
    .then((response) => response.data.data);

export const duplicateRecord = async (id: string) =>
  axios
    .post<ITimeTrackerTaskResponse>('/timetracker/duplicate', {
      id
    })
    .then((response) => response.data.data);
