import React, { FC, useRef, useState } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import BlockWrapper from 'components/BlockWrapper';

// Types
import {
  WorkspaceSettingsNames as Names,
  WorkspaceBlockProps
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import { IWorkspaceSettingsPatch } from 'types/workspaceSettings';

// Styles
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import classes from './TimeRounding.module.scss';

const minutes = [1, 5, 10, 15, 20, 30, 60];

const TimeRounding: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, setFieldValue } = useFormikContext<IWorkspaceSettingsPatch>();

  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLElement | null>(null);

  const clickHandler = () => {
    setIsOpen(true);
  };

  const closeHandler = () => {
    setIsOpen(false);
  };

  const itemClickHandler = (value: number) => {
    setFieldValue(Names.timeRounding, value);
    closeHandler();
    onChange();
  };

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Time rounding</span>
            <span className={classes.description}>
              Round time in reports up, down, or to nearest X minutes.
            </span>
          </Box>
          <Box>
            <span className={classes.beforeField}>Round to nearest, </span>
            <span
              className={classes.field}
              ref={anchorRef}
              onClick={clickHandler}
            >
              {`${values.timeRounding as number} minutes`}
            </span>
            <Menu
              anchorEl={anchorRef.current}
              open={isOpen}
              onClose={closeHandler}
            >
              {minutes.map((value) => {
                return (
                  <MenuItem onClick={() => itemClickHandler(value)}>
                    {value}
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default TimeRounding;
