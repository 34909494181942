import React, { MouseEvent, SyntheticEvent, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

// Components
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

// Styles
import classes from './PlannerSelector.module.scss';

interface PlannerSelectorProps<Option> {
  fullWidth?: boolean;
  options: Option[];
  getOptionLabel: (option: Option) => string;
  placeholder: string;
  onChange?: (option: Option) => void | boolean;
}

const PlannerSelector = <Option,>({
  fullWidth,
  options,
  getOptionLabel,
  placeholder,
  onChange
}: PlannerSelectorProps<Option>): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const availableOptions = useMemo(
    () =>
      options.filter(
        (option) => !selectedOptions.find((val) => val === option)
      ),
    [options, selectedOptions]
  );

  const handleChange = (event: SyntheticEvent, option: Option | null) => {
    if (!onChange || !option) {
      return;
    }

    const isSelected = onChange(option);

    if (isSelected === undefined || isSelected) {
      setSelectedOptions((prev) => prev.concat(option));
    }

    setSearchValue('');
    setOpen(false);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <div className={classes.select}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Autocomplete
          open={isOpen}
          options={availableOptions}
          getOptionLabel={getOptionLabel}
          value={null}
          inputValue={searchValue}
          onInputChange={(event, value) => setSearchValue(value)}
          sx={{
            width: '100%',
            maxWidth: fullWidth ? '100%' : '190px',
            paddingLeft: fullWidth ? '3px' : '0'
          }}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              value={searchValue}
              placeholder={placeholder}
              style={{ height: '28px' }}
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchIcon className={classes.searchIcon} />,
                endAdornment: (
                  <Box
                    component="button"
                    className={classes.buttonArrow}
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                      setOpen((prev) => !prev);
                      e.stopPropagation();
                    }}
                  >
                    {isOpen ? (
                      <KeyboardArrowUp className={classes.arrowIcon} />
                    ) : (
                      <KeyboardArrowDown className={classes.arrowIcon} />
                    )}
                  </Box>
                )
              }}
              onClick={() => setOpen(true)}
              onFocus={() => setOpen(true)}
              onBlur={() => setOpen(false)}
            />
          )}
          renderOption={(props, option) => (
            <Box
              sx={{
                marginLeft: 0,
                fontSize: '16px'
              }}
              component="li"
              {...props}
            >
              {getOptionLabel(option)}
            </Box>
          )}
        />
      </ClickAwayListener>
    </div>
  );
};

export default observer(PlannerSelector);
