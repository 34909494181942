import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import Box from '@mui/material/Box';

// Icons
import { ReactComponent as Logo } from 'assets/icons/downloadLogo.svg';

// Styles
import classes from './PlaceholderAvatar.module.scss';

const PlaceholderAvatar: FC = () => {
  return (
    <div>
      <Box className={classes.avatar}>
        <Logo className={classes.logo} />
      </Box>
    </div>
  );
};

export default observer(PlaceholderAvatar);
