export enum WorkspaceSettingsNames {
  companyName = 'companyName',
  companyLogo = 'companyLogo',
  workDays = 'workDays',
  workspaceClockifyId = 'workspaceClockifyId',
  clockifyApiKey = 'clockifyApiKey',
  defaultPrivacy = 'defaultPrivacy',
  defaultBillability = 'defaultBillability',
  durationFormat = 'durationFormat',
  isActivateBillableHours = 'isActivateBillableHours',
  isActivateFavorites = 'isActivateFavorites',
  currency = 'currency',
  numberFormat = 'numberFormat',
  currencyFormat = 'currencyFormat',
  requiredForTask = 'requiredForTask',
  timeRounding = 'timeRounding',
  canSeeHourlyRates = 'canSeeHourlyRates',
  canChangeBillable = 'canChangeBillable',
  canCreateProjectsClients = 'canCreateProjectsClients',
  canCreateTasks = 'canCreateTasks',
  timeVisibility = 'timeVisibility',
  hidedPages = 'hidedPages',
  canEditElsesTime = 'canEditElsesTime',
  isLockTime = 'isLockTime',
  lockTimeBefore = 'lockTimeBefore',
  isLockTimeAutoUpdate = 'isLockTimeAutoUpdate',
  isForceTimer = 'isForceTimer'
}

export enum RequiredForTaskNames {
  project = 'project',
  task = 'task',
  tag = 'tag',
  description = 'description'
}

export enum HidedPagesNames {
  management = 'management',
  reports = 'reports',
  planner = 'planner',
  invoices = 'invoices',
  expences = 'expences'
}

export interface WorkspaceBlockProps {
  onChange: () => void;
}
