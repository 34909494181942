import React, { FC, useState } from 'react';
import moment from 'moment';

// Components
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Icons
import { ReactComponent as EditIcon } from 'assets/icons/pen2.svg';

// Types
import { ITimeTrackerRecord } from 'types/timetrackerRecord';

// Stores
import timeTrackerStore from 'stores/TimeTrackerStore';

// Utils
import { formatTime } from 'utils/timetrackerUtils';

// Components
import Checkbox from '@mui/material/Checkbox';

// Components
import EditRecordsModal from 'components/modals/EditRecordsModal';
import SingleRecord from './SingleRecord/SingleRecord';

// Styles
import classes from './RecordItem.module.scss';

interface RecordItemProps {
  records: ITimeTrackerRecord[];
  timeFormat?: string;
}

const RecordItem: FC<RecordItemProps> = (props) => {
  const { records, timeFormat } = props;
  const [isBulkMode, setIsBulkMode] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const formatDate = (date: Date): string => {
    const momentDate = moment(date);

    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');

    if (momentDate.isSame(today, 'd')) {
      return 'Today';
    }

    if (momentDate.isSame(yesterday, 'd')) {
      return 'Yesterday';
    }

    return momentDate.format('ddd, MMM D');
  };

  const calculateTotalDayTime = (): string => {
    const total = records.reduce((prev, next) => prev + next.totalTime, 0);

    return formatTime(total, timeFormat);
  };

  const handleDeleteRecords = (id?: string) => {
    const idsToDelete = selectedIds;

    setIsBulkMode(false);
    setSelectedIds([]);

    if (id) {
      timeTrackerStore.deleteRecord([id]);
    } else {
      timeTrackerStore.deleteRecord(idsToDelete);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = event.target;

    if (checked) {
      setSelectedIds((prev) => [...prev, id]);
    } else {
      setSelectedIds((prev) => prev.filter((currentId) => currentId !== id));
    }
  };

  const handleCheckboxSelectAll = () => {
    if (selectedIds.length === records.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(records.map((r) => r.id));
    }
  };

  const handleBulkModeToggle = () => {
    if (!isBulkMode) {
      setSelectedIds([]);
    }

    setIsBulkMode((prev) => !prev);
  };

  const onBulkUpdate = (value: ITimeTrackerRecord) => {
    timeTrackerStore.patchRecords(selectedIds, value).then(() => {
      setSelectedIds([]);
      setIsBulkMode(false);
    });
  };

  return (
    <>
      <TableContainer className={classes.container}>
        <Table>
          <TableHead>
            <TableRow className={classes.head}>
              <span className={classes.leftBlock}>
                {isBulkMode && (
                  <Checkbox
                    sx={{
                      padding: 0,
                      marginRight: '12px'
                    }}
                    checked={selectedIds.length === records.length}
                    indeterminate={
                      selectedIds.length !== 0 &&
                      selectedIds.length !== records.length
                    }
                    onChange={handleCheckboxSelectAll}
                  />
                )}
                <span>{formatDate(records[0].startedAt)}</span>
                {isBulkMode && (
                  <>
                    <Button
                      size="small"
                      className={classes.bulkButton}
                      disabled={!selectedIds.length}
                      onClick={() => setIsOpen(true)}
                    >
                      Bulk edit
                    </Button>
                    <Button
                      size="small"
                      className={classes.bulkButton}
                      disabled={!selectedIds.length}
                      onClick={() => handleDeleteRecords()}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </span>
              <div className={classes.headTimeBlock}>
                <span>Total:</span>
                <span className={classes.timeText}>
                  {calculateTotalDayTime()}
                </span>
                <IconButton onClick={handleBulkModeToggle}>
                  <EditIcon />
                </IconButton>
              </div>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record) => (
              <TableRow className={classes.recordBlock}>
                <SingleRecord
                  record={record}
                  isBulkMode={isBulkMode}
                  checked={selectedIds.includes(record.id)}
                  timeFormat={timeFormat}
                  handleCheckboxChange={handleCheckboxChange}
                  handleDeleteRecords={handleDeleteRecords}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditRecordsModal
        isOpen={isOpen}
        setOpen={setIsOpen}
        recordsCount={selectedIds.length}
        onUpdate={onBulkUpdate}
      />
    </>
  );
};

export default RecordItem;
