import React, { FC } from 'react';
import { useFormikContext } from 'formik';

// Components
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import BlockWrapper from 'components/BlockWrapper';

// Types
import {
  WorkspaceBlockProps,
  WorkspaceSettingsNames as Names
} from 'routes/WorkspaceSettings/WorkspaceSettingsTypes';
import {
  IWorkspaceSettingsPatch,
  TimeVisibility
} from 'types/workspaceSettings';

// Styles
import classes from './RegularUserSee.module.scss';

const RegularUserSee: FC<WorkspaceBlockProps> = ({ onChange }) => {
  const { values, handleChange } = useFormikContext<IWorkspaceSettingsPatch>();

  return (
    <BlockWrapper>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper}>
          <Box className={classes.textInfoContainer}>
            <span className={classes.title}>Regular users can see</span>
            <span className={classes.description}>
              Prevent regular users from seeing other people&apos;s time
              entries.
            </span>
          </Box>
        </Box>
        <Box className={classes.FieldsContainer}>
          <Box className={classes.inputContainer}>
            <Box className={classes.row}>
              <RadioGroup
                row
                name={Names.timeVisibility}
                onChange={(e) => {
                  handleChange(e);
                  onChange();
                }}
                value={values.timeVisibility}
                defaultValue={values.timeVisibility}
              >
                <FormControlLabel
                  control={<Radio />}
                  value={TimeVisibility.ALL_USERS}
                  label={
                    <Typography className={classes.font}>
                      All users time
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={<Radio />}
                  value={TimeVisibility.ONLY_PUBLIC_PROJECTS}
                  label={
                    <Typography className={classes.font}>
                      Only time on public projects
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={<Radio />}
                  value={TimeVisibility.ONLY_OWN}
                  label={
                    <Typography className={classes.font}>
                      Only their own time
                    </Typography>
                  }
                />
              </RadioGroup>
            </Box>
          </Box>
        </Box>
      </Box>
    </BlockWrapper>
  );
};

export default RegularUserSee;
